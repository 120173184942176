/* eslint-disable react/prop-types */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FilterableCardListSlice from './FilterableCardListSlice';
import {
  mapPrismicPurposeTags,
  mapPurposeTagToHubLink,
  sortCaseStudiesByPublicationDate,
  pluckTagsFromCaseStudy,
  pluckTagsFromHub,
  mergeCaseStudiesAndHubs,
} from '../../helpers/helpers';
import SymmetricGrid from '../../components/Layouts/Grids/SymmetricGrid/SymmetricGrid';
import SymmetricGridItem from '../../components/Layouts/Grids/SymmetricGrid/SymmetricGridItem';
import { linkResolver } from '../../helpers/linkResolver';
import ThumbnailImage from '../../components/ui/ThumbnailImage/ThumbnailImage';

const getGridItems = (gridItem) => {
  if (gridItem === undefined || !gridItem.data) return null;
  const purposeTags = mapPrismicPurposeTags(gridItem.data.purpose_tags);

  let linkText = gridItem.data.title;
  let size = 'Third';
  let subtext = purposeTags?.[0]?.title || '';
  const subtextLink = mapPurposeTagToHubLink(subtext);

  if (gridItem.type === 'hub') {
    size = 'Twothirds';
    linkText = `Learn more about our work in ${gridItem.data.title.toLowerCase()}`;
    subtext = 'Area of Impact';
  }

  return (
    <SymmetricGridItem
      key={gridItem.id}
      gridLink={linkResolver({ type: gridItem.type, uid: gridItem.uid })}
      linkText={linkText}
      size={size}
      subtext={subtext.toUpperCase()}
      subtextLink={subtextLink}
      roundedBorder
      smallDataSubtext
    >
      <ThumbnailImage
        imageData={gridItem.data}
        size={size}
      />
    </SymmetricGridItem>
  );
};

const WorkPageGridItems = ({ items, filters }) => {
  if (items === undefined) return null;
  const filteredCaseStudies = items.map((item) => (item.type === 'case_study' ? item : null)).filter((item) => item !== null);
  const filteredHubs = items.map((item) => (item.type === 'hub' ? item : null)).filter((item) => item !== null);

  let itemsVisibleDesktop = [];
  let itemsVisibleTablet = [];
  // update the state with the new filtered arrays
  // if an impact filter is selected which is an exact match for a hub,
  // put the hub at the start of the list and don't worry about the layout pattern
  if (filters.impacts && items.length > 0) {
    itemsVisibleDesktop = filteredHubs.concat(filteredCaseStudies);
    itemsVisibleTablet = filteredHubs.concat(filteredCaseStudies);
  } else {
    // otherwise, maintain the layout pattern
    itemsVisibleDesktop = mergeCaseStudiesAndHubs(
      filteredCaseStudies, filteredHubs, 3, 3, 5,
    );
    itemsVisibleTablet = mergeCaseStudiesAndHubs(
      filteredCaseStudies, filteredHubs, 4, 4, 4,
    );
  }

  return (
    <>
      {itemsVisibleDesktop.length === 0 && (
        <>
          <h3>No items match your filter selection.</h3>
          <h4>Please try another filter.</h4>
        </>
      )}

      {itemsVisibleDesktop.length > 0 && (
        <>
          <div className="HiddenXs HiddenLg HiddenXl">
            <SymmetricGrid>
              {itemsVisibleTablet.map(getGridItems)}
            </SymmetricGrid>
          </div>
          <div className="HiddenMd">
            <SymmetricGrid>
              {itemsVisibleDesktop.map(getGridItems)}
            </SymmetricGrid>
          </div>
        </>
      )}
    </>
  );
};

const WorkPageCaseStudiesQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicCaseStudy(sort: {fields: first_publication_date, order: DESC}) {
        edges {
          node {
            id
            uid
            type
            first_publication_date
            data {
              publication_date
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              landscape_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 1400,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              purpose_tags {
                purposes {
                  type
                  document {
                    ... on PrismicPurposeCategory {
                      id
                      data {
                        purpose
                      }
                    }
                  }
                }
              }
              service_tags {
                services {
                  type
                  document {
                    ... on PrismicServiceCategory {
                      id
                      data {
                        service
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicHub(sort: {fields: first_publication_date, order: ASC}) {
        edges {
          node {
            id
            uid
            type
            data {
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              landscape_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 1400,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              purpose_tag {
                document {
                  ... on PrismicPurposeCategory {
                    id
                    data {
                      purpose
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicPurposeCategory {
        nodes {
          data {
            purpose
          }
        }
      }
      allPrismicServiceCategory {
        nodes {
          data {
            service
          }
        }
      }
    }
  `);
  const caseStudyEdges = data.allPrismicCaseStudy.edges;
  const hubEdges = data.allPrismicHub.edges;
  const purposes = data.allPrismicPurposeCategory.nodes;
  const services = data.allPrismicServiceCategory.nodes;

  // extract initial arrays of all case studies and hubs from prismic query
  const allCaseStudies = sortCaseStudiesByPublicationDate(
    caseStudyEdges.map((n) => n.node),
  ).map(pluckTagsFromCaseStudy);
  const allHubs = hubEdges.map((n) => n.node).map(pluckTagsFromHub);

  // create simple array of strings from all prismic purposes and services
  const allPurposes = purposes.map((p) => p.data.purpose);
  const allServices = services.map((s) => s.data.service);

  const allItems = allCaseStudies.concat(allHubs);

  const selectedFilters = ['impacts', 'services'];

  const slug = 'work';

  return (
    <FilterableCardListSlice
      allItems={allItems}
      getGridItems={getGridItems}
      allPurposes={allPurposes}
      allServices={allServices}
      selectedFilters={selectedFilters}
      slug={slug}
    >
      {/* https://codedaily.io/tutorials/Using-Functions-as-Children-and-Render-Props-in-React-Components */}
      {({ items, filters }) => <WorkPageGridItems items={items} filters={filters} />}
    </FilterableCardListSlice>
  );
};

export default WorkPageCaseStudiesQuery;
