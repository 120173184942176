/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PrismicRichText } from '@prismicio/react';

import { linkResolver } from '../../helpers/linkResolver';
import * as styles from '../../components/ui/Body/Body.module.scss';
import Grid from '../../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../../components/Layouts/Grids/GeneralGrid/GeneralGridItem';
import LinkButton from '../../components/Link/LinkButton';

const GridPropTypes = {
  subtitle: PropTypes.string,
  subtitleType: PropTypes.string,
  text: PropTypes.shape({
    text: PropTypes.string,
    richText: PropTypes.array,
  }),
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  isExternal: PropTypes.bool,
};

const GridDefaultProps = {
  subtitle: null,
  subtitleType: null,
  text: null,
  buttonText: null,
  buttonUrl: null,
  isExternal: false,
};

const GridOneColumn = ({
  subtitle, subtitleType, text, buttonText, buttonUrl, isExternal,
}) => (
  <Grid>
    {subtitle && (
      <GridItem spanMd="7" offsetMd="5">
        {
          // At designer's request h2 is styled like h3 and h3 is styled like h4
          subtitleType === 'heading2' ? (
            <h2 className={styles.rowDescriptionH3}>{subtitle}</h2>
          ) : (
            <h3 className={styles.rowDescription}>{subtitle}</h3>
          )
        }
      </GridItem>
    )}

    <GridItem spanMd="12" spanLg="7">
      <PrismicRichText field={text.richText} linkResolver={linkResolver} />
      {buttonUrl && (
        <LinkButton modifierClass="MarginTop" to={buttonUrl} isExternal={isExternal}>
          {buttonText}
        </LinkButton>
      )}
    </GridItem>
  </Grid>
);

GridOneColumn.propTypes = GridPropTypes;

GridOneColumn.defaultProps = GridDefaultProps;

const GridTwoColumn = ({
  subtitle, subtitleType, text, buttonText, buttonUrl, isExternal,
}) => (
  <Grid>
    {subtitle && (
      <GridItem spanMd="4" offsetMd="1">
        {
          // At designer's request h2 is styled like h3 and h3 is styled like h4
          subtitleType === 'heading2' ? (
            <h2 className={styles.rowDescriptionH3}>{subtitle}</h2>
          ) : (
            <h3 className={styles.rowDescription}>{subtitle}</h3>
          )
        }
      </GridItem>
    )}
    <GridItem spanMd="7" spanLg="7">
      <PrismicRichText field={text.richText} linkResolver={linkResolver} />
      {buttonUrl && (
        <LinkButton modifierClass="MarginTop" to={buttonUrl} isExternal={isExternal}>
          {buttonText}
        </LinkButton>
      )}
    </GridItem>
  </Grid>
);

GridTwoColumn.propTypes = GridPropTypes;

GridTwoColumn.defaultProps = GridDefaultProps;

const ContentSlice = ({
  layout,
  heading,
  subtitle,
  subtitleType,
  text,
  buttonText,
  buttonUrl,
  isExternal,
}) => {
  const props = {
    subtitle,
    subtitleType,
    text,
    buttonText,
    buttonUrl,
    isExternal,
  };

  const headingOnlyClass = !subtitle && text.richText.length <= 0 && !buttonText && layout !== 'Two column' ? styles.headingOnly : null;
  return (
    <div data-cy="content-slice" className={styles.content}>
      {heading && (
        <h2
          className={cx(
            styles.rowHeading,
            headingOnlyClass,
          )}
        >
          {heading}
        </h2>
      )}

      {layout === 'One column' ? <GridOneColumn {...props} /> : <GridTwoColumn {...props} />}
    </div>
  );
};

ContentSlice.propTypes = {
  layout: PropTypes.oneOf(['One column', 'Two column']).isRequired,
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleType: PropTypes.string,
  text: PropTypes.shape({
    text: PropTypes.string,
    richtext: PropTypes.array,
  }),
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  isExternal: PropTypes.bool,
};

ContentSlice.defaultProps = {
  heading: null,
  subtitle: null,
  subtitleType: null,
  text: null,
  buttonText: null,
  buttonUrl: null,
  isExternal: false,
};

export default ContentSlice;
