/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './RelatedLinksCards.module.scss';
import Container from '../../components/Layouts/Container';
import RelatedLinksCards from './RelatedLinksCards';
import SiteLink from '../../components/Link/SiteLink';
import Grid from '../../components/Layouts/Grids/GeneralGrid/GeneralGrid';

const RelatedLinksCardsSlice = ({ primary, items = [] }) => {
  const { CTA_text, CTA_url, heading } = primary;

  return (
    <Container>
      {heading?.text
      && (
        <div className={styles.header}>
          <h2 className={styles.heading}>
            {heading.text}
          </h2>

          {CTA_text?.text && CTA_url?.url
          && (
            <div className={styles.cta}>
              <SiteLink to={CTA_url?.url}>
                {CTA_text.text}
              </SiteLink>
              <span> ↗ </span>
            </div>
          )}
        </div>
      )}
      <Grid>
        <RelatedLinksCards links={items} size="Third" />
      </Grid>
    </Container>
  );
};

RelatedLinksCardsSlice.propTypes = {
  primary: PropTypes.shape({
    CTA_text: PropTypes.shape({
      text: PropTypes.string,
    }),
    CTA_url: PropTypes.shape({
      url: PropTypes.string,
    }),
    heading: PropTypes.shape({
      text: PropTypes.string,
    }),
  }).isRequired,
  items: PropTypes.array.isRequired,
};

export default RelatedLinksCardsSlice;
