// extracted by mini-css-extract-plugin
export var BlackText = "AnchorNavigation-module--BlackText--GO4En";
export var HiddenLg = "AnchorNavigation-module--HiddenLg--ilaV-";
export var HiddenMd = "AnchorNavigation-module--HiddenMd--0MfdL";
export var HiddenXl = "AnchorNavigation-module--HiddenXl--qG4Vf";
export var HiddenXs = "AnchorNavigation-module--HiddenXs--JnfuP";
export var TextAlignRightXs = "AnchorNavigation-module--TextAlignRightXs--ekp7r";
export var WhiteText = "AnchorNavigation-module--WhiteText--bDpdm";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "AnchorNavigation-module--container--FRekc";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var links = "AnchorNavigation-module--links--bQGPv";
export var mdBreakpoint = "40rem";
export var srOnly = "AnchorNavigation-module--sr-only--OtOau";
export var visuallyHidden = "AnchorNavigation-module--visually-hidden--kJ0Kn";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";