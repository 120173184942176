/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import debounce from 'lodash.debounce';
import cx from 'classnames';
import { Helmet } from 'react-helmet';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NotFoundPage from './404';
import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import Body from '../components/ui/Body/Body';
import Container from '../components/Layouts/Container';
import NewsletterSignupForm from '../components/Form/NewsletterSignup/NewsletterSignupForm';
import HomeHeroBanner from '../components/HomeHeroBanner/HomeHeroBanner';
import * as styles from './home.module.scss';
import getPageContextFromData from '../lib/dto/getPageContextFromData';

const IndexPage = ({
  data: { prismicHomePage: { type, uid, data } },
}) => {
  if (!data) return <NotFoundPage />;
  const [heroOpacity, setHeroOpacity] = useState(1);

  const dataContext = getPageContextFromData(type, uid, data);

  // Override anchor link clicks to smoothly scroll to the anchor
  const onClickScrollLink = (event) => {
    // Cancel the default behaviour of the anchor link
    event.preventDefault();

    // Get the anchor element from the ID
    const anchor = document.getElementById('main-content');
    if (!anchor) return;

    // Get the position of the anchor, smoothly scroll to it with the desired offset
    const anchorTop = anchor.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: anchorTop, behavior: 'smooth' });
  };

  // Adjust the opacity of the header hero component based on scroll position
  useEffect(() => {
    if (!window) return;

    const onScroll = debounce(() => {
      // if the scroll position is more than twice the viewport height, do nothing
      if (window.pageYOffset > (window.innerHeight * 2)) return;

      if (window.pageYOffset < window.innerHeight) {
        // calculate the opacity percentage
        let opacityPercentage = (
          (window.innerHeight - (window.pageYOffset - 100)
          ) / window.innerHeight);
        if (opacityPercentage > 100) opacityPercentage = 100;
        setHeroOpacity(Math.round(opacityPercentage * 10) / 10);
      }
    }, 15);
    window.addEventListener('scroll', onScroll);
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <PageData.Provider value={dataContext}>
      <Helmet />
      <Layout isHomePage>
        <HomeHeroBanner
          onScroll={onClickScrollLink}
          style={{
            opacity: heroOpacity,
            transition: 'opacity 100ms ease-in',
          }}
        />
        <div id="main-content" className={cx(styles.main, styles.homePageNavMargin)}>
          <Body
            style={{
              marginTop: '100vh',
              transition: 'margin-top 0.35s ease-out',
            }}
          />
          <Container>
            <NewsletterSignupForm />
          </Container>
        </div>
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
{
  prismicHomePage {
    _previewable
    uid
    type
    tags
    data {
      title
      introduction
      page_colour
      page_short_description
      prismic_page_name
      seo_description
      seo_title
      body {
        ... on PrismicHomePageDataBodyCaseStudies {
          id
          slice_type
          __typename
          primary {
            items_to_show
            case_studies_title {
              text
            }
          }
          items {
            featured_case_study {
              document {
                ... on PrismicCaseStudy {
                  id
                  uid
                  type
                  data {
                    title
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                    landscape_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 1400,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                    purpose_tags {
                      purposes {
                        type
                        document {
                          ... on PrismicPurposeCategory {
                            id
                            data {
                              purpose
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicHomePageDataBodyIntroductorySection {
          id
          slice_type
          primary {
            description {
              text
            }
            heading {
              text
            }
          }
        }
        ... on PrismicHomePageDataBodyImpactAreas {
          id
          slice_type
          primary {
            heading {
              text
            }
            description {
              text
            }
          }
          items {
            hub {
              document {
                ... on PrismicHub {
                  uid
                  type
                  data {
                    impact_areas_name {
                      text
                    }
                    hub_shapes {
                      localFile {
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicHomePageDataBodyWhatsHappening {
          id
          slice_type
          primary {
            heading {
              text
            }
          }
        }
        ... on PrismicHomePageDataBodyContent {
          id
          slice_type
          primary {
            content_heading {
              richText
              text
            }
            content_subtitle {
              richText
              text
            }
            content_text {
              richText
              text
            }
            content_button_text {
              text
            }
            content_button_url {
              raw
            }
            layout
          }
        }
        ... on PrismicHomePageDataBodyImage {
          id
          slice_type
          primary {
            alignment
            image {
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    quality: 100,
                    width: 1400,
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
          }
        }
        ... on PrismicHomePageDataBodyContentAndCards {
          id
          slice_type
          primary {
            title1 {
              text
            }
            description {
              richText
            }
            button_link {
              url
              uid
            }
            button_label {
              text
            }
          }
          items {
            tag1 {
              text
            }
            image {
              alt
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    quality: 100
                    width: 142
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
            description {
              text
            }
          }
        }
        ... on PrismicHomePageDataBodyCardsGrid {
          id
          slice_type
          primary {
            section_title {
              text
            }
            section_introduction {
              richText
              text
            }
          }
          items {
            title {
              richText
              text
            }
            description {
              richText
              text
            }
            card_link {
              url
              uid
              target
            }
            call_to_action {
              richText
              text
            }
            image {
              url
              alt
            }
          }
        }
        ... on PrismicHomePageDataBodyDivider {
          id
          slice_type
        }
      }
    }
  }
}
`;

IndexPage.propTypes = {
  data: PropTypes.shape({
    prismicHomePage: PropTypes.object.isRequired,
  }).isRequired,
};

export default withPrismicPreview(IndexPage);
