import React from 'react';
import PropTypes from 'prop-types';

import DownArrow from '../../images/downArrowWhite.svg';
import * as styles from './AnchorNavigation.module.scss';
import Container from '../../components/Layouts/Container';
import { getScrollPositionWidthOffset, htmlSafeID } from '../../helpers/helpers';

const AnchorNavigationSliceProps = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  })),
};

const AnchorNavigationSlice = ({
  items,
}) => {
  const onClickScrollLink = (event, id) => {
    event.preventDefault();
    getScrollPositionWidthOffset(id);
  };

  const TagAndContentAnchorNavigation = ({ item }) => (
    <>
      <a
        href={`#${item.id}_${htmlSafeID(item.name)}`}
        onClick={(event) => onClickScrollLink(event, `${item.id}_${htmlSafeID(item.name)}`)}
      >
        {item.name}
        <span>
          <img src={DownArrow} alt="down arrow" />
        </span>
      </a>
    </>
  );

  const OtherAnchorNavigation = ({ item, index }) => (
    <>
      <a
        href={`#${item.id}`}
        key={index}
        onClick={(event) => onClickScrollLink(event, item.id)}
      >
        {item.name}
        <span>
          <img src={DownArrow} alt="down arrow" />
        </span>
      </a>
    </>
  );

  return (
    <div className={styles.container}>
      {items.map((item, index) => (
        <div className={styles.links}>
          {item.id === 'tag_and_content' ? (
            <TagAndContentAnchorNavigation item={item} />
          ) : (
            <OtherAnchorNavigation item={item} index={index} />
          )}
        </div>
      ))}
    </div>
  );
};

AnchorNavigationSlice.propTypes = AnchorNavigationSliceProps;
AnchorNavigationSlice.defaultProps = AnchorNavigationSliceProps;

export default AnchorNavigationSlice;
