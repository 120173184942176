// extracted by mini-css-extract-plugin
export var BlackText = "ServiceSuggestionSlice-module--BlackText--wp7JT";
export var HiddenLg = "ServiceSuggestionSlice-module--HiddenLg--QDwEW";
export var HiddenMd = "ServiceSuggestionSlice-module--HiddenMd--Erv0v";
export var HiddenXl = "ServiceSuggestionSlice-module--HiddenXl--7Tdh8";
export var HiddenXs = "ServiceSuggestionSlice-module--HiddenXs--VAQ-R";
export var TextAlignRightXs = "ServiceSuggestionSlice-module--TextAlignRightXs--NrV8-";
export var WhiteText = "ServiceSuggestionSlice-module--WhiteText--i8b9l";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "ServiceSuggestionSlice-module--container--zZuqr";
export var desktopWidth = "960px";
export var icon = "ServiceSuggestionSlice-module--icon--Cx98W";
export var lgBreakpoint = "60rem";
export var linkText = "ServiceSuggestionSlice-module--linkText--5-2Jc";
export var mdBreakpoint = "40rem";
export var serviceItem = "ServiceSuggestionSlice-module--serviceItem--CEQ13";
export var serviceLink = "ServiceSuggestionSlice-module--serviceLink--80ERu";
export var serviceName = "ServiceSuggestionSlice-module--serviceName--KWumj";
export var services = "ServiceSuggestionSlice-module--services--TD3ek";
export var srOnly = "ServiceSuggestionSlice-module--sr-only--mIFp9";
export var visuallyHidden = "ServiceSuggestionSlice-module--visually-hidden--+iQMk";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";