import React, { useState } from 'react';

import Button from '../../Button/Button';
import CirclesImage from '../../../images/sign-up-circles.svg';
import Grid from '../../Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../../Layouts/Grids/GeneralGrid/GeneralGridItem';
import SiteLink from '../../Link/SiteLink';
import TextField from '../TextField';
import * as styles from './NewsletterSignupForm.module.scss';
import { getHubspotCookie } from '../../../helpers/helpers';

const NewsletterSignupForm = () => {
  const [email, setEmail] = useState('');
  const [formState, setFormState] = useState({
    hasError: false,
    submitting: false,
    submitted: false,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const hubspotutk = getHubspotCookie();

    const postData = {
      fields: [
        {
          name: 'email',
          value: email,
        },
      ],
      context: {
        hutk: hubspotutk,
        pageUri: window.location.href,
        pageName: document.title,
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'I agree to allow Portable to store and process my personal data.',
          communications: [
            {
              value: true,
              subscriptionTypeId: process.env.GATSBY_HUBSPOT_NEWSLETTER_SUBSCRIPTION_ID,
              text: 'I agree to receive weekly newsletter marketing communications from Portable.',
            },
          ],
        },
      },
    };

    setFormState((prev) => ({
      ...prev,
      hasError: false,
      submitting: true,
    }));

    fetch(event.target.action, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    })
      .then(async (response) => {
        if (response.status >= 400) throw Error(await response.text());

        setFormState((prev) => ({
          ...prev,
          submitting: false,
          submitted: true,
        }));
        setEmail('');

        return response.text();
      })
      .catch((error) => {
        setFormState((prev) => ({
          ...prev,
          hasError: true,
          submitting: false,
        }));
        console.error(error);
      });
  };

  return (
    <section className={styles.newsletterSignup}>
      <h2>Sign up to our email newsletter to get updates about our events, work and research</h2>

      <div className={styles.formContainer}>
        <form
          id="newsletterSignupForm"
          onSubmit={handleSubmit}
          action={`https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_ACCOUNT_ID}/${process.env.GATSBY_HUBSPOT_NEWSLETTER_FORM_ID}`}
          method="POST"
        >
          {!formState.submitted && (
            <>
              <Grid align="flex-end">
                <GridItem spanMd="6" spanXLg="4">
                  <TextField
                    id="newsletterEmail"
                    type="email"
                    label="Email address"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                    disabled={formState.submitting}
                  />
                </GridItem>
                <GridItem spanMd="6" spanXLg="4">
                  <Button type="submit" disabled={formState.submitting}>Sign up now</Button>
                </GridItem>
              </Grid>
              <p className={styles.unsubscribeMessage}>
                You can unsubscribe at any time using the link in our emails. For more details, review our <SiteLink to="/privacy">privacy policy</SiteLink>.
              </p>
            </>
          )}

          {formState.hasError && <p className={styles.errorMessage}>Oops! Something went wrong while submitting the form.</p>}
          {formState.submitted && (
            <div className={styles.successMessageContainer}>
              <div>
                <h3>Thank you.</h3>
                <p>You’ve signed up and will hear from us soon.</p>
              </div>
            </div>
          )}
        </form>
        <div className={styles.circles}>
          <img className={styles.circlesImage} src={CirclesImage} alt="" />
        </div>
      </div>
    </section>
  );
};

export default NewsletterSignupForm;
