const getUpcomingSessionProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;
  const tag = primary.tag?.text?.trim() || null;
  const title1 = primary.title1?.text?.trim() || null;
  const description = primary.description || null;
  const card = sliceData.items.map((item) => {
    const cardTag = item.session_tag?.text?.trim() || null;
    const cardTitle = item.session_title?.text?.trim() || null;
    const cardDescription = item.session_description || null;
    const cardDate = item.date_and_time || null;
    const cardAmount = item.amount.text || null;
    const buttonText = item.button_text?.text?.trim() || null;
    const buttonLink = item.button_link.url || "#";
    const buttonLinkTarget = item.button_link.target || "_self";
    const sessionList = item.session_information_list || null;

    return {
      cardTag,
      cardTitle,
      cardDescription,
      cardDate,
      buttonText,
      buttonLink,
      buttonLinkTarget,
      cardAmount,
      sessionList,
    };
  });

  return {
    tag,
    title1,
    description,
    card,
  };
};

export default getUpcomingSessionProps;
