/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import DownloadFormHubspot from '../components/Form/DownloadForm/DownloadFormHubspot';

const DownloadFormSlice = ({
  input: {
    primary: {
      form_title,
      form_image = {},
      form_imageSharp,
      hubspot_form_id,
    },
  },
}) => {
  const image = form_imageSharp || form_image || {};
  const imageAlt = image.alt || '';

  return (
    <DownloadFormHubspot
      downloadImage={image}
      downloadImageAlt={imageAlt}
      downloadTitle={form_title}
      formID={hubspot_form_id}
    />
  );
};

DownloadFormSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      form_title: PropTypes.string,
      form_image: PropTypes.object,
      form_imageSharp: PropTypes.object,
      hubspot_form_id: PropTypes.string,
    }),
  }).isRequired,
};

export default DownloadFormSlice;
