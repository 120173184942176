// extracted by mini-css-extract-plugin
export var BlackText = "WhatsHappeningSlice-module--BlackText--7Azo6";
export var HiddenLg = "WhatsHappeningSlice-module--HiddenLg--1wIBD";
export var HiddenMd = "WhatsHappeningSlice-module--HiddenMd--+VVgb";
export var HiddenXl = "WhatsHappeningSlice-module--HiddenXl--DRghe";
export var HiddenXs = "WhatsHappeningSlice-module--HiddenXs--tPCYj";
export var TextAlignRightXs = "WhatsHappeningSlice-module--TextAlignRightXs--KHB1L";
export var WhiteText = "WhatsHappeningSlice-module--WhiteText--6xDFi";
export var aspectRatioBox = "WhatsHappeningSlice-module--aspectRatioBox--WB4Bj";
export var aspectRatioBoxInside = "WhatsHappeningSlice-module--aspectRatioBoxInside--DTnE6";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var date = "WhatsHappeningSlice-module--date--6k1jc";
export var desktopWidth = "960px";
export var gridItemLink = "WhatsHappeningSlice-module--gridItemLink--A0Qhl";
export var hidden = "WhatsHappeningSlice-module--hidden--YlgpG";
export var icon = "WhatsHappeningSlice-module--icon--qw2Xu";
export var itemType = "WhatsHappeningSlice-module--itemType--gmcxf";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var sectionHeading = "WhatsHappeningSlice-module--sectionHeading--TWpop";
export var sectionHeading__text = "WhatsHappeningSlice-module--sectionHeading__text--e6ifs";
export var showMore = "WhatsHappeningSlice-module--showMore--mzpNF";
export var showMoreWrapper = "WhatsHappeningSlice-module--showMoreWrapper--aAo66";
export var srOnly = "WhatsHappeningSlice-module--sr-only--Ijqiv";
export var subtext = "WhatsHappeningSlice-module--subtext--35BNk";
export var title = "WhatsHappeningSlice-module--title--nrNH5";
export var visible = "WhatsHappeningSlice-module--visible--WpmOO";
export var visuallyHidden = "WhatsHappeningSlice-module--visually-hidden--z37Wd";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";