/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './RelatedSubjectMatter.module.scss';
import RelatedLinksCards from '../../../slices/RelatedLinksCardsSlice/RelatedLinksCards';

const RelatedSubjectMatter = ({
  heading,
  links,
}) => (
  <>
    <section className={styles.blockWrapper}>
      <h3 className={styles.sectionHeading}>
        {heading.text}
      </h3>
      <div className={styles.sectionLinks}>
        <div className={styles.linksWrapper}>
          <RelatedLinksCards links={links} size="Half" />
        </div>
      </div>
    </section>
  </>
);

RelatedSubjectMatter.propTypes = {
  heading: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
};

export default RelatedSubjectMatter;
