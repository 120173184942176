/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

import { linkResolver } from '../../helpers/linkResolver';
import SiteLink from '../../components/Link/SiteLink';
import * as styles from './UpcomingSessionCard.module.scss';
import Container from '../../components/Layouts/Container';
import Button from '../../components/Button/Button';

const UpcomingSessionCardProps = {
  tag: PropTypes.string,
  title1: PropTypes.string,
  description: PropTypes.shape({
    richText: PropTypes.array,
  }),
  card: PropTypes.arrayOf(PropTypes.shape({
    tag: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.shape({
      richText: PropTypes.array,
    }),
    amount: PropTypes.number,
    date: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonLinkTarget: PropTypes.string,
    listContent: PropTypes.shape({
      richText: PropTypes.array,
    }),
  })),
};

const UpcomingSessionCard = ({
  tag,
  title1,
  description,
  card,
}) => (
  <Container>
    <div id="get_tickets_card" className={styles.cardItems}>
      <div className={styles.tag}>{tag}</div>
      <h2>{title1}</h2>
      <PrismicRichText field={description.richText} linkResolver={linkResolver} />
    </div>
    <div className={styles.container}>
      {card && card.map((item, i) => (
        <div key={`card-${i}`} className={styles.card}>
          <div className={styles.tag}>{item.cardTag}</div>
          <h3 className={styles.title}>{item.cardTitle}</h3>
          <PrismicRichText field={item.cardDescription.richText} linkResolver={linkResolver} />

          {/* Show amount value to 2 decimal places */}
          <p className={styles.amount}>{item.cardAmount}</p>

          {/* Convert date from 2022-08-01 to Thursday 01 August 2022 */}
          <PrismicRichText
            field={item.cardDate.richText}
            linkResolver={linkResolver}
            components={{
              paragraph: ({ children }) => <p className={styles.dateTime}>{children}</p>,
            }}
          />

          <Button aria-label="get your tickets" className={styles.button}>
            <SiteLink
              target={item.buttonLinkTarget}
              to={item.buttonLink}
            >
              {item.buttonText}
            </SiteLink>
          </Button>
          <PrismicRichText field={item.sessionList.richText} linkResolver={linkResolver} />
        </div>
      ))}
    </div>
  </Container>
);

UpcomingSessionCard.propTypes = UpcomingSessionCardProps;
UpcomingSessionCard.defaultProps = UpcomingSessionCardProps;

export default UpcomingSessionCard;
