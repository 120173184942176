// /* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import BannerTextLink from './BannerTextLink';
import ScrollLink from './ScrollLink';
import bcorpLogo from '../../images/bcorp-logo-white.svg';
import * as styles from './HomeHeroBanner.module.scss';

const HomeHeroBanner = ({ style, onScroll }) => (
  <StaticQuery
    query={graphql`
      query {
        work: allFile(filter: {relativePath: {glob: "home/work-*"}}, sort: {fields: name}) {
          nodes {
            id
            childImageSharp {
              gatsbyImageData(width: 1440, height: 900, quality: 90)
            }
          }
        }
        mentalHealth: allFile(filter: {relativePath: {glob: "home/mental-health-*"}}, sort: {fields: name}) {
          nodes {
            id
            childImageSharp {
              gatsbyImageData(width: 1440, height: 900, quality: 90)
            }
          }
        }
        justice: allFile(filter: {relativePath: {glob: "home/justice-*"}}, sort: {fields: name}) {
          nodes {
            id
            childImageSharp {
              gatsbyImageData(width: 1440, height: 900, quality: 90)
            }
          }
        }
        public: allFile(filter: {relativePath: {glob: "home/public-*"}}, sort: {fields: name}) {
          nodes {
            id
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1440, height: 900, quality: 90)
            }
          }
        }
        environment: allFile(filter: {relativePath: {glob: "home/environment-*"}}, sort: {fields: name}) {
          nodes {
            id
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1440, height: 900, quality: 90)
            }
          }
        }
      }
    `}
    render={(hubs) => {
      /* Use the visual viewport API on browsers that support it to
      * get the actual viewport height rather than "100vh"
      * This is to prevent browser UI from covering the "Scroll" button
      * in the banner footer */
      const [viewportHeight, setViewportHeight] = useState('100vh');
      useEffect(() => {
        if (!window.visualViewport) return;

        const onResize = () => {
          setViewportHeight(`${window.visualViewport.height}px`);
        };
        onResize();

        window.visualViewport.addEventListener('resize', onResize);
        // eslint-disable-next-line consistent-return
        return () => {
          window.visualViewport.removeEventListener('resize', onResize);
        };
      }, []);

      return (
        <div className={styles.container} style={{ ...style, '--viewport-height': viewportHeight }}>
          <div id="home-banner" className={styles.bannerText}>
            <h1>
              We use design and{' '}
              <BannerTextLink to="/technology">technology</BannerTextLink> for positive impact.{' '}
              <BannerTextLink to="/work" hub={hubs.work}>Our work</BannerTextLink> helps people in areas like{' '}
              <BannerTextLink to="/mental-health" hub={hubs.mentalHealth}>mental health</BannerTextLink>,{' '}
              <BannerTextLink to="/justice" hub={hubs.justice}>justice</BannerTextLink>,{' '}
              <BannerTextLink to="/environment" hub={hubs.environment}>environment</BannerTextLink> and {' '}
              <BannerTextLink to="#impact-areas">more</BannerTextLink>.
            </h1>
          </div>
          <div className={styles.bannerFooter}>
            <ScrollLink onClick={onScroll} />
            <a href="https://www.bcorporation.net/en-us/find-a-b-corp/company/portable" target="_blank" rel="noreferrer">
              <img
                className={styles.bcorp}
                src={bcorpLogo}
                alt="Portable is a Certified B Corporation"
              />
            </a>
          </div>
        </div>
      );
    }}
  />
);

HomeHeroBanner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  onScroll: PropTypes.func.isRequired,
};

HomeHeroBanner.defaultProps = {
  style: {},
};

export default HomeHeroBanner;
