// extracted by mini-css-extract-plugin
export var BlackText = "ImageAndContentCarousel-module--BlackText--jla20";
export var Card = "ImageAndContentCarousel-module--Card--+EPjz";
export var CardBackgroundClassica = "ImageAndContentCarousel-module--CardBackgroundClassica--rFkf4";
export var CardBackgroundParadise = "ImageAndContentCarousel-module--CardBackgroundParadise--5Q6an";
export var CardBackgroundSuzie = "ImageAndContentCarousel-module--CardBackgroundSuzie--SzEVW";
export var HiddenLg = "ImageAndContentCarousel-module--HiddenLg--zrZdm";
export var HiddenMd = "ImageAndContentCarousel-module--HiddenMd--IqXQR";
export var HiddenXl = "ImageAndContentCarousel-module--HiddenXl--Zh3fm";
export var HiddenXs = "ImageAndContentCarousel-module--HiddenXs--lwGYi";
export var TextAlignRightXs = "ImageAndContentCarousel-module--TextAlignRightXs--Gn8Fy";
export var WhiteText = "ImageAndContentCarousel-module--WhiteText--sMorY";
export var author = "ImageAndContentCarousel-module--author--RCs6f";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var image = "ImageAndContentCarousel-module--image--P1Ow3";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "ImageAndContentCarousel-module--sr-only--6KWfn";
export var visuallyHidden = "ImageAndContentCarousel-module--visually-hidden--ECJZa";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";
export var withImage = "ImageAndContentCarousel-module--withImage--S3z+V";