/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NotFoundPage from './404';
import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import Body from '../components/ui/Body/Body';
import getPageContextFromData from '../lib/dto/getPageContextFromData';

const ArticlesPage = ({
  data: { prismicArticlesPage: { type, uid, data } },
}) => {
  if (!data) return <NotFoundPage />;

  const dataContext = getPageContextFromData(type, uid, data);

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <Body />
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
{
  prismicArticlesPage {
    _previewable
    uid
    type
    lang
    data {
      introduction
      page_colour
      page_short_description
      seo_description
      seo_title
      title
      body {
        ... on PrismicArticlesPageDataBodyBlogPosts {
          id
          slice_type
          primary {
            heading {
              text
            }
          }
          items {
            blog_posts {
              uid
              type
              slug
            }
          }
        }
        ... on PrismicArticlesPageDataBodyContent {
          id
          slice_type
          primary {
            content_heading {
              text
            }
            content_subtitle {
              richText
              text
            }
            content_text {
              richText
              text
            }
          }
        }
      }
    }
  }
}
`;

ArticlesPage.propTypes = {
  data: PropTypes.shape({
    prismicArticlesPage: PropTypes.object.isRequired,
  }).isRequired,
};

export default withPrismicPreview(ArticlesPage);
