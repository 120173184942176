/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import SiteLink from '../../components/Link/SiteLink';
import UpRightArrow from '../../images/upright-arrow.svg';
import * as styles from './OurWork.module.scss';
import Container from '../../components/Layouts/Container';

const OurWorkProps = {
  title: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.image,
    heading: PropTypes.string,
    tagLabel: PropTypes.string,
    imageLink: PropTypes.string,
  })),
};

const OurWork = ({
  title, linkText, link, contents,
}) => (
  <Container>
    <div className={styles.container}>
      <div className={styles.titleSection}>
        <h2 className={styles.title}> {title}</h2>
        <div className={styles.linkText}>
          {link && linkText && (
            <SiteLink className={styles.subLink} to={link} bold>
              {linkText}
              <img className={styles.icon} src={UpRightArrow} alt="arrow" />
            </SiteLink>
          )}
        </div>
      </div>
      <div className={styles.imageContainer}>
        {contents
      && contents.map((content, i) => (
        <div key={`contents-${i}`} className={styles.contentContainer}>
          {content.imageLink && content.heading && (
            <SiteLink to={content.imageLink}>
              <img className={styles.image} src={content.image} alt="our-work" aria-hidden />
              <h4 className={styles.heading}>{content.heading}</h4>
              <p className={styles.tag}>{content.tagLabel}</p>
            </SiteLink>
          )}
        </div>
      ))}
      </div>
    </div>
  </Container>
);

OurWork.propTypes = OurWorkProps;
OurWork.defaultProps = OurWorkProps;

export default OurWork;
