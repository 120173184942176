/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { slicePropsFormatter } from '../../lib/dto/dto';
import AccordionSlice from '../../slices/AccordionSlice';
import ActionButtonSlice from '../../slices/ActionButtonSlice';
import BannerSlice from '../../slices/BannerSlice';
import BlogsSlice from '../../slices/BlogsSlice';
import CaseStudiesSlice from '../../slices/CaseStudiesSlice';
import ContentAndCardsSlice from '../../slices/ContentAndCards/ContentAndCards';
import CodeAndContentSlice from '../../slices/CodeAndContentSlice';
import CodeHighlighterSlice from '../../slices/loadable/CodeHighlighterSlice';
import CollectionSlice from '../../slices/CollectionSlice';
import ContentSlice from '../../slices/ContentSlice/ContentSlice';
import DividerSlice from '../../slices/DividerSlice';
import DownloadFormHubspotSlice from '../../slices/DownloadFormHubspotSlice';
import EmbeddedHubspotFormSlice from '../../slices/EmbeddedHubspotFormSlice';
import EmbededVideoSlice from '../../slices/EmbededVideoSlice';
import EventbriteSlice from '../../slices/EventbriteSlice';
import ProductCaseStudiesSlice from '../../slices/ProductCaseStudies/ProductCaseStudiesSlice';
import RelatedContentSlice from '../../slices/RelatedContentSlice';
import EventsSlice from '../../slices/EventsSlice';
import ExtLinksSectionSlice from '../../slices/ExtLinksSectionSlice';
import HeroLinkSlice from '../../slices/HeroLinkSlice';
import HomePageCaseStudiesSlice from '../../slices/HomePageCaseStudiesSlice';
import ImageSlice from '../../slices/ImageSlice';
import ImageAndContentCarouselSlice from '../../slices/ImageAndContentCarousel/ImageAndContentCarousel';
import ImpactAreasSlice from '../../slices/ImpactAreasSlice';
import IntroductorySectionSlice from '../../slices/IntroductorySectionSlice';
import ListSlice from '../../slices/ListSlice';
import QuoteCarouselSlice from '../../slices/QuoteCarouselSlice/QuoteCarouselSlice';
import QuoteSlice from '../../slices/QuoteSlice';
import RelatedSubjectMatterSlice from '../../slices/RelatedSubjectMatterSlice';
import ReportSlice from '../../slices/ReportsSlice';
import ServicesSectionSlice from '../../slices/ServicesSectionSlice';
import TeamSlice from '../../slices/TeamSlice';
import TwoColListSlice from '../../slices/TwoColListSlice';
import ValuesSlice from '../../slices/ValuesSlice';
import WhatsHappeningSlice from '../../slices/WhatsHappeningSlice';
import WorkPageCaseStudiesQuery from '../../slices/FilterableCardListSlice/WorkPageCaseStudiesQuery';
import JusticeHubCaseStudiesQuery from '../../slices/FilterableCardListSlice/JusticeHubCaseStudiesQuery';
import ServiceHubHeroSlice from '../../slices/ServiceHubHeroSlice/ServiceHubHeroSlice';
import ServiceSection from '../../slices/ServiceSection/ServiceSection';
import ServiceDesignHero from '../../slices/ServiceDesignHero/ServiceDesignHero';
import ServiceSuggestionSlice from '../../slices/ServiceSuggestionSlice/ServiceSuggestionSlice';
import OurWorkSlice from '../../slices/OurWorkSlice/OurWork';
import AlternatingContentMediaBlock from '../../slices/AlternatingContentMediaBlock/AlternatingContentMediaBlock';
import UpcomingSessionCard from '../../slices/UpcomingSessionCard/UpcomingSessionCard';
import ImageAndContentSlice from '../../slices/ImageAndContentSlice/ImageAndContentSlice';
import TagAndContentSlice from '../../slices/TagAndContentSlice/TagAndContentSlice';
import AnchorNavigationSlice from '../../slices/AnchorNavigationSlice/AnchorNavigationSlice';
import TextCardsGridSlice from '../../slices/TextCardsGridSlice';
import RelatedLinksCardsSlice from '../../slices/RelatedLinksCardsSlice/RelatedLinksCardsSlice';

const SliceZone = ({ allSlices = [] }) => {
  const slice = useMemo(
    () => allSlices.map((s) => {
      const sliceProps = slicePropsFormatter(s);
      switch (s.slice_type) {
      // These are the API IDs of the slices in alphabetical order
      case 'accordion':
        return <AccordionSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'alternating_media_text':
        return <AlternatingContentMediaBlock key={`slice-${s.id}`} {...sliceProps} />;
      case 'action_button':
        return <ActionButtonSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'banner':
        return <BannerSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'blog_posts':
        return <BlogsSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'case_studies':
      case 'hub_collection':
      case 'products_list':
        // work (case studies) are rendered differently on the home, work, and other pages.
        // load the relevant slice zone template based on the typename
        if (s.__typename === 'PrismicHomePageDataBodyCaseStudies') return <HomePageCaseStudiesSlice key={`slice-${s.id}`} input={sliceProps} />;
        if (s.__typename === 'PrismicWorkPageDataBodyCaseStudies') return <WorkPageCaseStudiesQuery key={`slice-${s.id}`} input={sliceProps} />;
        if (s.__typename === 'PrismicHubBodyHubCollection') return <JusticeHubCaseStudiesQuery key={`slice-${s.id}`} input={sliceProps} />;
        return <CaseStudiesSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'code_and_content':
        return <CodeAndContentSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'code_highlighter':
        return <CodeHighlighterSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'collection':
        return <CollectionSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'content':
        return <ContentSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'divider':
        return <DividerSlice key={`slice-${s.id}`} />;
      case 'download_form_hs':
        return <DownloadFormHubspotSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'eventbrite':
        return <EventbriteSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'external_links':
        return <ExtLinksSectionSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'hero_link':
        return <HeroLinkSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'hs_embedded_form':
        return <EmbeddedHubspotFormSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'image':
        return <ImageSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'image_and_content_carousel':
        return <ImageAndContentCarouselSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'impact_areas':
        return <ImpactAreasSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'list':
        return <ListSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'past_events':
        return <EventsSlice key={`slice-${s.id}`} input={sliceProps} past />;
      case 'quote_carousel':
        return <QuoteCarouselSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'quote' || 'block_quote':
        return <QuoteSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'related_content':
        return <RelatedContentSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'reports':
        return <ReportSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'related_subject_matter':
        return <RelatedSubjectMatterSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'services_section':
        return <ServicesSectionSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'services_hero':
        return <ServiceHubHeroSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'services_list':
        return <ServiceSection key={`slice-${s.id}`} {...sliceProps} />;
      case 'service_post_hero':
        return <ServiceDesignHero key={`slice-${s.id}`} {...sliceProps} />;
      case 'service_suggestion':
        return <ServiceSuggestionSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'our_work':
        return <OurWorkSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'team_members':
        return <TeamSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'two-column-list':
        return <TwoColListSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'upcoming_events':
        return <EventsSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'values':
        return <ValuesSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'video':
        return <EmbededVideoSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'introductory_section':
        return <IntroductorySectionSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'whats_happening':
        return <WhatsHappeningSlice key={`slice-${s.id}`} input={sliceProps} />;
      case 'get_tickets_card':
        return <UpcomingSessionCard key={`slice-${s.id}`} {...sliceProps} />;
      case 'image_and_content':
        return <ImageAndContentSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'tag_and_content':
        return <TagAndContentSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'anchor_navigation':
        return <AnchorNavigationSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'cards_grid':
        return <TextCardsGridSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'related_links_cards':
        return <RelatedLinksCardsSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'content_and_cards':
        return <ContentAndCardsSlice key={`slice-${s.id}`} {...sliceProps} />;
      case 'product_case_studies':
        return <ProductCaseStudiesSlice key={`slice-${s.id}`} {...sliceProps} />;
      default:
        return null;
      }
    }),
    [allSlices],
  );

  return slice;
};

SliceZone.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allSlices: PropTypes.array,
};

export default SliceZone;
