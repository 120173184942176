import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as styles from './Image.module.scss';

const Image = ({
  className,
  imageWrapperClassName,
  source,
  alt,
  alignment,
  isFixedSize,
  roundedBorder,
  ...rest
}) => {
  if (!source || (!source.url && !source.localFile && !source.childImageSharp)) {
    return null;
  }

  let ImageElement = null;
  const imageClassName = cx(className, { [styles.roundedBorder]: roundedBorder });
  // Some images don't have a local file or a publicURL
  // need to check for these as well as the file extension type
  const isSvg = source.localFile?.publicURL && source.localFile.extension === 'svg';

  // Some images are a gif
  const isGif = source.localFile?.publicURL && source.localFile.extension === 'gif';

  // childImageSharp is null because file uploaded is an svg
  // so we need to directly reference the downloaded static svg file
  if (isSvg || isGif) {
    ImageElement = (
      <img src={source.localFile.publicURL} className={imageClassName} alt={alt} {...rest} />
    );
  } else if (isFixedSize) {
    //  used for the home hero link image, the schema structure source.childImageSharp.fixed
    // is different for this image
    ImageElement = (
      <GatsbyImage
        className={imageClassName}
        image={source.childImageSharp.gatsbyImageData}
        alt={alt}
        {...rest}
      />
    );
  } else if (source?.childImageSharp?.gatsbyImageData) {
    // regular image option, most image queries should have a
    // source.localFile.childImageSharp.fluid available
    ImageElement = (
      <GatsbyImage
        className={imageClassName}
        image={source.childImageSharp.gatsbyImageData}
        alt={alt}
        {...rest}
      />
    );
  } else if (source?.localFile) {
    ImageElement = (
      <GatsbyImage
        className={imageClassName}
        image={getImage(source.localFile)}
        alt={alt}
        {...rest}
      />
    );
  }

  // fallback image option is no childImageSharp information is passed in,
  // like for externally hosted images for the job openings thumbnails.
  if (ImageElement === null && source.url) {
    ImageElement = <img src={source.url} className={imageClassName} alt={alt} {...rest} />;
  }

  return (
    <div
      className={cx(
        styles[`image${alignment === 'Full width' ? 'FullWidth' : alignment}`],
        imageWrapperClassName,
      )}
    >
      {ImageElement}
    </div>
  );
};

Image.propTypes = {
  className: PropTypes.string,
  imageWrapperClassName: PropTypes.string,
  alt: PropTypes.string,
  source: PropTypes.shape({
    url: PropTypes.string,
    childImageSharp: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      gatsbyImageData: PropTypes.object,
    }),
    localFile: PropTypes.shape({
      publicURL: PropTypes.string,
      extension: PropTypes.string,
      childImageSharp: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        gatsbyImageData: PropTypes.object,
      }),
    }),
  }).isRequired,
  alignment: PropTypes.oneOf(['Full width', 'Right', 'Left']),
  isFixedSize: PropTypes.bool,
  roundedBorder: PropTypes.bool,
};

Image.defaultProps = {
  className: '',
  imageWrapperClassName: '',
  alt: '',
  alignment: null,
  isFixedSize: false,
  roundedBorder: false,
};

export default Image;
