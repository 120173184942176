import React from 'react';

import * as styles from './Footer.module.scss';
import portableLogoWhite from '../../images/Portable-logo-white.svg';

export const Footer = (props) => (
  <footer data-cy="footer" className={styles.footer} {...props} />
);

export const FooterContainer = (props) => (
  <div className={styles.footerContainer} {...props} />
);

export const FooterGrid = (props) => (
  <div className={styles.footerGrid} {...props} />
);

export const FooterGridItem = (props) => (
  <div className={styles.footerGridItem} {...props} />
);

export const Logo = () => (
  <img className={styles.logo} alt="" src={portableLogoWhite} />
);
