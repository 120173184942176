// extracted by mini-css-extract-plugin
export var BlackText = "IntroductorySectionSlice-module--BlackText--Xxjg8";
export var HiddenLg = "IntroductorySectionSlice-module--HiddenLg--4srBF";
export var HiddenMd = "IntroductorySectionSlice-module--HiddenMd--MvZd3";
export var HiddenXl = "IntroductorySectionSlice-module--HiddenXl--jXF+G";
export var HiddenXs = "IntroductorySectionSlice-module--HiddenXs--ENOqg";
export var TextAlignRightXs = "IntroductorySectionSlice-module--TextAlignRightXs--olT2z";
export var WhiteText = "IntroductorySectionSlice-module--WhiteText--hBb7S";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var circlesContainer = "IntroductorySectionSlice-module--circlesContainer--OlXVJ";
export var container = "IntroductorySectionSlice-module--container--aFkjz";
export var description = "IntroductorySectionSlice-module--description--k1Sfq";
export var desktopWidth = "960px";
export var heading = "IntroductorySectionSlice-module--heading--53gRL";
export var lgBreakpoint = "60rem";
export var link = "IntroductorySectionSlice-module--link--QVFT4";
export var mdBreakpoint = "40rem";
export var spacer = "IntroductorySectionSlice-module--spacer--+cqHO";
export var srOnly = "IntroductorySectionSlice-module--sr-only--ePSyB";
export var visuallyHidden = "IntroductorySectionSlice-module--visually-hidden--T6yD5";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";