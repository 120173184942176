/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Container from '../components/Layouts/Container';
import * as buttonStyles from '../components/Button/Button.module.scss';
import * as styles from './EventbriteSlice.module.scss';

const EventbriteSlice = ({ input: { primary: { eventbrite_id, eventbrite_event_url } } }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.onload = () => {
      window.EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId: eventbrite_id,
        iframeContainerId: 'eventbrite-widget',
      });
    };
    script.src = 'https://www.eventbrite.com.au/static/widgets/eb_widgets.js';
    document.getElementsByTagName('head')[0].appendChild(script);
  }, []);

  return (
    <Container>
      <div className={styles.eventbrite}>
        {eventbrite_id && <div id="eventbrite-widget" />}
        {!eventbrite_id && (
          <a
            href={eventbrite_event_url}
            target="_blank"
            rel="noopener noreferrer"
            className={buttonStyles.Button}
          >
            Register now
          </a>
        )}
      </div>
    </Container>
  );
};

EventbriteSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      eventbrite_id: PropTypes.string,
      eventbrite_event_url: PropTypes.string,
    }),
  }).isRequired,
};

export default EventbriteSlice;
