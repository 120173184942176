/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './ExtLinksSection.module.scss';
import ExtLinkBlock from './ExtLinkBlock';

const ExtLinksSection = ({
  heading,
  links,
}) => {
  const linksList = links.map((l) => (
    <ExtLinkBlock
      key={l.link_heading ? l.link_heading.text : ''}
      heading={l.link_heading ? l.link_heading.text : ''}
      url={l.link_url ? l.link_url.url : ''}
      description={l.link_description ? l.link_description.text : []}
      date={l.link_date || null}
    />
  ));
  return (
    <>
      <section className={styles.blockWrapper}>
        <h3 className={styles.sectionHeading}>
          {heading.text}
        </h3>
        <div className={styles.sectionLinks}>
          <ul className={styles.linksWrapper}>
            {linksList}
          </ul>
        </div>
      </section>
    </>
  );
};

ExtLinksSection.propTypes = {
  heading: PropTypes.object.isRequired,
  links: PropTypes.array.isRequired,
};

export default ExtLinksSection;
