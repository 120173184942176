// extracted by mini-css-extract-plugin
export var BlackText = "ServiceDesignHero-module--BlackText--lRCPJ";
export var HiddenLg = "ServiceDesignHero-module--HiddenLg--IP5Vj";
export var HiddenMd = "ServiceDesignHero-module--HiddenMd--A7oEw";
export var HiddenXl = "ServiceDesignHero-module--HiddenXl--y2-ez";
export var HiddenXs = "ServiceDesignHero-module--HiddenXs--ITEjT";
export var TextAlignRightXs = "ServiceDesignHero-module--TextAlignRightXs--dTj0H";
export var WhiteText = "ServiceDesignHero-module--WhiteText--YQr3k";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "ServiceDesignHero-module--container--13XDF";
export var content = "ServiceDesignHero-module--content--uhms5";
export var desktopWidth = "960px";
export var heading = "ServiceDesignHero-module--heading--6zU8s";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "ServiceDesignHero-module--sr-only--vGIF4";
export var visuallyHidden = "ServiceDesignHero-module--visually-hidden--ly0dS";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";