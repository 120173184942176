/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import HeroLink from '../components/Link/HeroLink';

const HeroLinkSlice = ({
  input: {
    primary: {
      linked_page: {
        document: {
          type,
          uid,
          data: {
            title,
            landscape_image,
            purpose_tags,
          },
        },
      },
    },
  },
}) => {
  const firstPurposeTag = purpose_tags.length >= 1 ? purpose_tags[0].purposes.document.data.purpose : '';

  return (
    <HeroLink
      linkInfo={{ type, uid }}
      title={title}
      image={landscape_image}
      alt={landscape_image.alt || ''}
      tag={firstPurposeTag}
    />
  );
};

HeroLinkSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      linked_page: PropTypes.shape({
        document: PropTypes.object,
      }),
    }),
  }).isRequired,
};

export default HeroLinkSlice;
