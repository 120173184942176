// extracted by mini-css-extract-plugin
export var BlackText = "DownloadForm-module--BlackText--+hv12";
export var HiddenLg = "DownloadForm-module--HiddenLg--ZJ+Ou";
export var HiddenMd = "DownloadForm-module--HiddenMd--gFkas";
export var HiddenXl = "DownloadForm-module--HiddenXl--bd4kH";
export var HiddenXs = "DownloadForm-module--HiddenXs--MQ3cO";
export var TextAlignRightXs = "DownloadForm-module--TextAlignRightXs--AaLiq";
export var WhiteText = "DownloadForm-module--WhiteText--uDcHw";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var checkbox = "DownloadForm-module--checkbox--vhXCC";
export var desktopWidth = "960px";
export var downloadButton = "DownloadForm-module--downloadButton--fnAO7";
export var downloadTitle = "DownloadForm-module--downloadTitle--L0JZ3";
export var errorMessage = "DownloadForm-module--errorMessage--z+c71";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var reportImage = "DownloadForm-module--reportImage--b4qo3";
export var srOnly = "DownloadForm-module--sr-only--rw2K7";
export var successMessageContainer = "DownloadForm-module--successMessageContainer--UrHLG";
export var visuallyHidden = "DownloadForm-module--visually-hidden--1SJxo";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";