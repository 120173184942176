/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from '../components/ui/Body/Body.module.scss';
import ServicesSection from '../components/Layouts/ServicesSection/ServicesSection';

const ServicesSectionSlice = ({
  input: {
    primary: {
      heading,
      image,
      image_caption_heading,
      image_caption_description,
    },
    items,
  },
}) => (
  <div className={styles.content}>
    <ServicesSection
      heading={heading.text}
      image={image}
      image_caption_heading={image_caption_heading.text}
      image_caption_description={image_caption_description.text}
      services={items}
    />
  </div>
);

ServicesSectionSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.array,
  }).isRequired,
};

export default ServicesSectionSlice;
