import React from 'react';
import PropTypes from 'prop-types';

import HeaderBanner from './HeaderBaner/HeaderBanner';
import HeaderNav from './Navigation/HeaderNav';

const HeaderContainer = ({ isHomePage }) => (
  <header>
    <HeaderNav isHomePage={isHomePage} />
    <HeaderBanner />
  </header>
);

HeaderContainer.propTypes = {
  isHomePage: PropTypes.bool,
};

HeaderContainer.defaultProps = {
  isHomePage: false,
};

export default HeaderContainer;
