const getContentAndCardsSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;

  const title = primary.title1?.text?.trim() || null;
  const button = {
    label: primary.button_label?.text?.trim() || null,
    link: primary.button_link?.url || null,
  };

  const { description } = primary;

  const cards = sliceData.items.map((card) => {
    const tag = card.tag1?.text?.trim() || null;
    const cardDescription = card.description?.text?.trim() || null;

    const { image } = card;

    return {
      image,
      tag,
      description: cardDescription,
    };
  });

  return {
    title,
    description,
    button,
    cards,
  };
};

export default getContentAndCardsSliceProps;
