/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Container from '../components/Layouts/Container';
import Image from '../components/ui/Image/Image';
import SymmetricGrid from '../components/Layouts/Grids/SymmetricGrid/SymmetricGrid';
import SymmetricGridItem from '../components/Layouts/Grids/SymmetricGrid/SymmetricGridItem';
import { linkResolver } from '../helpers/linkResolver';

const EventsSlice = ({ input: { primary }, past }) => (
  <StaticQuery
    query={graphql`
      {
        futureEvents: allPrismicEvent(filter: {isFutureEvent: {eq: true}}, sort: {order: ASC, fields: data___start_date_time}) {
          nodes {
            uid
            id
            type
            data {
              start_date_time(formatString: "DD MMMM YYYY")
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
            }
          }
          pageInfo {
            itemCount
          }
        }
        pastEvents: allPrismicEvent(filter: {isFutureEvent: {eq: false}}, sort: {order: DESC, fields: data___start_date_time}) {
          nodes {
            uid
            id
            type
            data {
              start_date_time(formatString: "DD MMMM YYYY")
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
            }
          }
          pageInfo {
            itemCount
          }
        }
      }
    `}
    render={(data) => {
      const events = past ? data.pastEvents : data.futureEvents;
      if (events.pageInfo.itemCount === 0) return null;

      return (
        <>
          <Container>
            <SymmetricGrid gridTitle={primary.events_title.text}>
              {events.nodes.map((event) => (
                <SymmetricGridItem
                  key={event.id}
                  gridLink={linkResolver({ type: event.type, uid: event.uid })}
                  linkText={event.data.title}
                  date={event.data.start_date_time}
                  tags={event.address ? event.address[0].text : ''}
                  roundedBorder
                >
                  <Image
                    source={event.data.thumbnail_image || {}}
                    alt={event.data.thumbnail_image.alt || ''}
                  />
                </SymmetricGridItem>
              ))}
            </SymmetricGrid>
          </Container>
        </>
      );
    }}
  />
);

EventsSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      events_title: PropTypes.object,
    }),
  }).isRequired,
  past: PropTypes.bool,
};

EventsSlice.defaultProps = {
  past: false,
};

export default EventsSlice;
