import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Typography.module.scss';

const SubHeading = ({ children }) => (
  <div className={styles.subHeading}>{ children }</div>
);

SubHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubHeading;
