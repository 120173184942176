import React from 'react';
import PageData from '../../../helpers/pageData';
import SliceZone from '../../Layouts/SliceZone';

const Body = ({ style = {}, ...rest }) => (
  <PageData.Consumer>
    {context => (
      <section style={style} {...rest}>
        <SliceZone allSlices={context.body} />
      </section>
    )}
  </PageData.Consumer>
);

export default Body;
