import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import BodyClassName from 'react-body-classname';
import * as layoutStyles from './Layout.module.scss';
import PageData from '../../helpers/pageData';
import { BLACK_THEME, WHITE_THEME } from '../../constants';
import { setCssVars } from '../../helpers/helpers';

import '../../helpers/global.scss';

const EmptyLayout = ({children }) => {
  const {
    theme
  } = useContext(PageData);

  // Get the appropriate CSS variables so we can use react-helmet to inject them in to the page <head>
  const themeCssVars = theme === 'Black' ? BLACK_THEME : WHITE_THEME;
  // On load (and theme change) use css-vars-ponyfill to apply the CSS variables on legacy browsers (i.e. IE11)
  useEffect(() => {
    if (!window.CSS || !CSS.supports('color', 'var(--fake-var)')) {
      setCssVars(themeCssVars);
    }
  }, [theme]);

  return (
    <>
      <Helmet htmlAttributes={{ class: `theme${theme}` }}>
        <style type="text/css">
          {`:root {
            ${Object.keys(themeCssVars)
              .map((key) => `${key}:${themeCssVars[key]};`)
              .join("")}
          }`}
        </style>
      </Helmet>
      <BodyClassName className={`theme${theme}`}>
        <div className={`wrapper ${layoutStyles.layout}`}>
            {children}
        </div>
      </BodyClassName>
    </>
  );
};

EmptyLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmptyLayout;
