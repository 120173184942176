// extracted by mini-css-extract-plugin
export var BlackText = "UpcomingSessionCard-module--BlackText--ZEIvH";
export var HiddenLg = "UpcomingSessionCard-module--HiddenLg--FddzX";
export var HiddenMd = "UpcomingSessionCard-module--HiddenMd--1gYXo";
export var HiddenXl = "UpcomingSessionCard-module--HiddenXl--91vJM";
export var HiddenXs = "UpcomingSessionCard-module--HiddenXs--mKRhT";
export var TextAlignRightXs = "UpcomingSessionCard-module--TextAlignRightXs--GV1pe";
export var WhiteText = "UpcomingSessionCard-module--WhiteText--IeJkA";
export var amount = "UpcomingSessionCard-module--amount--XZMvI";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var button = "UpcomingSessionCard-module--button--eFGuu";
export var card = "UpcomingSessionCard-module--card--vMccw";
export var cardItems = "UpcomingSessionCard-module--cardItems--XTCwt";
export var container = "UpcomingSessionCard-module--container--Elv8N";
export var dateTime = "UpcomingSessionCard-module--dateTime--3o-+a";
export var description = "UpcomingSessionCard-module--description--6VisB";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "UpcomingSessionCard-module--sr-only--RHjn8";
export var tag = "UpcomingSessionCard-module--tag--Qdvj+";
export var title = "UpcomingSessionCard-module--title--7SaaP";
export var visuallyHidden = "UpcomingSessionCard-module--visually-hidden--ZMa9x";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";