const ImageAndContentCarouselSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const { items } = sliceData;

  const cards = items.map((card) => ({
    image: card.image || null,
    title: card.title1?.text?.trim() || null,
    description: card.description?.text?.trim() || null,
    author: card.author?.text?.trim() || null,
  }));

  return { cards };
};
export default ImageAndContentCarouselSliceProps;
