import React from 'react';

import * as styles from './Footer.module.scss';

import lgbtqiFlag from '../../images/LGBTQ-flag.svg';

export default () => (
  <div className={styles.textBlock}>
    <img
      className={styles.lgbtqiFlag}
      src={lgbtqiFlag}
      alt="LGBTQI+ flag"
    />
    <p>Portable is a proudly inclusive organisation and an ally of the LGBTIQ+ community.</p>
  </div>

);
