/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from '../../components/ui/Body/Body.module.scss';
import { linkResolver } from '../../helpers/linkResolver';

import Grid from '../../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../../components/Layouts/Grids/GeneralGrid/GeneralGridItem';
import LinkButton from '../../components/Link/LinkButton';

const ActionButtonSlice = ({
  layout,
  text,
  url,
}) => {
  if (!text || !url) {
    return null;
  }

  const buttonLinkUrl = url.isExternal ? url.url : linkResolver({ type: url.type, uid: url.uid });

  return (
    <div className={styles.content}>
      <Grid>
        {/* This is an empty grid item to make space to ensure the correct layout. */}
        <GridItem
          spanMd={layout === 'One column' ? '7' : '4'}
          offsetMd={layout === 'One column' ? '5' : '1'}
        />

        <GridItem spanMd={layout === 'One column' ? '12' : '7'} spanLg="7">
          <LinkButton to={buttonLinkUrl} isExternal={url.isExternal} target={url.target}>
            {text}
          </LinkButton>
        </GridItem>
      </Grid>
    </div>
  );
};

ActionButtonSlice.propTypes = {
  layout: PropTypes.oneOf(['One column', 'Two column']).isRequired,
  text: PropTypes.string,
  url: PropTypes.shape({
    isExternal: PropTypes.bool,
    target: PropTypes.string,
    type: PropTypes.string,
    uid: PropTypes.string,
    url: PropTypes.string,
  }),
};

ActionButtonSlice.defaultProps = {
  text: undefined,
  url: undefined,
};

export default ActionButtonSlice;
