const getAnchorNavigationSliceProps = (sliceData) => {
  if (sliceData === null) return null;

  const items = sliceData.items.map((item) => {
    const name = item.navigation_label?.text?.trim() || null;
    const id = item.slice_id?.text?.trim() || null;

    return {
      name,
      id,
    };
  });

  return {
    items,
  };
};

export default getAnchorNavigationSliceProps;
