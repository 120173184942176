// extracted by mini-css-extract-plugin
export var BlackText = "NewsletterSignupForm-module--BlackText--DHkJa";
export var HiddenLg = "NewsletterSignupForm-module--HiddenLg--dTDLg";
export var HiddenMd = "NewsletterSignupForm-module--HiddenMd--RoOZy";
export var HiddenXl = "NewsletterSignupForm-module--HiddenXl--i9iZX";
export var HiddenXs = "NewsletterSignupForm-module--HiddenXs--udEcI";
export var TextAlignRightXs = "NewsletterSignupForm-module--TextAlignRightXs--BlR4A";
export var WhiteText = "NewsletterSignupForm-module--WhiteText--mN96Z";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var circles = "NewsletterSignupForm-module--circles--LEQSc";
export var circlesImage = "NewsletterSignupForm-module--circlesImage--NHVqU";
export var desktopWidth = "960px";
export var errorMessage = "NewsletterSignupForm-module--errorMessage--oWN+Y";
export var formContainer = "NewsletterSignupForm-module--formContainer--gWa-P";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var newsletterSignup = "NewsletterSignupForm-module--newsletterSignup--V1RSy";
export var srOnly = "NewsletterSignupForm-module--sr-only--1PbLT";
export var successMessageContainer = "NewsletterSignupForm-module--successMessageContainer--Vw7+l";
export var unsubscribeMessage = "NewsletterSignupForm-module--unsubscribeMessage--ZVRfE";
export var visuallyHidden = "NewsletterSignupForm-module--visually-hidden--9Nd69";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";