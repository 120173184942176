import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './TextArea.module.scss';

const TextArea = ({
  id,
  name,
  label,
  value,
  className,
  onChange,
  required,
  disabled,
}) => (
  <>
    <label htmlFor={id}>{label}</label>
    <textarea
      id={id}
      name={name}
      value={value}
      className={cx(styles.input, className)}
      onChange={onChange}
      required={required}
      disabled={disabled}
    />
  </>
);

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.node,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextArea.defaultProps = {
  value: '',
  name: null,
  required: false,
  onChange: null,
  disabled: false,
  className: null,
};

export default TextArea;
