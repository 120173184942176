import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicContactPage {
        edges {
          node {
            id
            data {
              body {
                ... on PrismicContactPageDataBodyContactInfo {
                  id
                  items {
                    address_column {
                      html
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // For the purposes of storybook and passing this around and node choking
  // storybook, it's been more useful to treat these as strings, and not live
  // react elements/nodes.
  const studios = (data.allPrismicContactPage?.edges?.[0]?.node?.data?.body?.[0]?.items || [])
    .map((s) => s.address_column.html);

  // @TODO - are these in the CMS in a useful way?
  const contact = (
    `
      <h6>Contact</h6>
      <p><a href="tel:1300323179">1300 323 179</a></p>
      <p><a href="mailto:info@portable.com.au">info@portable.com.au</a></p>
    `
  );

  const social = (
    `
      <h6>Social</h6>
      <p><a href="https://www.instagram.com/portable/">Instagram, </a><a href="https://twitter.com/Portable">Twitter, </a><a href="https://www.linkedin.com/company/portable/">Linkedin, </a><a href="https://www.facebook.com/portable">Facebook</a></p>
      `
  );

  return [...studios, contact, social];
};
