/* eslint-disable camelcase */
import { linkResolver } from '../../helpers/linkResolver';

const getContentSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;
  const {
    layout,
    content_heading,
    content_subtitle,
    content_text,
  } = primary;
  const heading = content_heading?.text?.trim() || null;
  const subtitle = content_subtitle?.text?.trim() || null;
  const subtitleType = content_subtitle?.richText[0]?.type || null;
  const text = content_text;

  let buttonUrl = null;
  let buttonText = null;
  let isExternal = false;

  // Check if there is button text and url and assign them to readable variables
  if (primary.content_button_text
    && primary.content_button_text.text
    && primary.content_button_url
    && primary.content_button_url.raw) {
    buttonText = primary.content_button_text?.text?.trim() || null;

    isExternal = primary.content_button_url.raw.link_type !== 'Document';

    buttonUrl = isExternal
      ? primary.content_button_url.raw.url
      : linkResolver({
        type: primary.content_button_url.raw.type,
        uid: primary.content_button_url.raw.uid,
      });
  }

  return {
    layout,
    heading,
    subtitle,
    subtitleType,
    text,
    buttonText,
    buttonUrl,
    isExternal,
  };
};

export default getContentSliceProps;
