import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './ContactStrip.module.scss';

export const HTMLBlock = ({ data }) => (
  <div className={styles.wrapper}>
    <div dangerouslySetInnerHTML={{ __html: data }} />
  </div>
);

const ContactStrip = ({ contactBlocks }) => (
  <div className={styles.wrapper}>
    {contactBlocks.map((c, i) => <HTMLBlock key={i} data={c} />)}
  </div>
);

ContactStrip.propTypes = {
  contactBlocks: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ContactStrip;
