// extracted by mini-css-extract-plugin
export var BlackText = "ContactInfo-module--BlackText--oOqDZ";
export var HiddenLg = "ContactInfo-module--HiddenLg--yJwEm";
export var HiddenMd = "ContactInfo-module--HiddenMd--7xcPT";
export var HiddenXl = "ContactInfo-module--HiddenXl--VJBEx";
export var HiddenXs = "ContactInfo-module--HiddenXs---aYf7";
export var TextAlignRightXs = "ContactInfo-module--TextAlignRightXs--ntv14";
export var WhiteText = "ContactInfo-module--WhiteText--n9laY";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var block = "ContactInfo-module--block--8qKor";
export var contactInfo = "ContactInfo-module--contactInfo--imeYv";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var marginTop = "ContactInfo-module--marginTop--dgIBC";
export var mdBreakpoint = "40rem";
export var richtext = "ContactInfo-module--richtext--PrexP";
export var srOnly = "ContactInfo-module--sr-only--3dkvv";
export var visuallyHidden = "ContactInfo-module--visually-hidden--ffuPa";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";