/* eslint-disable import/no-named-default */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Carousel from '../../components/Carousel/Carousel';
import { default as QuoteComponent } from '../../components/ui/Body/Quote';
import { Circle } from '../../components/ui/Icons/Icons';
import * as styles from './QuoteCarouselSlice.module.scss';
import Container from '../../components/Layouts/Container';

const QuoteCard = ({ index, quote: { quoteText, quoteDate, quotee } }) => {
  const colours = [
    styles.QuoteCardBackgroundParadise,
    styles.QuoteCardBackgroundEveryday,
    styles.QuoteCardBackgroundSuzie,
    styles.QuoteCardBackgroundRose,
  ];

  return (
    <figure className={cx(styles.QuoteCard, colours[index % colours.length])}>
      <QuoteComponent quoteText={quoteText} />
      <figcaption className={styles.Figcaption}>
        <Circle width={52} height={52} />
        <span className={styles.Quotee}>
          {quotee}, {quoteDate}
        </span>
      </figcaption>
    </figure>
  );
};

QuoteCard.propTypes = {
  index: PropTypes.number.isRequired,
  quote: PropTypes.shape({
    quoteText: PropTypes.string.isRequired,
    quoteDate: PropTypes.string.isRequired,
    quotee: PropTypes.string.isRequired,
  }).isRequired,
};

const QuoteCarouselSlice = ({ quotes }) => (
  <>
    <Container nonCssModuleClass="carousel-measuring-machine" />
    <Carousel>
      {quotes.map((quote, index) => (
        <QuoteCard key={`quote${index + 1}`} index={index} quote={quote} />
      ))}
    </Carousel>
  </>
);

QuoteCarouselSlice.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      quoteText: PropTypes.string.isRequired,
      quoteDate: PropTypes.string.isRequired,
      quotee: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default QuoteCarouselSlice;
