/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PrismicRichText } from '@prismicio/react';

import * as styles from './ProductCaseStudiesSlice.module.scss';
import * as typographyStyles from '../../components/ui/Typography/Typography.module.scss';

import { linkResolver } from '../../helpers/linkResolver';

import LinkButton from '../../components/Link/LinkButton';
import Image from '../../components/ui/Image/Image';

const ProductCaseStudiesSlice = ({
  backgroundColour,
  textColour,
  link,
  name,
  title,
  introduction,
  productImage,
  partnerImage,
}) => (
  <div className={styles.container}>
    <article
      className={styles.sectionProduct}
      style={{
        backgroundColor: backgroundColour,
        '--textColour': `var(--${textColour})`,
      }}
    >
      <div className={styles.contentColumn}>
        <h2 className={cx(styles.name, typographyStyles.smallCapsHeading)}>{name}</h2>
        <h3 className={styles.productTitle}>{title}</h3>
        <div className={styles.contentBody}>
          <PrismicRichText field={introduction.richText} linkResolver={linkResolver} />
        </div>
        <div className={styles.linkAndPartner}>
          <LinkButton
            to={link}
            modifierClass={textColour[0].toUpperCase() + textColour.substring(1)}
          >
            View the <span className="visually-hidden">{name}</span> case study
          </LinkButton>
          {partnerImage?.localFile && (
            <div className={styles.partner}>
              <small className={typographyStyles.smallCapsHeading}>in partnership with</small>
              <Image
                className={styles.partnerImage}
                source={partnerImage}
                alt={partnerImage?.alt || ''}
              />
            </div>
          )}
        </div>
      </div>
      <Image
        className={styles.productImage}
        imageWrapperClassName={styles.imageWrapper}
        source={productImage}
        alt={productImage?.alt || ''}
      />
    </article>
  </div>
);

ProductCaseStudiesSlice.propTypes = {
  backgroundColour: PropTypes.string.isRequired,
  textColour: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  introduction: PropTypes.shape({
    richText: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        spans: PropTypes.array,
      }),
    ),
  }).isRequired,
  productImage: PropTypes.shape({
    localFile: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    alt: PropTypes.string,
  }).isRequired,
  partnerImage: PropTypes.shape({
    localFile: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    alt: PropTypes.string,
  }),
};

ProductCaseStudiesSlice.defaultProps = {
  partnerImage: undefined,
};

export default ProductCaseStudiesSlice;
