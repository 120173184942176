import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as styles from './AlternatingContentMediaBlock.module.scss';
import Image from '../../components/ui/Image/Image';

const AlternatingContentMediaBlock = ({
  counter,
  tag,
  heading,
  headingType,
  content,
  image,
  listItems,
}) => {
  if (!image && !heading) return null;
  return (
    <article className={styles.container}>
      <div className={styles.upperContent}>
        <Image imageWrapperClassName={styles.image} source={image} alt={image?.alt || ''} />
        <p className={cx(styles.tag, counter ? styles.counter : null)}>{tag}</p>
        <h2
          className={cx(
            styles.serviceHeading,
            headingType === 'heading3' ? styles.serviceHeadingH3 : null,
          )}
        >
          {heading}
        </h2>
        <p className={styles.serviceContent}>{content}</p>
      </div>

      {listItems && Array.isArray(listItems) && listItems[0]?.listHeading ? (
        <ul className={styles.lowerContent}>
          {listItems.map((listItem, index) => (
            <li className={styles.listItem} key={`listItem-${index + 1}`}>
              <h3 className={styles.listHeading}>{listItem.listHeading}</h3>
              <p className={styles.listText}>{listItem.listDescription}</p>
            </li>
          ))}
        </ul>
      ) : null}
    </article>
  );
};

AlternatingContentMediaBlock.propTypes = {
  counter: PropTypes.bool.isRequired,
  tag: PropTypes.string,
  heading: PropTypes.string.isRequired,
  headingType: PropTypes.string.isRequired,
  content: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object.isRequired,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      listHeading: PropTypes.string,
      listDescription: PropTypes.string,
    }),
  ),
};

AlternatingContentMediaBlock.defaultProps = {
  tag: undefined,
  content: undefined,
  listItems: undefined,
};

export default AlternatingContentMediaBlock;
