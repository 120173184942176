// extracted by mini-css-extract-plugin
export var BlackText = "OurWork-module--BlackText--IXa3+";
export var HiddenLg = "OurWork-module--HiddenLg--kb9e9";
export var HiddenMd = "OurWork-module--HiddenMd--uLaVa";
export var HiddenXl = "OurWork-module--HiddenXl--pYQcI";
export var HiddenXs = "OurWork-module--HiddenXs--7M+yt";
export var TextAlignRightXs = "OurWork-module--TextAlignRightXs--9lDsT";
export var WhiteText = "OurWork-module--WhiteText--pW-xA";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "OurWork-module--container--4Bg70";
export var contentContainer = "OurWork-module--contentContainer--C8zxq";
export var desktopWidth = "960px";
export var heading = "OurWork-module--heading--cWZSX";
export var icon = "OurWork-module--icon--UbS5M";
export var image = "OurWork-module--image--3Nkie";
export var imageContainer = "OurWork-module--imageContainer--Yn2ck";
export var lgBreakpoint = "60rem";
export var linkText = "OurWork-module--linkText--AWBRD";
export var mdBreakpoint = "40rem";
export var srOnly = "OurWork-module--sr-only--F0qEZ";
export var subLink = "OurWork-module--subLink--tUvZX";
export var tag = "OurWork-module--tag--hcbiN";
export var title = "OurWork-module--title--XsRnr";
export var titleSection = "OurWork-module--titleSection--1LH1w";
export var visuallyHidden = "OurWork-module--visually-hidden--wdQFw";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";