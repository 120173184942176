// extracted by mini-css-extract-plugin
export var BlackText = "ProductCaseStudiesSlice-module--BlackText--yGJZm";
export var HiddenLg = "ProductCaseStudiesSlice-module--HiddenLg--Q6ma1";
export var HiddenMd = "ProductCaseStudiesSlice-module--HiddenMd--kjHII";
export var HiddenXl = "ProductCaseStudiesSlice-module--HiddenXl--pR8fp";
export var HiddenXs = "ProductCaseStudiesSlice-module--HiddenXs--WG9cG";
export var TextAlignRightXs = "ProductCaseStudiesSlice-module--TextAlignRightXs--eMfih";
export var WhiteText = "ProductCaseStudiesSlice-module--WhiteText--rCENY";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "ProductCaseStudiesSlice-module--container--Fxcwb";
export var contentBody = "ProductCaseStudiesSlice-module--contentBody--3wKxT";
export var contentColumn = "ProductCaseStudiesSlice-module--contentColumn--0jeQi";
export var desktopWidth = "960px";
export var imageWrapper = "ProductCaseStudiesSlice-module--imageWrapper--S9Csn";
export var lgBreakpoint = "60rem";
export var linkAndPartner = "ProductCaseStudiesSlice-module--linkAndPartner--+6gBQ";
export var mdBreakpoint = "40rem";
export var name = "ProductCaseStudiesSlice-module--name--nCQwW";
export var partner = "ProductCaseStudiesSlice-module--partner--f9BXM";
export var productImage = "ProductCaseStudiesSlice-module--productImage--q4FgT";
export var productTitle = "ProductCaseStudiesSlice-module--productTitle--mitZo";
export var sectionProduct = "ProductCaseStudiesSlice-module--sectionProduct--EGliZ";
export var srOnly = "ProductCaseStudiesSlice-module--sr-only--Cl4A4";
export var visuallyHidden = "ProductCaseStudiesSlice-module--visually-hidden--502ha";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";