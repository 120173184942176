import { linkResolver } from '../../helpers/linkResolver';

const getOurWorkProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;
  const title = primary.title1?.text?.trim() || null;
  const linkText = primary.link_text?.text?.trim() || null;
  let link = null;
  const isExternal = primary.link.link_type !== 'Document';
  link = isExternal
    ? primary.link.url
    : linkResolver({
      type: primary.link.type,
      uid: primary.link.uid,
    });

  const contents = sliceData.items.map((item) => {
    const image = item.image.url;
    const heading = item.heading?.text?.trim() || null;
    const tagLabel = item.taglabel?.text?.trim() || null;
    let imageLink = null;
    imageLink = isExternal
      ? item.image_link.url
      : linkResolver({
        type: item.image_link.type,
        uid: item.image_link.uid,
      });
    return {
      title,
      linkText,
      link,
      image,
      heading,
      tagLabel,
      imageLink,
    };
  });

  return {
    title,
    linkText,
    link,
    contents,
  };
};
export default getOurWorkProps;
