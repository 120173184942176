// extracted by mini-css-extract-plugin
export var BlackText = "Banner-module--BlackText--BTlUe";
export var HiddenLg = "Banner-module--HiddenLg--vWeH5";
export var HiddenMd = "Banner-module--HiddenMd--cUS1a";
export var HiddenXl = "Banner-module--HiddenXl--Kh5wf";
export var HiddenXs = "Banner-module--HiddenXs--AapIV";
export var TextAlignRightXs = "Banner-module--TextAlignRightXs--Ji6DG";
export var WhiteText = "Banner-module--WhiteText--7ys3N";
export var bannerHeading = "Banner-module--bannerHeading--dmURD";
export var bannerInner = "Banner-module--bannerInner--ythRL";
export var bannerWrapper = "Banner-module--bannerWrapper--peqWC";
export var black = "Banner-module--black--lly28";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var noPadding = "Banner-module--noPadding--m69Fb";
export var srOnly = "Banner-module--sr-only--yPc5z";
export var visuallyHidden = "Banner-module--visually-hidden--Urus9";
export var white = "Banner-module--white--nlVad";
export var whiteHSL = "0 0% 99%";