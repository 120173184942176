import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as styles from './GeneralGrid.module.scss';

const Grid = React.forwardRef(({
  className, align, modifiers, children, ...rest
}, ref) => {
  const classes = cx({
    [styles.Grid]: true,
    [className]: className,
    [styles[`Grid${modifiers}`]]: true,
  });

  return (
    <div className={classes} style={{ alignItems: align }} ref={ref} {...rest}>
      {children}
    </div>
  );
});

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  align: PropTypes.oneOf(['stretch', 'center', 'flex-start', 'flex-end']),
  modifiers: PropTypes.string,
};

Grid.defaultProps = {
  className: '',
  align: null,
  modifiers: null,
};

export default Grid;
