/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion, AccordionItem, AccordionButton, AccordionPanel,
} from '@reach/accordion';
import { PrismicRichText } from '@prismicio/react';

import { linkResolver } from '../../helpers/linkResolver';
import closeIconWhite from '../../images/close-menu-white.svg';
import SiteLink from '../Link/SiteLink';
import * as styles from './Accordion.module.scss';

const AccordionComponent = ({ items }) => {
  // TODO: Move defaultOpen to an option in CMS
  const defaultOpen = 0;

  return (
    <Accordion className={styles.accordion} collapsible multiple defaultIndex={defaultOpen}>
      {items.map(({
        heading, text, linkText, link,
      }, index) => (
        <AccordionItem key={index} uuid={index} className={styles.accordion__item}>
          <h3 className={styles.accordion__heading}>
            <AccordionButton className={styles.accordion__button}>
              <span>{heading}</span>
              <img src={closeIconWhite} alt="" />
            </AccordionButton>
          </h3>
          <AccordionPanel className={styles.accordion__panel}>
            <PrismicRichText field={text.richText} linkResolver={linkResolver} />
            {link && linkText && (
              <div>
                <span>→</span>
                <SiteLink to={link} bold>
                  {linkText}
                </SiteLink>
              </div>
            )}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

AccordionComponent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.element,
      linkText: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
};

export default AccordionComponent;
