/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './ListSlice.module.scss';
import Grid from '../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../components/Layouts/Grids/GeneralGrid/GeneralGridItem';

const TwoColListSlice = ({ input: { primary: { list_heading }, items } }) => {
  const ListItems = items.map(({
    heading, description,
  }) => (
    <GridItem
      key={heading.text}
      spanMd="5"
      offsetMd="1"
      spanLg="5"
      className={styles.listItem}
    >
      <h4 className={styles.title}>{heading.text}</h4>
      <p>{description.text}</p>
    </GridItem>
  ));

  return (
    <div className={styles.list}>
      <h2>{list_heading.text || ''}</h2>
      <Grid className={styles.listItems}>
        {ListItems}
      </Grid>
    </div>
  );
};

TwoColListSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.array,
  }).isRequired,
};

export default TwoColListSlice;
