import cssVars from 'css-vars-ponyfill';
import { isBefore } from 'date-fns';

import { linkResolver } from './linkResolver';

export const setCssVars = (variables) => {
  Object.entries(variables).forEach(([prop, value]) => {
    cssVars({
      variables: {
        [prop]: value,
      },
    });
  });
};

export const shouldUseLightImage = (themeMode, backgroundImage) => themeMode === 'Black' || !!backgroundImage;

/**
 * Map the purpose tags items returned from Prismic to a suitable data structure
 * @param purposeTags
 * @returns {null|[]}
 */
export const mapPrismicPurposeTags = (purposeTags) => {
  if (!purposeTags || !Array.isArray(purposeTags)) return null;

  return purposeTags.map((item) => {
    if (!item.purposes.document) return null;
    return {
      type: item.purposes.type,
      title: item.purposes.document.data.purpose,
    };
  });
};

export const truncateString = (str, num) => {
  if (!str) return str;
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return `${str.slice(0, num)}...`;
};

// fisher-yates array shuffle from https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export const shuffleArray = (array) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    // eslint-disable-next-line no-param-reassign
    array[currentIndex] = array[randomIndex];
    // eslint-disable-next-line no-param-reassign
    array[randomIndex] = temporaryValue;
  }

  return array;
};

/**
 * Merges 2 arrays at together
 * at specific indexes: caseStudiesArray and hubsArray
 * @param caseStudiesArray
 * @param hubsArray
 * @param firstMergeIndex
 * @param smallStep
 * @param bigStep
 * @returns {[]}
 */
export const mergeCaseStudiesAndHubs = (
  caseStudiesArray,
  hubsArray,
  firstMergeIndex,
  smallStep,
  bigStep,
) => {
  // make a copy of the array to be merged at specific indexes
  // so the original array isn't mutated
  const arrayToDistribute = hubsArray.map((i) => i);

  // determine which indecies to place hubs at when combining
  // the allCaseStudies and allHubs arrays
  let nextMergeIndex = firstMergeIndex;
  let nextMergeStepIsBig = true;

  const allItemsArray = [];
  caseStudiesArray.forEach((item, index) => {
    if (index === nextMergeIndex && arrayToDistribute.length > 0) {
      // get the hub to insert
      const hubToInsert = arrayToDistribute[0];
      // remove it from the hubs list
      arrayToDistribute.shift();
      // set up for the next spot to insert the hub
      nextMergeIndex = nextMergeStepIsBig ? nextMergeIndex + bigStep : nextMergeIndex + smallStep;

      nextMergeStepIsBig = !nextMergeStepIsBig;
      // add the hub in
      allItemsArray.push(hubToInsert);
    }
    // add the case study in
    allItemsArray.push(item);
  });
  if (arrayToDistribute.length > 0) {
    return allItemsArray.concat(arrayToDistribute);
  }
  return allItemsArray;
};

/**
 * Get hubspot cookie from the browser
 */
export const getHubspotCookie = () => {
  const allCookies = document.cookie.split('; ');
  const hubspotCookie = allCookies.find((row) => row.startsWith('hubspotutk'));
  let hubspotutk = null;
  if (hubspotCookie) {
    // eslint-disable-next-line prefer-destructuring
    hubspotutk = hubspotCookie.split('=')[1];
  }
  return hubspotutk;
};

export const mapPurposeTagToHubLink = (tag) => {
  // TODO: add purpose tag to hub content type so this
  // dict can be created dynamically
  const purposeTagDict = {
    'Access to justice': 'justice',
    'Death and ageing': 'death-and-ageing',
    Education: 'education',
    'Government innovation': 'government',
    'Mental health': 'mental-health',
  };

  if (purposeTagDict[tag] === undefined) return undefined;

  return linkResolver({
    type: 'hub',
    uid: purposeTagDict[tag],
  });
};

// Sort case studies by order of most recently published
export const sortCaseStudiesByPublicationDate = (caseStudies) => {
  // make a copy of the array to be merged at specific indexes
  // so the original array isn't mutated
  const caseStudiesCopy = caseStudies.map((i) => i);
  // Sort case studies by order of most recently published
  caseStudiesCopy.sort((a, b) => {
    let aDate;
    let bDate;
    if (a.data.publication_date) {
      aDate = new Date(a.data.publication_date);
    } else {
      aDate = new Date(a.first_publication_date);
    }
    if (b.data.publication_date) {
      bDate = new Date(b.data.publication_date);
    } else {
      bDate = new Date(b.first_publication_date);
    }
    if (isBefore(aDate, bDate)) {
      return 1;
    }
    if (isBefore(bDate, aDate)) {
      return -1;
    }
    return 0;
  });

  return caseStudiesCopy;
};

export const waitForThen = (check, callback) => {
  if (check()) {
    callback();
  } else {
    setTimeout(() => {
      waitForThen(check, callback);
    }, 100);
  }
};

export const pluckTagsFromCaseStudy = (caseStudy) => {
  let impacts = [];
  let services = [];

  if (caseStudy.data.purpose_tags && caseStudy.data.purpose_tags.length > 0) {
    impacts = caseStudy.data.purpose_tags
      ?.map((tag) => tag.purposes.document?.data.purpose)
      .filter((impact) => !!impact);
  }

  if (caseStudy.data.service_tags && caseStudy.data.service_tags.length > 0) {
    services = caseStudy.data.service_tags
      ?.map((service) => service.services.document?.data.service)
      .filter((service) => !!service);
  }

  return { ...caseStudy, tags: { impacts, services } };
};

export const pluckTagsFromHub = (hub) => ({
  ...hub,
  tags: {
    impacts: hub.data.purpose_tag.document?.data.purpose,
  },
});

export const pluckTagsFromBlog = (blog) => ({
  ...blog,
  tags: {
    services: blog.data.service_tags
      ?.map((service) => service.services.document?.data.service)
      .filter((service) => !!service),
  },
});

export const getScrollPositionWidthOffset = (id) => {
  // Get the anchor element from the ID
  const anchor = document.getElementById(id);
  if (!anchor) return;

  // check the width of the window
  const viewportWidth = window.innerWidth;
  let value = 0;
  // check for large breakpoint and assign a value to center the div
  if (viewportWidth >= 960) {
    value = 288;
  } else {
    value = 140;
  }

  // Get the position of the anchor, smoothly scroll to it with the desired offset
  const anchorTop = anchor.getBoundingClientRect().top + window.pageYOffset - value;
  window.scrollTo({ top: anchorTop, behavior: 'smooth' });
};

// To replace an id that contains a space with an underscore
export const htmlSafeID = (id) => id.replace(/\s/g, '_').toLowerCase();
