/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../components/ui/Image/Image';
import SymmetricGridItem from '../../components/Layouts/Grids/SymmetricGrid/SymmetricGridItem';
import { linkResolver } from '../../helpers/linkResolver';

const RelatedLinksCards = ({ links, size = 'Half' }) => {
  const filteredLinks = links.filter((link) => link?.related_content?.document !== null);

  const subText = (relatedContentType) => {
    switch (relatedContentType) {
    case 'case_study':
      return 'Case study';
    case 'blog':
      return 'Article';
    case 'event':
      return 'Event';
    case 'report':
      return 'Report';
    default:
      return '';
    }
  };

  return filteredLinks.map(({ related_content: { document: { uid, type, data } } }) => (
    <SymmetricGridItem
      key={uid || ''}
      linkText={data?.title || ''}
      gridLink={linkResolver({ type, uid })}
      subtext={subText(type)}
      size={size}
      roundedBorder
    >
      <Image
        source={data?.thumbnail_image}
        alt=""
      />
    </SymmetricGridItem>
  ));
};

RelatedLinksCards.propTypes = {
  size: PropTypes.string,
  links: PropTypes.array.isRequired,
};

export default RelatedLinksCards;
