import React, { useEffect } from 'react';

import PageData from '../helpers/pageData';
import Layout from './Layouts/Layout';
import Container from './Layouts/Container';
import SiteLink from './Link/SiteLink';

import getPageContextFromData from '../lib/dto/getPageContextFromData';

const FourOFour = () => {
  const dataContext = {
    ...getPageContextFromData(null, null, {}),
    theme: 'Black',
    titleText: 'Page not found',
    pageTitle: 'Page not found',
  };

  // Log the 404 error to Sentry
  useEffect(() => {
    Sentry.captureMessage('User encountered 404 error');
  }, []);

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <Container>
          <h2 data-cy="404-error-page">We can’t find the page that you’re looking for.</h2>

          <p>You might want to visit <SiteLink to="/">home</SiteLink> or drop us an email at <a href="mailto:info@portable.com.au">info@portable.com.au</a>.</p>
        </Container>
      </Layout>
    </PageData.Provider>
  );
};

export default FourOFour;
