import React from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

import * as styles from './TagAndContentSlice.module.scss';
import Container from '../../components/Layouts/Container';
import { htmlSafeID } from '../../helpers/helpers';
import { linkResolver } from '../../helpers/linkResolver';

const TagAndContentSliceProps = {
  tag: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    richText: PropTypes.array,
  }),
};

const TagAndContentSlice = ({
  tag, heading, description,
}) => (
  <Container>
    <div id={`tag_and_content_${htmlSafeID(tag)}`} className={styles.container}>
      <div className={styles.tag}>{tag}</div>
      <h2>{heading}</h2>
      <PrismicRichText field={description.richText} linkResolver={linkResolver} />
    </div>
  </Container>
);

TagAndContentSlice.propTypes = TagAndContentSliceProps;
TagAndContentSlice.defaultProps = TagAndContentSliceProps;

export default TagAndContentSlice;
