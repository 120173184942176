/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './ImageAndContentSlice.module.scss';
import Button from '../../components/Button/Button';
import Image from '../../components/ui/Image/Image';
import SiteLink from '../../components/Link/SiteLink';

const ImageAndContentSliceProps = {
  image: PropTypes.image,
  description: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonLinkTarget: PropTypes.string,
  buttonLinkText: PropTypes.string,
};

const ImageAndContentSlice = ({
  description, image, buttonLink, buttonLinkTarget, buttonLinkText,
}) => (
  <div className={styles.container}>
    <Image source={image} alt="" />
    <div className={styles.contentContainer}>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      <SiteLink
        target={buttonLinkTarget}
        to={buttonLink}
      >
        <Button type="button">{buttonLinkText}</Button>
      </SiteLink>
    </div>
  </div>
);
ImageAndContentSlice.propTypes = ImageAndContentSliceProps;
ImageAndContentSlice.defaultProps = ImageAndContentSliceProps;

export default ImageAndContentSlice;
