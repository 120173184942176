import React from 'react';

// import FourOFour from '../components/404';

const NotFoundPage = () => (
  <>
    <h2 data-cy="404-error-page">We can’t find the page that you’re looking for.</h2>

    <p>You might want to visit <a href="/">home</a> or drop us an email at <a href="mailto:info@portable.com.au">info@portable.com.au</a>.</p>
  </>
);
// return <FourOFour />

export default NotFoundPage;
