/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Container from '../../components/Layouts/Container';
import FilterMenu from '../../components/ui/Filter/FilterMenu';
import PageData from '../../helpers/pageData';

const FilterableCardListSlice = ({
  allItems, allPurposes, allServices, selectedFilters, slug, children,
}) => {
  if (allItems.length === 0) {
    return null;
  }

  const { location } = useContext(PageData);

  // set initial state with all contents of both arrays
  const [selectedOptions, setSelectedOptions] = useState({ items: allItems, filters: {} });

  // Check whether the there's a preset filter in the query and
  // whether it is a purpose or service category before applying it
  const presetImpactFilter = allPurposes.find((p) => {
    const impactSearchQueryFilter = new URLSearchParams(location?.search).get('impact');
    return p === impactSearchQueryFilter;
  });
  const presetServiceFilter = allServices.find((s) => {
    const serviceSearchQueryFilter = new URLSearchParams(location?.search).get('service');
    return (s === serviceSearchQueryFilter);
  });

  // Create opbject for filter menu options
  const [filterOptions, setFilterOptions] = useState([
    {
      id: 'impacts',
      name: 'Area of impact',
      selected: presetImpactFilter,
      options: allPurposes,
      dropdownOptionsVisible: false,
    },
    {
      id: 'services',
      name: 'Service',
      selected: presetServiceFilter,
      options: allServices,
      dropdownOptionsVisible: false,
    },
  ].filter((option) => selectedFilters.indexOf(option.id) !== -1));

  const onClearFilters = () => {
    const updatedFilterState = filterOptions.map((option) => (
      { ...option, selected: null, dropdownOptionsVisible: false }
    ));
    setFilterOptions(updatedFilterState);
    navigate(`/${slug}`);
  };

  const onFilterMenuToggle = (filterId) => {
    const updatedFilterState = filterOptions.map((option) => {
      if (filterId === option.id && option.dropdownOptionsVisible === false) {
        return { ...option, dropdownOptionsVisible: true };
      }
      return (
        { ...option, dropdownOptionsVisible: false }
      );
    });
    setFilterOptions(updatedFilterState);
  };

  // Sets new filter on the list matching with the filterId
  // passes through any currently set fitler option for the other list
  const onFilterSelect = (filterOption, filterId) => {
    const updatedFilterState = filterOptions.map((option) => {
      if (filterId === option.id) {
        return { ...option, selected: filterOption, dropdownOptionsVisible: false };
      }
      return option;
    });
    setFilterOptions(updatedFilterState);
  };

  // Re-calculate the visible case studies when the selected filters change
  useEffect(() => {
    let filteredItems = allItems;
    // This is for the URL params
    const filters = {};

    filterOptions.forEach((filter) => {
      if (!filter.selected) return;

      filters[filter.id] = filter.selected;

      // filter case studies by impact filter
      filteredItems = filteredItems
        .filter((item) => item?.tags?.[filter.id]?.includes(filter.selected));
    });

    setSelectedOptions({ items: filteredItems, filters });

    // convert filters selected to a query sting
    const filtersQuery = new URLSearchParams(filters).toString();

    if (Object.keys(filters).length > 0) {
      navigate(`/${slug}?${filtersQuery}`);
    } else {
      navigate(`/${slug}`);
    }
  }, [filterOptions]);

  return (
    <>
      <Container className="containerMarginBottom">
        <FilterMenu
          onClearFilters={onClearFilters}
          onFilterSelect={onFilterSelect}
          onFilterMenuToggle={onFilterMenuToggle}
          filterOptions={filterOptions}
        />
        {/*
          https://codedaily.io/tutorials/Using-Functions-as-Children-and-Render-Props-in-React-Components
          Whatever component is rendered here will need to be wrapped
          with a SymetricGrid and be responsible for each grid item
        */}
        {children(selectedOptions)}
      </Container>
    </>
  );
};

FilterableCardListSlice.propTypes = {
  allItems: PropTypes.array,
  allPurposes: PropTypes.array,
  allServices: PropTypes.array,
  selectedFilters: PropTypes.array,
  slug: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

FilterableCardListSlice.defaultProps = {
  allItems: [],
  allPurposes: [],
  allServices: [],
  selectedFilters: [],
};

export default FilterableCardListSlice;
