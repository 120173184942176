// extracted by mini-css-extract-plugin
export var BlackText = "Image-module--BlackText--XcztS";
export var HiddenLg = "Image-module--HiddenLg--GqkvY";
export var HiddenMd = "Image-module--HiddenMd--1biHv";
export var HiddenXl = "Image-module--HiddenXl--it+q+";
export var HiddenXs = "Image-module--HiddenXs--cGKKp";
export var TextAlignRightXs = "Image-module--TextAlignRightXs--e9sRo";
export var WhiteText = "Image-module--WhiteText--hjwwL";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var captionHeading = "Image-module--captionHeading--xOH4T";
export var desktopWidth = "960px";
export var imageFullWidth = "Image-module--image--full-width--toC8V";
export var imageLeft = "Image-module--image--left--ULasK";
export var imageRight = "Image-module--image--right--+Abnc";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var roundedBorder = "Image-module--rounded-border--vtRGM";
export var srOnly = "Image-module--sr-only--rvuVq";
export var visuallyHidden = "Image-module--visually-hidden--xdEWR";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";