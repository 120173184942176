import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';

import * as variables from '../../../helpers/_variables.module.scss';
import { truncateString } from '../../../helpers/helpers';
import * as styles from './Filter.module.scss';

const Filter = ({
  handleFilterSelect,
  handleClearFilter,
  toggleMenuOptsVisible,
  filterId,
  filterName,
  filterSelected,
  filterOptions,
  dropdownOptionsVisible,
  isLast,
}) => {
  // Used to determine when to truncate text - at tablet screen width
  const shouldTruncate = useMediaQuery({
    query: `(max-width: ${variables.lgBreakpoint}) and (min-width: ${variables.mdBreakpoint})`,
  });

  return (
    <>
      <div>
        {/** *** Filter title button **** */}
        <button
          type="button"
          onClick={() => toggleMenuOptsVisible(filterId)}
          aria-expanded={dropdownOptionsVisible ? 'true' : 'false'}
          aria-controls={filterId}
          className={cx(styles.filterButton,
            (isLast && styles.lastFilterButton),
            (dropdownOptionsVisible ? styles.openFilter : ''),
            (filterSelected ? styles.hide : ''))}
        >
          <h3 className={styles.buttonLabel}>
            Filter by {filterName}
            <span>
              <svg className={styles.filterArrow} viewBox="0 0 22 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.9 11.4L11.6 15.8 8.4 19H12.7C14.7 16.9 19 12.3 21.4 9.9 19 7.5 14.8 2.9 12.7 0.8H8.4C9.5 1.9 10.6 3 11.6 4 13.2 5.7 15.1 7.6 15.9 8.4H0.1V11.4H15.9Z" />
              </svg>
            </span>
            <span className={styles.hiddenText} aria-hidden="true">oo</span>
          </h3>
        </button>

        {/** *** Filter selected button **** */}
        <button
          type="button"
          onClick={() => handleClearFilter(filterId)}
          className={cx(styles.filterSelectedButton, (!filterSelected ? styles.hide : ''))}
          aria-label={`Clear ${filterSelected} filter`}
        >
          <h4>
            {`${shouldTruncate ? truncateString(filterSelected, 16) : filterSelected} (×)`}
          </h4>
        </button>

        {/** *** Filter options dropdown **** */}
        <div
          className={cx(styles.filterMenu, (!dropdownOptionsVisible ? styles.hide : ''))}
          id={filterId}
          role="group"
          aria-labelledby={`${filterId}-label`}
        >
          <span id={`${filterId}-label`} className={styles.hide}>{`Select ${filterId} filter`}</span>
          {filterOptions.map((option) => (
            <div key={option} className={styles.filterItem}>
              <label
                htmlFor={option}
                className={styles.filterLabel}
                data-selected-value={`${filterId}Filter`}
                data-selected-display-name={`${filterId}Filter`}
              >
                {option}
                <input
                  type="checkbox"
                  id={option}
                  value={option}
                  name={`${filterId}Filter`}
                  checked={filterSelected === option}
                  aria-checked={filterSelected === option}
                  onChange={(e) => {
                    handleFilterSelect(e.target.value, filterId);
                  }}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

Filter.propTypes = {
  handleFilterSelect: PropTypes.func.isRequired,
  handleClearFilter: PropTypes.func.isRequired,
  toggleMenuOptsVisible: PropTypes.func.isRequired,
  filterId: PropTypes.string.isRequired,
  filterName: PropTypes.string,
  filterSelected: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  filterOptions: PropTypes.array,
  dropdownOptionsVisible: PropTypes.bool,
  isLast: PropTypes.bool.isRequired,
};

Filter.defaultProps = {
  filterName: null,
  filterSelected: null,
  filterOptions: null,
  dropdownOptionsVisible: false,
};

export default Filter;
