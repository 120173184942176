import * as React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';

import { linkResolver } from './src/helpers/linkResolver';
import ArticlesPage from './src/pages/articles';
import BlogPost from './src/templates/BlogPost';
import CaseStudy from './src/templates/CaseStudy';
import ContactPage from './src/pages/contact';
import DisciplinePage from './src/templates/DisciplinePage';
import Event from './src/templates/Event';
import EventsPage from './src/pages/events';
import GenericPage from './src/templates/GenericPage';
import HomePage from './src/pages/index';
import Hub from './src/templates/Hub';
import JobOpenings from './src/templates/JobOpenings';
import Report from './src/templates/ReportPost';
import ReportsPage from './src/pages/reports';
import ServicePost from './src/templates/ServicePost';
import ServicePage from './src/pages/services';
import WorkPage from './src/pages/work';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          articles_page: ArticlesPage,
          blog: BlogPost,
          case_study: CaseStudy,
          contact_page: ContactPage,
          discipline_page: DisciplinePage,
          event: Event,
          events_page: EventsPage,
          generic_page: GenericPage,
          home_page: HomePage,
          hub: Hub,
          job_openings: JobOpenings,
          report: Report,
          reports_page: ReportsPage,
          service_post: ServicePost,
          services_page: ServicePage,
          work_page: WorkPage,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);


export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    import('object-fit-images').then(({
      default: ObjectFitImages
    }) => ObjectFitImages());
    console.log(`👍 Object-fit/Object-position are polyfilled`)
  }

  // Polyfill for passive event listeners https://github.com/WICG/EventListenerOptions
  import('eventlistener-polyfill');

  // Polyfill for IE11 to enable smoothscroll on scroll button click on home page
  import('smoothscroll-polyfill').then(smoothscroll => {
    smoothscroll.polyfill();
  });
}

export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  getSavedScrollPosition
}) => {
  const currentPosition = getSavedScrollPosition(routerProps.location);

  if (prevRouterProps && (routerProps.location.pathname === prevRouterProps.location.pathname)) {
    return false;
  };

  window.scrollTo(...(currentPosition || [0, 0]))

  return false
}
