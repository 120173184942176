// extracted by mini-css-extract-plugin
export var BlackText = "home-module--BlackText--CfyRn";
export var HiddenLg = "home-module--HiddenLg--QFT5W";
export var HiddenMd = "home-module--HiddenMd--zJC5o";
export var HiddenXl = "home-module--HiddenXl--Yhhxm";
export var HiddenXs = "home-module--HiddenXs--J44T3";
export var TextAlignRightXs = "home-module--TextAlignRightXs--CCaxs";
export var WhiteText = "home-module--WhiteText--rc95q";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var homePageNavMargin = "home-module--homePageNavMargin--r596s";
export var lgBreakpoint = "60rem";
export var main = "home-module--main--2lkmw";
export var mdBreakpoint = "40rem";
export var srOnly = "home-module--sr-only--HUZ9B";
export var visuallyHidden = "home-module--visually-hidden--yjG8Q";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";