// extracted by mini-css-extract-plugin
export var BlackText = "Layout-module--BlackText--tdbtK";
export var HiddenLg = "Layout-module--HiddenLg--3hi16";
export var HiddenMd = "Layout-module--HiddenMd--VrU0f";
export var HiddenXl = "Layout-module--HiddenXl--433wA";
export var HiddenXs = "Layout-module--HiddenXs--M-Ce0";
export var TextAlignRightXs = "Layout-module--TextAlignRightXs--7BT+v";
export var WhiteText = "Layout-module--WhiteText--EXwOA";
export var bg = "Layout-module--bg--5ITfG";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var layout = "Layout-module--layout--5-KgM";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "Layout-module--sr-only--4Dbwr";
export var visuallyHidden = "Layout-module--visually-hidden--3n5cc";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";