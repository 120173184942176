// extracted by mini-css-extract-plugin
export var BlackText = "TeamSlice-module--BlackText--oSBne";
export var HiddenLg = "TeamSlice-module--HiddenLg--0gdTm";
export var HiddenMd = "TeamSlice-module--HiddenMd--rnGuc";
export var HiddenXl = "TeamSlice-module--HiddenXl--oLkXX";
export var HiddenXs = "TeamSlice-module--HiddenXs--iC6sR";
export var TextAlignRightXs = "TeamSlice-module--TextAlignRightXs--JPSif";
export var WhiteText = "TeamSlice-module--WhiteText--p2DZ8";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var name = "TeamSlice-module--name--b40Ck";
export var srOnly = "TeamSlice-module--sr-only--yWg+2";
export var visuallyHidden = "TeamSlice-module--visually-hidden--4qFS8";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";