const getProductCaseStudiesSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;

  const title = primary.title?.text?.trim() || null;
  const name = primary.name?.text?.trim() || null;
  const link = primary?.link?.url || null;

  const {
    background_colour: backgroundColour,
    text_colour: textColour,
    introduction,
    product_image: productImage,
    partner_image: partnerImage,
  } = primary;

  return {
    backgroundColour,
    textColour,
    link,
    name,
    title,
    introduction,
    productImage,
    partnerImage,
  };
};

export default getProductCaseStudiesSliceProps;
