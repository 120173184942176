/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-named-default */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Carousel from '../../components/Carousel/Carousel';
import * as styles from './ImageAndContentCarousel.module.scss';
import Container from '../../components/Layouts/Container';
import Image from '../../components/ui/Image/Image';
import { BlackCircle } from '../../components/ui/Icons/Icons';

const Card = ({
  index, card: {
    image, title, description, author,
  },
}) => {
  const colours = [
    styles.CardBackgroundParadise,
    styles.CardBackgroundSuzie,
    styles.CardBackgroundClassica,
  ];

  return (
    <article className={cx(styles.Card, (image?.localFile && styles.withImage),
      colours[index % colours.length])}
    >
      { image?.localFile && (
        <Image source={image} alt={image?.alt || ''} imageWrapperClassName={styles.image} />
      )}
      <h3>{title}</h3>
      {description
      && <p>{description}</p>}
      {author
      && (
        <p className={styles.author}>
          <BlackCircle />
          {author}
        </p>
      )}

    </article>
  );
};

Card.propTypes = {
  index: PropTypes.number.isRequired,
  card: PropTypes.shape({
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    author: PropTypes.string,
  }),
};

Card.defaultProps = {
  card: {
    description: null,
    author: null,
  },
};

const ImageAndContentCarousel = ({ cards }) => (
  <>
    <Container nonCssModuleClass="carousel-measuring-machine" />
    <Carousel>
      {cards.map((card, index) => (
        <Card key={`card${index + 1}`} index={index} card={card} />
      ))}
    </Carousel>
  </>
);

ImageAndContentCarousel.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.object.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      author: PropTypes.string,
    }),
  ),
};

ImageAndContentCarousel.defaultProps = {
  cards: {
    description: null,
    author: null,
  },
};

export default ImageAndContentCarousel;
