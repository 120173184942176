import React from 'react';
import PropTypes from 'prop-types';

import ColumnsList from '../components/Layouts/ColumnsList/ColumnsList';
import * as styles from './TeamSlice.module.scss';

const TeamSlice = ({
  input: {
    primary,
    items,
  },
}) => {
  if (primary.sort === 'Alphabetically') {
    // sort team members alphabetically
    items.sort((a, b) => {
      const nameA = a?.team_member?.document?.data?.name?.toUpperCase();
      const nameB = b?.team_member?.document?.data?.name?.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  const teamMembers = items.map((tm) => {
    if (!tm?.team_member?.document) return null;
    return (
      <>
        <h3 className={styles.name}>{tm?.team_member?.document?.data?.name}</h3>
        <p>{tm?.team_member?.document?.data?.title}</p>
      </>
    );
  });

  return <ColumnsList listItems={teamMembers} listHeading={primary.heading.text} />;
};

TeamSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.array,
  }).isRequired,
};

export default TeamSlice;
