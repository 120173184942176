/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Image.module.scss';
import Image from './Image';

const CaptionedImage = ({
  source, alt, image_caption_heading, image_caption_description, ...rest
}) => {
  if (!source || !(source.url || source.localFile)) {
    return null;
  }

  const imageId = image_caption_heading.split(' ').join('-');

  return (
    <figure aria-labelledby={imageId}>
      <Image
        id={imageId}
        source={source}
        alt={alt}
        {...rest}
      />
      {(image_caption_heading || image_caption_description)
        ? (
          <figcaption id={imageId}>
            <h3 className={styles.captionHeading}>
              {image_caption_heading}
            </h3>
            <p>{image_caption_description.text}</p>
          </figcaption>
        )
        : null}
    </figure>
  );
};

CaptionedImage.propTypes = {
  alt: PropTypes.string,
  source: PropTypes.shape({
    url: PropTypes.string,
    localFile: PropTypes.shape({
      publicURL: PropTypes.string,
      extension: PropTypes.string,
      childImageSharp: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        gatsbyImageData: PropTypes.object,
      }),
    }),
  }).isRequired,
  image_caption_heading: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  image_caption_description: PropTypes.array,
};

CaptionedImage.defaultProps = {
  alt: '',
  image_caption_heading: null,
  image_caption_description: null,
};

export default CaptionedImage;
