const TagAndContentSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const {
    primary: { tag1, heading, description },
  } = sliceData;

  return {
    tag: tag1?.text?.trim() || null,
    heading: heading?.text?.trim() || null,
    description: description || null,
  };
};
export default TagAndContentSliceProps;
