import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Helmet } from 'react-helmet';
import { useLocation } from '@gatsbyjs/reach-router';

import useScroll from '../../../helpers/useScroll';
import PageData from '../../../helpers/pageData';
import { shouldUseLightImage } from '../../../helpers/helpers';
import useMediaSize from '../../../helpers/useMediaSize';

import bcorpLogoBlack from '../../../images/bcorp-logo-black.svg';
import bcorpLogoWhite from '../../../images/bcorp-logo-white.svg';
import closeIconBlack from '../../../images/close-menu-black.svg';
import closeIconWhite from '../../../images/close-menu-white.svg';
import portableLogoBlack from '../../../images/Portable-logo-black.svg';
import portableLogoWhite from '../../../images/Portable-logo-white.svg';
import SiteLink from '../../Link/SiteLink';
import * as styles from './HeaderNav.module.scss';
import { HTMLBlock } from '../../Footer/ContactStrip';
import {
  Instagram, Facebook, LinkedIn, Twitter,
} from '../../ui/Icons/Icons';

const HeaderNav = ({ isHomePage }) => {
  const { contactBlocks } = useContext(PageData);
  const dialogRef = useRef(null);

  const [
    melbourneStudioHTML,
    sydneyStudioHTML,
    canberraStudioHTML,
    adelaideStudioHTML,
  ] = contactBlocks;

  const [menuOpen, setMenuOpen] = useState(false);
  // We only toggle the background on the homepage so set to true when not on the homepage
  const [showNavBackground, setShowNavBackground] = useState(!isHomePage);
  const isOpenAndScrolled = useScroll();

  const isDesktop = useMediaSize('desktop');
  const isTablet = useMediaSize('tablet');
  const isMobile = useMediaSize('mobile');

  const closeModal = () => {
    const animationDuration = parseInt(getComputedStyle(document.body).getPropertyValue('--ui-animation-speed').slice(0, -2), 10);
    const fadeAndSlideOutToTop = [
      {
        transform: 'translateY(0%)',
        opacity: '100%',
      },
      {
        transform: 'translateY(-100%)',
        opacity: '66%',
      },
    ];
    dialogRef.current.animate(fadeAndSlideOutToTop, { duration: animationDuration });

    setTimeout(() => {
      setMenuOpen(false);
      dialogRef.current.close();
      dialogRef.current.classList.remove('closing');
    }, animationDuration);
  };

  useEffect(() => {
    // Gatsby weirdness means we need a `setTimeout` of 1ms so that
    // the scrollbar doesn't get calculated before it's visible.
    setTimeout(() => {
      document.documentElement.style.setProperty('--scrollbar-gap', `${window.innerWidth - document.documentElement.clientWidth}px`);
    }, 1);

    const handleCancel = (event) => {
      // the default will instantly close the modal with no animation, so we change it here
      event.preventDefault();
      closeModal();
    };
    dialogRef.current.addEventListener('cancel', handleCancel);
    return dialogRef.current.removeEventListener('cancel', handleCancel);
  }, []);

  /* Listen to scroll to set the background colour on scroll
  so it is transparent over the hero images but solid colour after scrolling. */
  useEffect(() => {
    // We only toggle the background on the homepage
    if (!isHomePage) return undefined;

    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowNavBackground(true);
      } else {
        setShowNavBackground(false);
      }
    };

    const debouncedScroll = debounce(handleScroll, 10);

    window.addEventListener('scroll', debouncedScroll);

    return () => {
      window.removeEventListener('scroll', debouncedScroll);
    };
  }, [isHomePage]);

  const location = useLocation();
  useEffect(() => {
    if (menuOpen) setMenuOpen(false);
    if (dialogRef.current.open) dialogRef.current.close();
  }, [location.href]);

  /* Listen to scroll to show/hide nav */
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    // find current scroll position
    const currentScrollPos = window.pageYOffset;

    // set state based on location info
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);

    // set state to new scroll position
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 15);
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => window.removeEventListener('scroll', debouncedHandleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  // Show and hide the menu overlay
  const [menuClosedPrevScrollPos, setMenuClosedPrevScrollPos] = useState(0);
  const handleClick = () => {
    setTimeout(() => {
      if (menuOpen) {
        closeModal();
        window.scroll(0, menuClosedPrevScrollPos);
      } else {
        setMenuOpen(!menuOpen);
        dialogRef.current.showModal();
        setMenuClosedPrevScrollPos(prevScrollPos);
        window.scroll(0, 0);
      }
    }, 0);
  };

  const NavLogo = ({ useLightImage }) => (
    <SiteLink to="/">
      <img
        className={styles.portableLogo}
        src={
          (useLightImage && !menuOpen) || (!useLightImage && menuOpen)
            ? portableLogoWhite
            : portableLogoBlack
        }
        alt="Portable Logo"
      />
    </SiteLink>
  );

  NavLogo.propTypes = {
    useLightImage: PropTypes.bool.isRequired,
  };

  const SocialLinks = ({ useLightImage }) => (
    <>
      <SiteLink to="tel:1300323179">
        1300 323 179
      </SiteLink>
      <SiteLink to="mailto:info@portable.com.au">
        info@portable.com.au
      </SiteLink>
      <SiteLink to="https://www.instagram.com/portable/">
        <span className="visually-hidden">Instagram</span>
        <Instagram fill={useLightImage ? 'black' : 'white'} aria-hidden="true" />
      </SiteLink>
      <SiteLink to="https://www.facebook.com/portable">
        <span className="visually-hidden">Facebook</span>
        <Facebook fill={useLightImage ? 'black' : 'white'} aria-hidden="true" />
      </SiteLink>
      <SiteLink to="https://www.linkedin.com/company/portable/">
        <span className="visually-hidden">LinkedIn</span>
        <LinkedIn stroke={useLightImage ? 'black' : 'white'} aria-hidden="true" />
      </SiteLink>
      <SiteLink to="https://twitter.com/Portable">
        <span className="visually-hidden">Twitter</span>
        <Twitter stroke={useLightImage ? 'black' : 'white'} aria-hidden="true" />
      </SiteLink>
    </>
  );

  SocialLinks.propTypes = {
    useLightImage: PropTypes.bool.isRequired,
  };

  return (
    <PageData.Consumer>
      {(context) => {
        const useLightImage = shouldUseLightImage(context.theme, context.backgroundImage);

        return (
          <>
            <Helmet htmlAttributes={menuOpen && { class: 'no-scroll' }} />
            <nav
              className={cx(styles.nav, {
                [styles.navActive]: menuOpen,
                [styles.navShowBackground]: showNavBackground,
                [styles.navHidden]: !visible,
              })}
            >
              <div className={styles.menuBar}>
                <div className={styles.menuBarGroup}>
                  <NavLogo useLightImage={useLightImage} />

                  <button
                    data-cy="menu-button"
                    type="button"
                    className={cx(styles.menuButton, {
                      [styles.menuButtonWhite]: useLightImage,
                    })}
                    onClick={handleClick}
                  >
                    Menu <img src={useLightImage ? closeIconWhite : closeIconBlack} alt="" />
                  </button>
                </div>

                {isOpenAndScrolled && menuOpen && !isDesktop && (
                  <hr className={styles.scrollDivider} />
                )}
              </div>

              { /* eslint-disable-next-line max-len */ }
              { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */ }
              <dialog
                ref={dialogRef}
                data-cy="menu-body"
                className={cx(styles.overlay, useLightImage ? 'revertScrollbar' : 'themeBlack')}
                id="navOverlay"
              >
                <div className={styles.innerContainer}>
                  {/* Logo that scrolls up with the other nav content on desktop only */}
                  <div className={styles.logoLinksGroup}>
                    {isDesktop && (
                      <>
                        <NavLogo useLightImage={useLightImage} />
                        <SocialLinks useLightImage={useLightImage} />
                      </>
                    )}
                    <button
                      data-cy="menu-button"
                      type="button"
                      className={cx(styles.menuButton, {
                        [styles.menuButtonWhite]: useLightImage,
                      })}
                      onClick={handleClick}
                    >
                      Close <img src={useLightImage ? closeIconBlack : closeIconWhite} alt="" />
                    </button>
                  </div>

                  <div className={cx(styles.navGroup, styles.container)}>
                    <div className={styles.mainMenuContainer}>
                      <h6 className={styles.subheading}>What we do</h6>
                      <div className={styles.mainMenuWrapper}>
                        <ul className={styles.mainMenu}>
                          <li>
                            <SiteLink to="/purpose">Purpose</SiteLink>
                          </li>
                          <li>
                            <SiteLink to="/process">Process</SiteLink>
                          </li>
                          <li>
                            <SiteLink to="/services">Services</SiteLink>
                          </li>
                          <li>
                            <SiteLink to="/products">Products</SiteLink>
                          </li>
                        </ul>
                        <ul className={styles.mainMenu}>
                          <li>
                            <SiteLink to="/work">Work</SiteLink>
                          </li>
                          <li>
                            <SiteLink to="/learn-with-us">Training</SiteLink>
                          </li>
                          <li>
                            <SiteLink to="/contact">Contact</SiteLink>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {!isDesktop && <hr className={styles.navDivider} />}

                    <div className={styles.rightSection}>

                      <div className={styles.hubMenusContainer}>
                        <div className={styles.hubMenu}>
                          <h6 className={styles.subheading}>Workplace</h6>
                          <ul>
                            <li>
                              <SiteLink to="/team">Team</SiteLink>
                            </li>
                            <li>
                              <SiteLink to="/careers">Careers</SiteLink>
                            </li>
                            <li>
                              <SiteLink to="/partners">Partners</SiteLink>
                            </li>
                          </ul>
                        </div>

                        <div className={styles.hubMenu}>
                          <h6 className={styles.subheading}>Content</h6>
                          <ul>
                            <li>
                              <SiteLink to="/events">Events</SiteLink>
                            </li>
                            <li>
                              <SiteLink to="/reports">Reports</SiteLink>
                            </li>
                            <li>
                              <SiteLink to="/articles">Articles</SiteLink>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {!isDesktop && <hr className={styles.navDivider} />}

                      <div className={styles.impactMenuContainer}>
                        <div>
                          <h6 className={styles.subheading}>Impact areas</h6>
                          <ul className={styles.impactMenu}>
                            <li>
                              <SiteLink to="/justice">Justice</SiteLink>
                            </li>
                            <li>
                              <SiteLink to="/mental-health">Mental health</SiteLink>
                            </li>
                            <li>
                              <SiteLink to="/death-and-ageing">Death and ageing</SiteLink>
                            </li>
                            <li>
                              <SiteLink to="/government">Public sector</SiteLink>
                            </li>
                            <li>
                              <SiteLink to="/education">Education</SiteLink>
                            </li>
                            <li>
                              <SiteLink to="/transport">Transport</SiteLink>
                            </li>
                            <li>
                              <SiteLink to="/environment">Environment</SiteLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={cx(styles.footer, styles.container)}>
                    {isMobile && <hr className={styles.navDivider} />}
                    <div className={styles.footerMenuGroup}>
                      <HTMLBlock data={melbourneStudioHTML} />
                      <HTMLBlock data={sydneyStudioHTML} />
                      <HTMLBlock data={canberraStudioHTML} />
                      <HTMLBlock data={adelaideStudioHTML} />
                    </div>

                    {isDesktop && (
                      <div className={styles.bcorp}>
                        <a href="https://www.bcorporation.net/en-us/find-a-b-corp/company/portable" rel="noreferrer">
                          <img
                            src={useLightImage ? bcorpLogoBlack : bcorpLogoWhite}
                            alt="Portable is a Certified B Corporation"
                          />
                        </a>
                      </div>
                    )}

                    {(isMobile || isTablet) && (
                      <div className={styles.footerMenuGroup}>
                        <a href="https://www.bcorporation.net/en-us/find-a-b-corp/company/portable" rel="noreferrer" className={styles.bcorp}>
                          <img
                            src={useLightImage ? bcorpLogoBlack : bcorpLogoWhite}
                            alt="Portable is a Certified B Corporation"
                          />
                        </a>
                        <div className={styles.socialLinksGroup}>
                          <SocialLinks useLightImage={useLightImage} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </dialog>
            </nav>
            <div className={cx({ [styles.navBarSpacerHomePage]: isHomePage })} />
          </>
        );
      }}
    </PageData.Consumer>
  );
};

HeaderNav.propTypes = {
  isHomePage: PropTypes.bool,
};

HeaderNav.defaultProps = {
  isHomePage: false,
};

export default HeaderNav;
