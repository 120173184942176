// extracted by mini-css-extract-plugin
export var BlackText = "ContactForm-module--BlackText--4d26R";
export var HiddenLg = "ContactForm-module--HiddenLg--43FUY";
export var HiddenMd = "ContactForm-module--HiddenMd--Iz84-";
export var HiddenXl = "ContactForm-module--HiddenXl--5fhnk";
export var HiddenXs = "ContactForm-module--HiddenXs--D7D4h";
export var TextAlignRightXs = "ContactForm-module--TextAlignRightXs--SJzFX";
export var WhiteText = "ContactForm-module--WhiteText--f0zDQ";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var captcha = "ContactForm-module--captcha--paPW1";
export var desktopWidth = "960px";
export var errorMessage = "ContactForm-module--errorMessage--FrYv5";
export var form = "ContactForm-module--form--117nf";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "ContactForm-module--sr-only--WHb5H";
export var submitButton = "ContactForm-module--submitButton--ef-AD";
export var successMessageContainer = "ContactForm-module--successMessageContainer--kDY3o";
export var visuallyHidden = "ContactForm-module--visually-hidden--ftclj";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";