// extracted by mini-css-extract-plugin
export var BlackText = "Video-module--BlackText--LRbCk";
export var HiddenLg = "Video-module--HiddenLg--iaj8e";
export var HiddenMd = "Video-module--HiddenMd--aARjb";
export var HiddenXl = "Video-module--HiddenXl--jl5Bi";
export var HiddenXs = "Video-module--HiddenXs--HoZEE";
export var TextAlignRightXs = "Video-module--TextAlignRightXs--G9-By";
export var WhiteText = "Video-module--WhiteText--i8tCF";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var reactPlayer = "Video-module--reactPlayer--FovFU";
export var srOnly = "Video-module--sr-only--mV0J8";
export var video = "Video-module--video--paorv";
export var visuallyHidden = "Video-module--visually-hidden--4pYih";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";