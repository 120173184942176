import React from 'react';

import * as styles from './Footer.module.scss';

import aboriginalFlag from '../../images/aboriginal-flag.svg';
import torresStraitFlag from '../../images/torres-strait-flag.svg';

export default () => (
  <div className={styles.textBlock}>
    <img
      className={styles.indigenousFlags}
      src={aboriginalFlag}
      alt="Aboriginal flag"
    />
    <img
      className={styles.indigenousFlags}
      src={torresStraitFlag}
      alt="Torres Strait Islander flag"
    />
    <p>
      We pay our respects to the traditional custodians of the lands on which we live and work,
      and to the traditional custodians of the lands and waters which we may visit upon in our
      work. We acknowledge their elders past and present.
      Indigenous sovereignty has never been ceded.
      It always was, and always will be Aboriginal land.
    </p>
  </div>

);
