import React from 'react';
import PropTypes from 'prop-types';
import Image from '../components/ui/Image/Image';

const ImageSlice = ({
  input: {
    primary: {
      alignment,
      image,
    },
  },
}) => (
  <Image
    source={image}
    alt={image.alt}
    alignment={alignment}
  />
);

ImageSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      alignment: PropTypes.oneOf(['Left', 'Right', 'Full width']),
      image: PropTypes.object,
    }).isRequired,
  }).isRequired,
};

export default ImageSlice;
