import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import * as styles from './GeneralGrid.module.scss';

const cx = classNames.bind(styles);

const GridItem = React.forwardRef(({
  span,
  spanMd,
  spanLg,
  spanXLg,
  offset,
  offsetMd,
  offsetLg,
  children,
  classes,
  className,
  ...rest
}, ref) => {
  const rootStyles = cx('Column', classes, className, {
    [`Column${span}`]: span,
    [`Column${spanMd}md`]: spanMd,
    [`Column${spanLg}lg`]: spanLg,
    [`Column${spanXLg}xlg`]: spanXLg,
    [`ColumnOffset${offset}`]: offset,
    [`ColumnOffset${offsetMd}md`]: offsetMd,
    [`ColumnOffset${offsetLg}lg`]: offsetLg,
  });
  return <div className={rootStyles} ref={ref} {...rest}>{children}</div>;
});

GridItem.propTypes = {
  span: PropTypes.string,
  spanMd: PropTypes.string,
  spanLg: PropTypes.string,
  spanXLg: PropTypes.string,
  offset: PropTypes.string,
  offsetMd: PropTypes.string,
  offsetLg: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.string,
  className: PropTypes.string,
};

GridItem.defaultProps = {
  classes: null,
  className: null,
  children: null,
  span: '12',
  spanMd: null,
  spanLg: null,
  spanXLg: null,
  offset: null,
  offsetMd: null,
  offsetLg: null,
};

export default GridItem;
