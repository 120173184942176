// extracted by mini-css-extract-plugin
export var BlackText = "ContentAndCards-module--BlackText--mmvv3";
export var HiddenLg = "ContentAndCards-module--HiddenLg--WS9VP";
export var HiddenMd = "ContentAndCards-module--HiddenMd--gPhcx";
export var HiddenXl = "ContentAndCards-module--HiddenXl--MHp55";
export var HiddenXs = "ContentAndCards-module--HiddenXs--Avy3q";
export var TextAlignRightXs = "ContentAndCards-module--TextAlignRightXs--K-6n4";
export var WhiteText = "ContentAndCards-module--WhiteText--Fc8jf";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var card = "ContentAndCards-module--card--Oj9sp";
export var card__description = "ContentAndCards-module--card__description--dtUKJ";
export var card__image = "ContentAndCards-module--card__image--dozfW";
export var card__tag = "ContentAndCards-module--card__tag--o03-g";
export var cards = "ContentAndCards-module--cards--ikRNJ";
export var content = "ContentAndCards-module--content--0ypZV";
export var contentAndCards = "ContentAndCards-module--contentAndCards--GxVxg";
export var description = "ContentAndCards-module--description--oC8kr";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "ContentAndCards-module--sr-only--LmMLI";
export var title = "ContentAndCards-module--title--HoHHf";
export var visuallyHidden = "ContentAndCards-module--visually-hidden--r4GJ6";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";