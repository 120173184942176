import React from 'react';
import PropTypes from 'prop-types';

import CirclesImage from '../images/intro-circles.svg';
import Container from '../components/Layouts/Container';
import Grid from '../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../components/Layouts/Grids/GeneralGrid/GeneralGridItem';
import SiteLink from '../components/Link/SiteLink';
import { UpRightArrow } from '../components/ui/Icons/Icons';

import * as styles from './IntroductorySectionSlice.module.scss';

const IntroductorySectionSlice = ({
  input: {
    primary: { description, heading },
  },
}) => (
  <>
    <Container>
      <Grid className={styles.container}>
        <GridItem span="10" spanMd="7" spanLg="6" offsetMd="5">
          <h2 className={styles.heading}>{heading.text}</h2>
        </GridItem>
        <GridItem span="10" spanMd="5" spanLg="4" offset="2" offsetMd="1" offsetLg="2">
          <p className={styles.description}>{description.text}</p>
          <span className={styles.link}>
            <SiteLink to="/services">
              <span>See all of our services </span><UpRightArrow />
            </SiteLink>
          </span>
        </GridItem>
        <div className={styles.spacer} />
        <GridItem span="11" spanMd="6" spanLg="6">
          <div className={styles.circlesContainer}>
            <img src={CirclesImage} alt="" />
          </div>
        </GridItem>
      </Grid>
    </Container>
  </>
);

IntroductorySectionSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      heading: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }).isRequired,
      description: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default IntroductorySectionSlice;
