import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NotFoundPage from './404';
import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import Body from '../components/ui/Body/Body';
import getPageContextFromData from '../lib/dto/getPageContextFromData';

const ReportsPage = ({
  data: { prismicReportsPage: { type, uid, data } },
}) => {
  if (!data) return <NotFoundPage />;

  const dataContext = getPageContextFromData(type, uid, data);

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <Body />
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
{
  prismicReportsPage {
    _previewable
    uid
    type
    data {
      title
      seo_title
      seo_description
      page_short_description
      page_colour
      introduction
      body {
        ... on PrismicReportsPageDataBodyReports {
          id
          slice_type
          primary {
            reports_heading {
              text
            }
            reports_subheading {
              text
            }
          }
        }
        ... on PrismicReportsPageDataBodyContent {
          id
          slice_type
          primary {
            content_heading {
              html
              text
            }
            content_subtitle {
              richText
              html
              text
            }
            content_text {
              richText
              text
            }
            layout
          }
        }
      }
    }
  }
}
`;

ReportsPage.propTypes = {
  data: PropTypes.shape({
    prismicReportsPage: PropTypes.object.isRequired,
  }).isRequired,
};

export default withPrismicPreview(ReportsPage);
