// extracted by mini-css-extract-plugin
export var BlackText = "ServicesSection-module--BlackText--liCnb";
export var HiddenLg = "ServicesSection-module--HiddenLg--jDVRt";
export var HiddenMd = "ServicesSection-module--HiddenMd--AvImC";
export var HiddenXl = "ServicesSection-module--HiddenXl--XzfaI";
export var HiddenXs = "ServicesSection-module--HiddenXs--yVsHv";
export var TextAlignRightXs = "ServicesSection-module--TextAlignRightXs--0Z8dU";
export var WhiteText = "ServicesSection-module--WhiteText--2tcIc";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var blockImage = "ServicesSection-module--blockImage--0Itud";
export var blockServices = "ServicesSection-module--blockServices--mswfl";
export var blockWrapper = "ServicesSection-module--blockWrapper--i7xn6";
export var desktopWidth = "960px";
export var iconWrapper = "ServicesSection-module--iconWrapper--2t0Im";
export var lgBreakpoint = "60rem";
export var linkHeading = "ServicesSection-module--linkHeading--8BWbb";
export var mdBreakpoint = "40rem";
export var sectionHeading = "ServicesSection-module--sectionHeading--+iJmq";
export var serviceContent = "ServicesSection-module--serviceContent--VrozX";
export var servicesList = "ServicesSection-module--servicesList--V0LuX";
export var servicesText = "ServicesSection-module--servicesText--KTCIP";
export var srOnly = "ServicesSection-module--sr-only--UCQLO";
export var visuallyHidden = "ServicesSection-module--visually-hidden--osTQ+";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";