// extracted by mini-css-extract-plugin
export var BlackText = "Button-module--BlackText--2ewMb";
export var Button = "Button-module--Button--nopv8";
export var HiddenLg = "Button-module--HiddenLg--u6c8N";
export var HiddenMd = "Button-module--HiddenMd--oWKBG";
export var HiddenXl = "Button-module--HiddenXl--2ppyO";
export var HiddenXs = "Button-module--HiddenXs--bCjD-";
export var TextAlignRightXs = "Button-module--TextAlignRightXs--MyXRu";
export var WhiteText = "Button-module--WhiteText--EKaRJ";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "Button-module--sr-only--g77m9";
export var visuallyHidden = "Button-module--visually-hidden--bxu7N";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";