const getServiceHubHeroSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;
  const header = primary.header?.text?.trim() || null;
  return {
    header,
  };
};

export default getServiceHubHeroSliceProps;
