// extracted by mini-css-extract-plugin
export var BlackText = "GeneralGrid-module--BlackText--2fEjr";
export var Column = "GeneralGrid-module--Column--Gj1v5";
export var Column1 = "GeneralGrid-module--Column1--o77WQ";
export var Column10 = "GeneralGrid-module--Column10---YU63";
export var Column10lg = "GeneralGrid-module--Column10lg--wzXQR";
export var Column10md = "GeneralGrid-module--Column10md--xzUUN";
export var Column10xlg = "GeneralGrid-module--Column10xlg--KkVKp";
export var Column11 = "GeneralGrid-module--Column11--RGBOr";
export var Column11lg = "GeneralGrid-module--Column11lg--yk5+a";
export var Column11md = "GeneralGrid-module--Column11md--GBwGn";
export var Column11xlg = "GeneralGrid-module--Column11xlg--tnw0d";
export var Column12 = "GeneralGrid-module--Column12--Hdh08";
export var Column12lg = "GeneralGrid-module--Column12lg--4Y1D0";
export var Column12md = "GeneralGrid-module--Column12md--lkG8K";
export var Column12xlg = "GeneralGrid-module--Column12xlg--WahPN";
export var Column1lg = "GeneralGrid-module--Column1lg--xtT0e";
export var Column1md = "GeneralGrid-module--Column1md--heTeg";
export var Column1xlg = "GeneralGrid-module--Column1xlg--xlF-z";
export var Column2 = "GeneralGrid-module--Column2--pA+sr";
export var Column2lg = "GeneralGrid-module--Column2lg--d-+Cu";
export var Column2md = "GeneralGrid-module--Column2md--AOuVL";
export var Column2xlg = "GeneralGrid-module--Column2xlg--GypoX";
export var Column3 = "GeneralGrid-module--Column3--YRBcE";
export var Column3lg = "GeneralGrid-module--Column3lg--I3EUq";
export var Column3md = "GeneralGrid-module--Column3md--XfKvb";
export var Column3xlg = "GeneralGrid-module--Column3xlg--Ar8I+";
export var Column4 = "GeneralGrid-module--Column4--Y2thU";
export var Column4lg = "GeneralGrid-module--Column4lg--AzChQ";
export var Column4md = "GeneralGrid-module--Column4md--KiLq+";
export var Column4xlg = "GeneralGrid-module--Column4xlg--ch+bl";
export var Column5 = "GeneralGrid-module--Column5--QTZ0K";
export var Column5lg = "GeneralGrid-module--Column5lg--EgA3O";
export var Column5md = "GeneralGrid-module--Column5md--NCs4s";
export var Column5xlg = "GeneralGrid-module--Column5xlg--wNA25";
export var Column6 = "GeneralGrid-module--Column6--z1bx4";
export var Column6lg = "GeneralGrid-module--Column6lg--1qqDC";
export var Column6md = "GeneralGrid-module--Column6md--ho0cp";
export var Column6xlg = "GeneralGrid-module--Column6xlg--Bgpc9";
export var Column7 = "GeneralGrid-module--Column7--XiD13";
export var Column7lg = "GeneralGrid-module--Column7lg--S79fw";
export var Column7md = "GeneralGrid-module--Column7md--Ug59P";
export var Column7xlg = "GeneralGrid-module--Column7xlg--pxy33";
export var Column8 = "GeneralGrid-module--Column8--3sThM";
export var Column8lg = "GeneralGrid-module--Column8lg--EUpYC";
export var Column8md = "GeneralGrid-module--Column8md--WV3XD";
export var Column8xlg = "GeneralGrid-module--Column8xlg--gAh9v";
export var Column9 = "GeneralGrid-module--Column9--JbVvm";
export var Column9lg = "GeneralGrid-module--Column9lg--W-K1k";
export var Column9md = "GeneralGrid-module--Column9md--tAiJA";
export var Column9xlg = "GeneralGrid-module--Column9xlg--B5mly";
export var ColumnOffset1 = "GeneralGrid-module--ColumnOffset1--Ypjdn";
export var ColumnOffset10 = "GeneralGrid-module--ColumnOffset10--Exgtz";
export var ColumnOffset10lg = "GeneralGrid-module--ColumnOffset10lg--2CD7s";
export var ColumnOffset10md = "GeneralGrid-module--ColumnOffset10md--tbA+k";
export var ColumnOffset10xlg = "GeneralGrid-module--ColumnOffset10xlg--wGu7c";
export var ColumnOffset11 = "GeneralGrid-module--ColumnOffset11--yUvz3";
export var ColumnOffset11lg = "GeneralGrid-module--ColumnOffset11lg--S+QQO";
export var ColumnOffset11md = "GeneralGrid-module--ColumnOffset11md--CRIqy";
export var ColumnOffset11xlg = "GeneralGrid-module--ColumnOffset11xlg--zUyt6";
export var ColumnOffset12 = "GeneralGrid-module--ColumnOffset12--jfqOt";
export var ColumnOffset12lg = "GeneralGrid-module--ColumnOffset12lg--Whtgj";
export var ColumnOffset12md = "GeneralGrid-module--ColumnOffset12md--j87Bd";
export var ColumnOffset12xlg = "GeneralGrid-module--ColumnOffset12xlg--ATvCv";
export var ColumnOffset1lg = "GeneralGrid-module--ColumnOffset1lg--2KnhK";
export var ColumnOffset1md = "GeneralGrid-module--ColumnOffset1md--h4msO";
export var ColumnOffset1xlg = "GeneralGrid-module--ColumnOffset1xlg--tNbNR";
export var ColumnOffset2 = "GeneralGrid-module--ColumnOffset2--t3fgg";
export var ColumnOffset2lg = "GeneralGrid-module--ColumnOffset2lg--gf8Y8";
export var ColumnOffset2md = "GeneralGrid-module--ColumnOffset2md--k12ke";
export var ColumnOffset2xlg = "GeneralGrid-module--ColumnOffset2xlg--xWl+C";
export var ColumnOffset3 = "GeneralGrid-module--ColumnOffset3--a0F+q";
export var ColumnOffset3lg = "GeneralGrid-module--ColumnOffset3lg--Kt8ba";
export var ColumnOffset3md = "GeneralGrid-module--ColumnOffset3md--Cr1vV";
export var ColumnOffset3xlg = "GeneralGrid-module--ColumnOffset3xlg--pPZE6";
export var ColumnOffset4 = "GeneralGrid-module--ColumnOffset4--O9QXV";
export var ColumnOffset4lg = "GeneralGrid-module--ColumnOffset4lg--7szkj";
export var ColumnOffset4md = "GeneralGrid-module--ColumnOffset4md--UFxd4";
export var ColumnOffset4xlg = "GeneralGrid-module--ColumnOffset4xlg--AMHY8";
export var ColumnOffset5 = "GeneralGrid-module--ColumnOffset5--GoVon";
export var ColumnOffset5lg = "GeneralGrid-module--ColumnOffset5lg--ZutFh";
export var ColumnOffset5md = "GeneralGrid-module--ColumnOffset5md--Y6CSt";
export var ColumnOffset5xlg = "GeneralGrid-module--ColumnOffset5xlg--RaBxg";
export var ColumnOffset6 = "GeneralGrid-module--ColumnOffset6--NbntO";
export var ColumnOffset6lg = "GeneralGrid-module--ColumnOffset6lg---R1i2";
export var ColumnOffset6md = "GeneralGrid-module--ColumnOffset6md--qkOuN";
export var ColumnOffset6xlg = "GeneralGrid-module--ColumnOffset6xlg--XCQ5z";
export var ColumnOffset7 = "GeneralGrid-module--ColumnOffset7--NoBIU";
export var ColumnOffset7lg = "GeneralGrid-module--ColumnOffset7lg--7Ug8G";
export var ColumnOffset7md = "GeneralGrid-module--ColumnOffset7md--5mif-";
export var ColumnOffset7xlg = "GeneralGrid-module--ColumnOffset7xlg--yw9wY";
export var ColumnOffset8 = "GeneralGrid-module--ColumnOffset8--bGek-";
export var ColumnOffset8lg = "GeneralGrid-module--ColumnOffset8lg--3+D8T";
export var ColumnOffset8md = "GeneralGrid-module--ColumnOffset8md--v1OUt";
export var ColumnOffset8xlg = "GeneralGrid-module--ColumnOffset8xlg--PZGfL";
export var ColumnOffset9 = "GeneralGrid-module--ColumnOffset9--Dq4FU";
export var ColumnOffset9lg = "GeneralGrid-module--ColumnOffset9lg--68w6L";
export var ColumnOffset9md = "GeneralGrid-module--ColumnOffset9md--f7cHM";
export var ColumnOffset9xlg = "GeneralGrid-module--ColumnOffset9xlg--unJDm";
export var Grid = "GeneralGrid-module--Grid--7fDJk";
export var GridReverse = "GeneralGrid-module--GridReverse--nkYTN";
export var HiddenLg = "GeneralGrid-module--HiddenLg--0z9wE";
export var HiddenMd = "GeneralGrid-module--HiddenMd--TcC3F";
export var HiddenXl = "GeneralGrid-module--HiddenXl--ncBAb";
export var HiddenXs = "GeneralGrid-module--HiddenXs--D2FbV";
export var TextAlignRightXs = "GeneralGrid-module--TextAlignRightXs--ng2jK";
export var WhiteText = "GeneralGrid-module--WhiteText--DUuJ3";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var noColumnPadding = "GeneralGrid-module--noColumnPadding--phU6I";
export var paddingLeft32 = "GeneralGrid-module--paddingLeft32--jfAaY";
export var paddingRight32 = "GeneralGrid-module--paddingRight32--FQmFp";
export var srOnly = "GeneralGrid-module--sr-only--oTvvu";
export var visuallyHidden = "GeneralGrid-module--visually-hidden--XlD-X";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";