import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';

import * as styles from './Collection.module.scss';
import Container from './Container';

const CollectionItem = ({ name }) => {
  const serviceUrl = name.toLowerCase().replace(/ /g, '-');
  return (
    <div data-cy="collection-item" className={styles.CollectionItem}>
      <Link
        className={styles.CollectionItem__inner}
        to={`/services/${serviceUrl}`}
      >
        <h4 className={styles.CollectionItem__title}>
          {name}
        </h4>
      </Link>
    </div>
  );
};

CollectionItem.propTypes = {
  name: PropTypes.string.isRequired,
};

const layoutColumns = [2, 3, 4];

const CollectionItemEmpty = ({ layout }) => (
  <div
    className={classNames(
      styles.CollectionItem,
      styles.CollectionItemEmpty,
      styles[`CollectionItemEmpty${layout}`],
    )}
  />
);

CollectionItemEmpty.propTypes = {
  layout: PropTypes.oneOf(layoutColumns).isRequired,
};

// eslint-disable-next-line react/no-array-index-key
const buildEmptyItems = (size, layout) => Array(size).fill('').map((v, i) => <CollectionItemEmpty {...{ layout }} key={`${layout}${i}`} />);
/*
  The collection grid has three layouts, 2, 3, 4 column.
  We need to generate the empty blocks for each layout my calculating the left
  over spaces for each e.g. .block--empty-2, .block--empty-3, .block--empty-4

  CSS for each of these groups then hides them at each breakpoint.
*/
const getEmptyBlocksForLayouts = (numPopulatedBlocks) => {
  const empties = layoutColumns.map((l) => {
    const remaining = numPopulatedBlocks % l;
    const emptySpaces = remaining && l - remaining;
    return buildEmptyItems(emptySpaces, l);
  }).reduce((allBlocks, blocks) => (
    [...allBlocks, ...blocks]
  ), []);
  return empties;
};

const getName = ({ document: { data } }) => data.service
  || data.process
  || data.purpose;

const CollectionGrid = ({ items }) => {
  if (!items) return null;

  const squares = items.map((square) => {
    const squareName = getName(square.squares);
    return <CollectionItem name={squareName} key={squareName} />;
  });

  const empties = getEmptyBlocksForLayouts(squares.length);
  return [...squares, ...empties];
};

const Collection = (props) => {
  const { collectionList, children } = props;

  return (
    <Container>
      {children}
      <div className={styles.Collection}>
        <CollectionGrid items={collectionList} />
      </div>
    </Container>
  );
};

Collection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  collectionList: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export default Collection;
