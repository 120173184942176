// extracted by mini-css-extract-plugin
export var BlackText = "HubspotEmbeddedForm-module--BlackText--bKJgF";
export var HiddenLg = "HubspotEmbeddedForm-module--HiddenLg--iQjIy";
export var HiddenMd = "HubspotEmbeddedForm-module--HiddenMd--PIwvO";
export var HiddenXl = "HubspotEmbeddedForm-module--HiddenXl--A3MGM";
export var HiddenXs = "HubspotEmbeddedForm-module--HiddenXs--kkqzE";
export var TextAlignRightXs = "HubspotEmbeddedForm-module--TextAlignRightXs--fQtUW";
export var WhiteText = "HubspotEmbeddedForm-module--WhiteText--4AG1e";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var hubspotEmbeddedForm = "HubspotEmbeddedForm-module--hubspotEmbeddedForm--hFO6m";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "HubspotEmbeddedForm-module--sr-only--UOj5f";
export var visuallyHidden = "HubspotEmbeddedForm-module--visually-hidden--OUwtA";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";