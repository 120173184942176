// extracted by mini-css-extract-plugin
export var BlackText = "SymmetricGridItem-module--BlackText--xVik-";
export var HiddenLg = "SymmetricGridItem-module--HiddenLg--zFcrD";
export var HiddenMd = "SymmetricGridItem-module--HiddenMd--ZQEUm";
export var HiddenXl = "SymmetricGridItem-module--HiddenXl--+rY7f";
export var HiddenXs = "SymmetricGridItem-module--HiddenXs--1Ym4V";
export var TextAlignRightXs = "SymmetricGridItem-module--TextAlignRightXs--fLN0n";
export var WhiteText = "SymmetricGridItem-module--WhiteText--xi+On";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var date = "SymmetricGridItem-module--date--5+eQw";
export var desktopWidth = "960px";
export var gridItem = "SymmetricGridItem-module--gridItem--s+cTr";
export var gridItemFull = "SymmetricGridItem-module--gridItem--full--yNrk0";
export var gridItemHalf = "SymmetricGridItem-module--gridItem--half--jgyvW";
export var gridItemLink = "SymmetricGridItem-module--gridItemLink--dZPnv";
export var gridItemThird = "SymmetricGridItem-module--gridItem--third--V2EyR";
export var gridItemTwothirds = "SymmetricGridItem-module--gridItem--twothirds--z-Dzm";
export var image = "SymmetricGridItem-module--image--ngAXf";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var roundedBorder = "SymmetricGridItem-module--roundedBorder--kS8GM";
export var smallDataSubtext = "SymmetricGridItem-module--smallDataSubtext--qkehb";
export var srOnly = "SymmetricGridItem-module--sr-only--IlYPM";
export var subtext = "SymmetricGridItem-module--subtext--pqz40";
export var subtextLink = "SymmetricGridItem-module--subtextLink--1f8g3";
export var tags = "SymmetricGridItem-module--tags--yOf-b";
export var title = "SymmetricGridItem-module--title--xezrw";
export var visuallyHidden = "SymmetricGridItem-module--visually-hidden--6fW6X";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";