import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from './Filter.module.scss';
import FilterMenuItem from './FilterMenuItem';

const Filter = ({
  onClearFilters,
  onFilterSelect,
  onFilterMenuToggle,
  filterOptions,
}) => {
  // Adds height to spacing div so that absolutely positioned menu dropdown
  // does not overlap other page content.
  // Menu is absolutely positioned so that the tab order is logical for the user.
  const [bufferHeight, setBufferHeight] = useState(0);
  useEffect(() => {
    let menuHeight = 0;
    filterOptions.forEach((option) => {
      if (option.dropdownOptionsVisible) {
        // TODO: Can we use a React ref here, or a ResizeObserver?
        menuHeight = document.getElementById(option.id).getBoundingClientRect().height;
      }
    });
    setBufferHeight(menuHeight);
  }, [filterOptions]);

  return (
    <>
      <div className={styles.filter}>
        <h2 className={styles.specialHeading}>CURRENTLY DISPLAYING:</h2>
        <hr />

        {/** *** Filter Menu **** */}
        <div className={styles.filterUpperMenu}>
          {/** *** All of our work filter option **** */}
          <button
            type="button"
            onClick={onClearFilters}
            className={styles.allButton}
          >
            <h3 className={styles.buttonLabel}>All of our work</h3>
          </button>

          {/** *** Other filter options as per the menuOptions list **** */}
          {filterOptions.map((f, i) => (
            <FilterMenuItem
              handleFilterSelect={onFilterSelect}
              handleClearFilter={() => onFilterSelect(null, f.id)}
              toggleMenuOptsVisible={() => onFilterMenuToggle(f.id)}
              filterId={f.id}
              filterName={f.name}
              filterSelected={f.selected}
              filterOptions={f.options}
              dropdownOptionsVisible={f.dropdownOptionsVisible}
              key={f.name}
              isLast={i === (filterOptions.length - 1)}
            />
          ))}
        </div>

        {/** *** Spacing Div - give absolutely positioned dropdown menus space **** */}
        <div
          className={cx(styles.menuBuffer)}
          id="buffer"
          style={{ height: bufferHeight }}
        />
      </div>

    </>
  );
};

Filter.propTypes = {
  onClearFilters: PropTypes.func.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  onFilterMenuToggle: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    selected: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.array,
    id: PropTypes.string,
    dropdownOptionsVisible: PropTypes.bool,
  })).isRequired,
};

export default Filter;
