// extracted by mini-css-extract-plugin
export var BlackText = "ContactStrip-module--BlackText--jbxW3";
export var HiddenLg = "ContactStrip-module--HiddenLg--IDP16";
export var HiddenMd = "ContactStrip-module--HiddenMd--Zj9Ug";
export var HiddenXl = "ContactStrip-module--HiddenXl--qr2xX";
export var HiddenXs = "ContactStrip-module--HiddenXs--Bi3O4";
export var TextAlignRightXs = "ContactStrip-module--TextAlignRightXs--k9mcS";
export var WhiteText = "ContactStrip-module--WhiteText--QVhHl";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "ContactStrip-module--sr-only--NuwZU";
export var visuallyHidden = "ContactStrip-module--visually-hidden--yt2DR";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";
export var wrapper = "ContactStrip-module--wrapper--ejoxV";