import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'gatsby';
import * as styles from './SiteLink.module.scss';

const LinkButton = ({
  to, children, isExternal, modifierClass, ...rest
}) => {
  // If the link isn't a page on the website, open it in a new tab
  if (isExternal) {
    return (
      <a
        className={cx(
          styles.linkButton,
          modifierClass && styles[`linkButton${modifierClass}`],
        )}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      to={to}
      className={cx(
        styles.linkButton,
        modifierClass && styles[`linkButton${modifierClass}`],
      )}
      {...rest}
    >
      {children}
    </Link>
  );
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  isExternal: PropTypes.bool,
  modifierClass: PropTypes.string,
  rest: PropTypes.node,
};

LinkButton.defaultProps = {
  children: '',
  isExternal: false,
  modifierClass: null,
  rest: null,
};

export default LinkButton;
