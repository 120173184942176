// @todo work out what exactly the linkResolver does (should we be calling it directly in <Link />?)
exports.linkResolver = (doc) => {
  if (!doc.uid) return;

  switch (doc.type) {
  case 'event':
    return `/events/${doc.uid}`;
  case 'case_study':
    return `/work/${doc.uid}`;
  case 'service_post':
    return `/services/${doc.uid}`;
  case 'job_openings':
    return `/careers/${doc.uid}`;
  case 'report':
    return `/reports/${doc.uid}`;
  case 'blog':
    return `/articles/${doc.uid}`;
  case 'home_page':
    return '/';
  default:
    return `/${doc.uid}`;
  }
};
