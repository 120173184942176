// extracted by mini-css-extract-plugin
export var BlackText = "ListSlice-module--BlackText--mtXXh";
export var HiddenLg = "ListSlice-module--HiddenLg--DTz6G";
export var HiddenMd = "ListSlice-module--HiddenMd--UwO-+";
export var HiddenXl = "ListSlice-module--HiddenXl--mwl0z";
export var HiddenXs = "ListSlice-module--HiddenXs--o64Wc";
export var TextAlignRightXs = "ListSlice-module--TextAlignRightXs--QJmno";
export var WhiteText = "ListSlice-module--WhiteText--0Hkd-";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var icon = "ListSlice-module--icon--QndGg";
export var lgBreakpoint = "60rem";
export var list = "ListSlice-module--list--MBoeF";
export var listItem = "ListSlice-module--listItem--x3gN+";
export var listItems = "ListSlice-module--listItems--HlnsW";
export var mdBreakpoint = "40rem";
export var srOnly = "ListSlice-module--sr-only--GD0hP";
export var tag = "ListSlice-module--tag--XTcr7";
export var title = "ListSlice-module--title--KoGvA";
export var visuallyHidden = "ListSlice-module--visually-hidden--2Lx1j";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";