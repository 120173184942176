/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-typescript';

// Prism line number plugin
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

const Code = ({
  code,
  language,
}) => {
  const l = language.toLowerCase();

  useEffect(() => {
    // call the highlightAll() function to style our code blocks
    Prism.highlightAll();
  }, [language, code]);

  return (
    <pre className={`line-numbers language-${l}`}>
      <code>{code}</code>
    </pre>
  );
};

Code.propTypes = {
  code: PropTypes.string.isRequired,
  language: PropTypes.oneOf(['javascript', 'typescript', 'php']).isRequired,
};

export default Code;
