import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PrismicRichText } from '@prismicio/react';

import { linkResolver } from '../helpers/linkResolver';
import * as styles from './ValuesSlice.module.scss';
import Grid from '../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../components/Layouts/Grids/GeneralGrid/GeneralGridItem';

/* Calculates the number of grid columns that should fill the space before the open
tab item at the desktop breakpoint.
This allows the value heading and description to be nested directly under the open tab.
The total number of columns is always 12 to span the full width of the page
*/
const getColsBeforeDesktop = (valNumber) => {
  // The first tab should not have any columns before,
  // then each one after should have 2 columns per tab before it
  const numberOfColums = (valNumber * 2) - 2;
  return numberOfColums;
};

/* Calculates the number of columns to offset after each desktop heading so that the
colsBeforeDesktop spacer is in the correct position */
const getColsOffsetAfterDesktop = (colsBeforeDesktop) => {
  /* The first tab should have 6 columns after it,
    then each one after should have the total number of columns minus the columns before
    the value heading and description, and minus the number of columns of
    the heading and description text */
  const totalColumns = 12;
  const textBlockWidth = 6;
  const numberOfColums = totalColumns - (colsBeforeDesktop + textBlockWidth);
  return numberOfColums;
};

const ValuesSlice = ({ input: { primary: { heading }, items } }) => {
  const [openValue, setOpenValue] = useState(1);

  const values = items.map(({ value: { document: { data } } }) => ({
    name: data.name.text,
    description: data.description,
    imgUrl: data.image.localFile?.publicURL,
    imgAlt: data.image.alt || '',
  }));

  return (
    <div className={styles.container}>
      <Grid className={styles.header}>
        <GridItem
          span="12"
          spanMd="5"
          spanLg="8"
          className={styles.headerText}
        >
          <h2>{heading.text}</h2>
        </GridItem>
        <GridItem
          className={styles.headerImageSpacer}
          span="0"
          spanMd="2"
          spanLg="0"
        />
        {values.map((value, index) => {
          const valNumber = index + 1;
          return (
            <GridItem
              key={valNumber}
              span="0"
              spanMd="5"
              spanLg="4"
              className={cx(
                openValue === valNumber ? styles.showHeaderImage : styles.hideHeaderImage,
              )}
            >
              <img src={value.imgUrl} alt={value.imgAlt} style={{ width: '100%' }} />
            </GridItem>
          );
        })}
      </Grid>

      {/* TABS */}
      <Grid className={styles.tabs} role="tablist" aria-label="Values Tabs">
        {values.map((value, index) => {
          const valNumber = index + 1;
          return (
            <GridItem
              key={value.name}
              span={openValue === valNumber ? '3' : '3'}
              spanMd={openValue === valNumber ? '6' : '2'}
              className={styles.tabsContainer}
            >
              <button
                role="tab"
                aria-selected={openValue === valNumber}
                aria-controls={`panel-${valNumber}`}
                id={`tab-${valNumber}`}
                tabIndex={index}
                type="button"
                className={cx(
                  styles.tab,
                  openValue === valNumber ? styles.selectedTab : '',
                )}
                onClick={() => setOpenValue(valNumber)}
                aria-label={`Value: ${value.name}`}
              >
                {`0${valNumber}.`}
              </button>
              {openValue === valNumber ? null : (
                <div className={styles.closedValueLabelContainer}>
                  {/* Removed from the tab order so that people using screen readers
                  do not have to listen to the same label twice */}
                  <button
                    type="button"
                    onClick={() => setOpenValue(valNumber)}
                    className={styles.closedValueLabel}
                    aria-hidden="true"
                    tabIndex="-1"
                  >
                    <p style={{ paddingTop: '17px' }}>{value.name}</p>
                  </button>
                </div>
              )}
            </GridItem>
          );
        })}
      </Grid>

      {/* TAB PANELS */}
      <div className={styles.values}>
        {values.map((val, index) => {
          const valNumber = index + 1;
          const colsBeforeDesktop = getColsBeforeDesktop(valNumber);
          const colsOffsetDesktop = getColsOffsetAfterDesktop(colsBeforeDesktop);
          return (
            <Grid
              key={val.name}
              id={`panel-${valNumber}`}
              role="tabpanel"
              tabindex={index}
              aria-labelledby={`tab-${valNumber}`}
            >
              {index === 0 ? null : (
                <GridItem
                  key={`spacer-${val.name}`}
                  span={colsBeforeDesktop}
                  className={
                    openValue === valNumber
                      ? styles.openSpacer
                      : styles.closedSpacer
                  }
                />
              )}
              <GridItem
                span="6"
                spanMd="7"
                spanLg="6"
                offsetLg={colsOffsetDesktop}
                className={cx(
                  openValue === valNumber
                    ? styles.openValue
                    : styles.closedValue,
                )}
              >
                <h3 className={styles.valueNameOpen}>{val.name}</h3>
              </GridItem>
              <GridItem
                span="6"
                spanMd="0"
                spanLg="0"
                className={cx(
                  styles.mobileImage,
                  openValue === valNumber
                    ? styles.openValue
                    : styles.closedValue,
                )}
              >
                <img
                  src={val.imgUrl}
                  alt={val.imgAlt}
                  style={{ width: '100%' }}
                />
              </GridItem>
              {index === 0 ? null : (
                <GridItem
                  key={`spacer-${val.name}`}
                  span={colsBeforeDesktop}
                  className={
                    openValue === valNumber
                      ? styles.openSpacer
                      : styles.closedSpacer
                  }
                />
              )}
              <GridItem
                span="12"
                spanMd="10"
                spanLg="6"
                className={cx(
                  openValue === valNumber
                    ? styles.openValue
                    : styles.closedValue,
                )}
              >
                <PrismicRichText field={val?.description.richText} linkResolver={linkResolver} />
              </GridItem>
            </Grid>
          );
        })}
      </div>
    </div>
  );
};

ValuesSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      heading: PropTypes.shape({
        text: PropTypes.string,
      }),
    }).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      value: {
        document: {
          data: {
            name: PropTypes.object,
            description: PropTypes.object,
            image: PropTypes.object,
          },
        },
      },
    })),
  }).isRequired,
};

export default ValuesSlice;
