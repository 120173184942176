// extracted by mini-css-extract-plugin
export var BlackText = "TextField-module--BlackText--5RZx5";
export var HiddenLg = "TextField-module--HiddenLg--mmBNs";
export var HiddenMd = "TextField-module--HiddenMd--D-vjJ";
export var HiddenXl = "TextField-module--HiddenXl--YbrpA";
export var HiddenXs = "TextField-module--HiddenXs--CN9BG";
export var TextAlignRightXs = "TextField-module--TextAlignRightXs--bEo3F";
export var WhiteText = "TextField-module--WhiteText--g540F";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var input = "TextField-module--input--3o3zy";
export var inputSuccess = "TextField-module--input--success--hmVgE";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "TextField-module--sr-only--oGgTA";
export var visuallyHidden = "TextField-module--visually-hidden--sec3R";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";