// extracted by mini-css-extract-plugin
export var BlackText = "SymmetricGrid-module--BlackText--VjPXW";
export var HiddenLg = "SymmetricGrid-module--HiddenLg--2cCs7";
export var HiddenMd = "SymmetricGrid-module--HiddenMd--5Znjf";
export var HiddenXl = "SymmetricGrid-module--HiddenXl--jYGm7";
export var HiddenXs = "SymmetricGrid-module--HiddenXs--t+J2q";
export var TextAlignRightXs = "SymmetricGrid-module--TextAlignRightXs---6dkm";
export var WhiteText = "SymmetricGrid-module--WhiteText--cLJCD";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var grid = "SymmetricGrid-module--grid--TM1Ev";
export var gridContainer = "SymmetricGrid-module--gridContainer--OMLCe";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "SymmetricGrid-module--sr-only--Gv+CP";
export var visuallyHidden = "SymmetricGrid-module--visually-hidden--89+Uq";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";