// extracted by mini-css-extract-plugin
export var BlackText = "ServiceSection-module--BlackText--xK-Bd";
export var HiddenLg = "ServiceSection-module--HiddenLg--n3tqE";
export var HiddenMd = "ServiceSection-module--HiddenMd--3KnJf";
export var HiddenXl = "ServiceSection-module--HiddenXl--bgTPI";
export var HiddenXs = "ServiceSection-module--HiddenXs--6+fLb";
export var TextAlignRightXs = "ServiceSection-module--TextAlignRightXs--rPUU+";
export var WhiteText = "ServiceSection-module--WhiteText--0mFbC";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var description = "ServiceSection-module--description--4lk3I";
export var desktopWidth = "960px";
export var heading = "ServiceSection-module--heading--afsAm";
export var headingIconColumn = "ServiceSection-module--headingIconColumn--qHKXY";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var p = "ServiceSection-module--p--VzjX5";
export var serviceContainer = "ServiceSection-module--serviceContainer--d78Qo";
export var serviceItemDescription = "ServiceSection-module--serviceItemDescription--sUKMf";
export var serviceItemTitle = "ServiceSection-module--serviceItemTitle--oJkd4";
export var serviceList = "ServiceSection-module--serviceList--H-V+G";
export var serviceListColumn = "ServiceSection-module--serviceListColumn--SakEd";
export var services = "ServiceSection-module--services--yval8";
export var srOnly = "ServiceSection-module--sr-only--5CS7h";
export var svg = "ServiceSection-module--svg--MxjGd";
export var tag = "ServiceSection-module--tag--a9Hs4";
export var tagContainer = "ServiceSection-module--tagContainer--tUX07";
export var twoColumnContent = "ServiceSection-module--twoColumnContent--1pOHw";
export var visuallyHidden = "ServiceSection-module--visually-hidden--Kts4r";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";