const getContentSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const { items } = sliceData;
  const quotes = items.map((item) => ({
    quoteText: item.quote_text?.text?.trim() || null,
    quoteDate: item.quote_date?.text?.trim() || null,
    quotee: item.quotee?.text?.trim() || null,
  }));

  return { quotes };
};

export default getContentSliceProps;
