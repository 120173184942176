// extracted by mini-css-extract-plugin
export var BlackText = "EventbriteSlice-module--BlackText--WEJUh";
export var HiddenLg = "EventbriteSlice-module--HiddenLg--UDIL4";
export var HiddenMd = "EventbriteSlice-module--HiddenMd--0TEH5";
export var HiddenXl = "EventbriteSlice-module--HiddenXl--ZTR2K";
export var HiddenXs = "EventbriteSlice-module--HiddenXs--2BykZ";
export var TextAlignRightXs = "EventbriteSlice-module--TextAlignRightXs--1yxXC";
export var WhiteText = "EventbriteSlice-module--WhiteText--BIL-d";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var eventbrite = "EventbriteSlice-module--eventbrite--prDPk";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "EventbriteSlice-module--sr-only--zlHvI";
export var visuallyHidden = "EventbriteSlice-module--visually-hidden--B5WHI";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";