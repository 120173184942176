import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './ServiceHubHeroSlice.module.scss';
import Container from '../../components/Layouts/Container';
import { getScrollPositionWidthOffset } from '../../helpers/helpers';

const ServiceHubHeroSliceProps = {
  header: PropTypes.string,
};

const ServiceHubHeroSlice = ({ header }) => {
  const onClickScrollLink = (event, id) => {
    event.preventDefault();
    getScrollPositionWidthOffset(id);
  };
  return (

    <Container>
      <div className={styles.container}>
        <div>
          <h2 className={styles.bannerText}>
            {header}
          </h2>
        </div>
        <h2 className={styles.span}>
          <span className={styles.active}>
            <a className={styles.link} href="#Design with the people you serve." onClick={(event) => onClickScrollLink(event, 'Design with the people you serve.')}>Design</a> with the people you serve.<br />
          </span>
          <span className={styles.active}>
            Deliver{' '}
            <a className={styles.link} href="#Deliver digital products and services." onClick={(event) => onClickScrollLink(event, 'Deliver digital products and services.')}>digital products</a> and services. <br />
          </span>
          <span className={styles.active}>
            <a className={styles.link} href="#Communicate with your audiences." onClick={(event) => onClickScrollLink(event, 'Communicate with your audiences.')}>Communicate</a> with your audiences.<br />
          </span>
          <span className={styles.active}>
            <a className={styles.link} href="#Change how your organisation works." onClick={(event) => onClickScrollLink(event, 'Change how your organisation works.')}>Change</a> how your organisation works.<br />
          </span>
        </h2>
        <div className={styles.icon}>
          <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="37.5" cy="37.5" r="37.5" fill="#181818" />
            <path d="M36.1 44.12C34.42 42.38 28 36.11 28 36.11L28 40.7C30.25 42.89 35.23 47.45 37.78 49.97C40.3 47.45 45.25 42.89 47.5 40.7L47.5 36.11C47.5 36.11 41.08 42.38 39.4 44.12L39.4 27.17L36.1 27.17L36.1 44.12Z" fill="#FCFCFC" />
          </svg>
        </div>
      </div>
    </Container>
  );
};

ServiceHubHeroSlice.propTypes = ServiceHubHeroSliceProps;

ServiceHubHeroSlice.defaultProps = ServiceHubHeroSliceProps;

export default ServiceHubHeroSlice;
