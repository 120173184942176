import React from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

import * as styles from './ContentAndCards.module.scss';
import Container from '../../components/Layouts/Container';
import LinkButton from '../../components/Link/LinkButton';
import Image from '../../components/ui/Image/Image';
import { linkResolver } from '../../helpers/linkResolver';

const Card = (card) => {
  const { image, description, tag } = card;
  return (
    <>
      <Image className={styles.card__image} source={image} alt={tag} />
      <p className={styles.card__description}>{description}</p>
      <p className={styles.card__tag}>{tag}</p>
    </>
  );
};

const Cards = ({ cards }) => (
  <ul className={styles.cards}>
    {cards.map((card, index) => (
      <li className={styles.card} key={`${index + 1}${card.tag}`}>
        <Card {...card} />
      </li>
    ))}
  </ul>
);

const ContentAndCards = ({
  title, description, button, cards,
}) => (
  <Container>
    <article className={styles.contentAndCards}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.description}>
          <PrismicRichText field={description.richText} linkResolver={linkResolver} />
        </div>
        { button && button.label && button.link && (
          <p>
            <LinkButton
              to={button.link}
            >
              {button.label}
            </LinkButton>
          </p>
        )}
      </div>
      <Cards cards={cards} />
    </article>
  </Container>
);

const ContentAndCardsPropTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    richText: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        spans: PropTypes.array,
      }),
    ),
  }).isRequired,
  button: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      image: PropTypes.object,
      description: PropTypes.string,
      tag: PropTypes.string,
    }),
  ).isRequired,
};

ContentAndCards.propTypes = ContentAndCardsPropTypes;
Cards.propTypes = ContentAndCardsPropTypes.cards;

export default ContentAndCards;
