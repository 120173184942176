import React from 'react';
import PropTypes from 'prop-types';

import { truncateString } from '../../../helpers/helpers';
import * as styles from './ServicesSection.module.scss';
import SiteLink from '../../Link/SiteLink';

const ServicesTextListItem = ({
  headingText, description, serviceHref,
}) => {
  const truncatedDescription = truncateString(description, 130);
  return (
    <li className={styles.serviceContent}>
      <div className={styles.iconWrapper}>
        {/* this is the arrow icon */}
        <svg viewBox="0 0 22 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.9 11.4L11.6 15.8 8.4 19H12.7C14.7 16.9 19 12.3 21.4 9.9 19 7.5 14.8 2.9 12.7 0.8H8.4C9.5 1.9 10.6 3 11.6 4 13.2 5.7 15.1 7.6 15.9 8.4H0.1V11.4H15.9Z" />
        </svg>
      </div>
      <div className={styles.servicesText}>
        <h3 className={styles.linkHeading}>{headingText}</h3>
        <p>{truncatedDescription}</p>
        <SiteLink to={serviceHref} bold><span>Learn more</span></SiteLink>
      </div>
    </li>
  );
};

ServicesTextListItem.propTypes = {
  headingText: PropTypes.string.isRequired,
  description: PropTypes.string,
  serviceHref: PropTypes.string.isRequired,
};

ServicesTextListItem.defaultProps = {
  description: '',
};

export default ServicesTextListItem;
