import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './ServiceDesignHero.module.scss';
import Container from '../../components/Layouts/Container';

const ServiceDesignHeroProps = {
  tag: PropTypes.string,
  heading: PropTypes.string,
  content: PropTypes.string,
};

const ServiceDesignHero = ({ tag, heading, content }) => (
  <Container>
    <div className={styles.container}>
      <div>
        {tag}
      </div>
      <h1 className={styles.heading}>{heading}</h1>
      <div className={styles.content}>
        {content}
      </div>
    </div>
  </Container>
);

ServiceDesignHero.propTypes = ServiceDesignHeroProps;

ServiceDesignHero.defaultProps = ServiceDesignHeroProps;

export default ServiceDesignHero;
