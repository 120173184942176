/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './CaseStudiesSlice.module.scss';

import { linkResolver } from '../helpers/linkResolver';
import Container from '../components/Layouts/Container';
import Image from '../components/ui/Image/Image';
import SymmetricGrid from '../components/Layouts/Grids/SymmetricGrid/SymmetricGrid';
import SymmetricGridItem from '../components/Layouts/Grids/SymmetricGrid/SymmetricGridItem';

const CaseStudiesSlice = ({
  input: {
    items = [],
    primary: {
      case_studies_title,
    },
  },
}) => {
  if (items.length === 0) {
    return null;
  }

  const featuredCases = items
    .map((node) => node.featured_case_study || node.featured_content)
    .filter((node) => node !== null);

  return (
    <>
      <Container>
        {case_studies_title && (
          <h3 className={styles.title}>{case_studies_title?.text?.trim() || null} </h3>
        )}
        <SymmetricGrid>
          {featuredCases && featuredCases.map((caseStudy) => {
            if (!caseStudy) return null;
            const {
              uid, type, document,
            } = caseStudy;
            if (document === null) {
              return null;
            }

            return (
              <SymmetricGridItem
                itemKey={Math.random().toString()}
                gridLink={linkResolver({ type, uid })}
                linkText={document?.data?.title || ''}
              >
                <Image
                  source={document?.data?.thumbnail_image || {}}
                  alt={document?.data?.thumbnail_image?.alt || ''}
                />
              </SymmetricGridItem>
            );
          })}
        </SymmetricGrid>
      </Container>
    </>
  );
};

CaseStudiesSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.array,
  }).isRequired,
};

export default CaseStudiesSlice;
