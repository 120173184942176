// extracted by mini-css-extract-plugin
export var BlackText = "ValuesSlice-module--BlackText--s9HDL";
export var HiddenLg = "ValuesSlice-module--HiddenLg--hHJGs";
export var HiddenMd = "ValuesSlice-module--HiddenMd--EuG7k";
export var HiddenXl = "ValuesSlice-module--HiddenXl--anvZY";
export var HiddenXs = "ValuesSlice-module--HiddenXs--S1kO4";
export var TextAlignRightXs = "ValuesSlice-module--TextAlignRightXs--KCybi";
export var WhiteText = "ValuesSlice-module--WhiteText--o4kHv";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var closedSpacer = "ValuesSlice-module--closedSpacer--Jx3WU";
export var closedValue = "ValuesSlice-module--closedValue--+XLai";
export var closedValueLabel = "ValuesSlice-module--closedValueLabel--cUbUc";
export var closedValueLabelContainer = "ValuesSlice-module--closedValueLabelContainer--uBNSH";
export var container = "ValuesSlice-module--container--ChJrp";
export var desktopPanel = "ValuesSlice-module--desktopPanel--eSU09";
export var desktopWidth = "960px";
export var header = "ValuesSlice-module--header--Iace-";
export var headerImageSpacer = "ValuesSlice-module--headerImageSpacer--A4GJn";
export var headerText = "ValuesSlice-module--headerText--gUqI8";
export var hideHeaderImage = "ValuesSlice-module--hideHeaderImage--BD0Rl";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var mobileImage = "ValuesSlice-module--mobileImage--QN0Kn";
export var openSpacer = "ValuesSlice-module--openSpacer--buodD";
export var openValue = "ValuesSlice-module--openValue--hRbgJ";
export var revealValue = "ValuesSlice-module--revealValue--gqvyY";
export var selectedTab = "ValuesSlice-module--selectedTab--VVJh-";
export var showHeaderImage = "ValuesSlice-module--showHeaderImage---Ndbm";
export var srOnly = "ValuesSlice-module--sr-only--tRtcc";
export var tab = "ValuesSlice-module--tab--qFX7w";
export var tabs = "ValuesSlice-module--tabs--fO200";
export var tabsContainer = "ValuesSlice-module--tabsContainer--kDSL9";
export var valueNameOpen = "ValuesSlice-module--valueNameOpen--FL1Oc";
export var values = "ValuesSlice-module--values--dd2Ga";
export var visuallyHidden = "ValuesSlice-module--visually-hidden--2xCMk";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";