// extracted by mini-css-extract-plugin
export var BlackText = "Carousel-module--BlackText--5sAV-";
export var CarouselNavBullet = "Carousel-module--CarouselNavBullet--exZ0y";
export var CarouselNavBulletActive = "Carousel-module--CarouselNavBullet--Active--rOpBd";
export var CarouselNavBulletContainer = "Carousel-module--CarouselNavBulletContainer--cRUZx";
export var CarouselNavContainer = "Carousel-module--CarouselNavContainer--tWM5-";
export var Container = "Carousel-module--Container--nzQSS";
export var HiddenLg = "Carousel-module--HiddenLg--JBVQZ";
export var HiddenMd = "Carousel-module--HiddenMd--QLyT5";
export var HiddenXl = "Carousel-module--HiddenXl--ZjtdB";
export var HiddenXs = "Carousel-module--HiddenXs--n6leM";
export var NextButton = "Carousel-module--NextButton--LYwIk";
export var PreviousButton = "Carousel-module--PreviousButton--Fu6g0";
export var TextAlignRightXs = "Carousel-module--TextAlignRightXs--jO1Lu";
export var WhiteText = "Carousel-module--WhiteText--D1lcu";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "Carousel-module--sr-only--cB0+E";
export var visuallyHidden = "Carousel-module--visually-hidden--sEd19";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";