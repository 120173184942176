import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image/Image';

const ThumbnailImage = ({ imageData, size }) => {
  if (size === 'Full' || size === 'Twothirds') {
    return (
      <Image
        source={imageData.landscape_image || {}}
        alt={imageData.landscape_image.alt || ''}
      />
    );
  }

  return (
    <Image
      source={imageData.thumbnail_image || {}}
      alt={imageData.thumbnail_image.alt || ''}
    />
  );
};

ThumbnailImage.propTypes = {
  imageData: PropTypes.shape({
    landscape_image: PropTypes.object,
    thumbnail_image: PropTypes.object,
  }).isRequired,
  size: PropTypes.string.isRequired,
};

export default ThumbnailImage;
