import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './AccordionSlice.module.scss';
import Container from '../../components/Layouts/Container';
import Accordion from '../../components/Accordion/Accordion';

const AccordionSlice = ({ heading, items }) => (
  <Container className="containerMarginBottom">
    {heading && <h2 className={styles.heading}>{heading}</h2>}
    <Accordion items={items} />
  </Container>
);

AccordionSlice.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    text: PropTypes.element,
    linkText: PropTypes.string,
    link: PropTypes.string,
  })).isRequired,
};

AccordionSlice.defaultProps = {
  heading: null,
};

export default AccordionSlice;
