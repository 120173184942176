/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '../components/Layouts/Container';
import Video from '../components/ui/Video/Video';

const EmbededVideoSlice = ({
  input: {
    primary: {
      alignment,
      video_embeded,
    },
  },
}) => {
  if (!video_embeded) return null;

  return (
    <Container>
      <Video
        source={video_embeded.embed_url}
        className={alignment}
      />
    </Container>
  );
};

EmbededVideoSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      alignment: PropTypes.string.isRequired,
      video_embeded: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EmbededVideoSlice;
