import React from 'react';
import PropTypes from 'prop-types';

import Collection from '../components/Layouts/Collection';
import Heading from '../components/ui/Typography/Heading';
import SubHeading from '../components/ui/Typography/SubHeading';

const CollectionSlice = (props) => {
  const { input } = props;
  const { items = [], primary } = input;
  return (
    <Collection collectionList={items}>
      {primary.collection_heading && (
        <Heading>
          <h2>{primary.collection_heading.text}</h2>
        </Heading>
      )}
      {primary.collection_subtext && (
        <SubHeading>
          <h3>{primary.collection_subtext.text}</h3>
        </SubHeading>
      )}
    </Collection>
  );
};

CollectionSlice.propTypes = {
  input: PropTypes.shape({
    items: PropTypes.array.isRequired,
    primary: PropTypes.shape({
      collection_heading: PropTypes.object,
      collection_subtext: PropTypes.object,
    }).isRequired,
  }).isRequired,
};

export default CollectionSlice;
