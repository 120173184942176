/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './ListSlice.module.scss';
import Grid from '../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../components/Layouts/Grids/GeneralGrid/GeneralGridItem';

const ListSlice = ({ input: { primary: { list_heading, list_tag }, items } }) => {
  const ListItems = items.map(({
    list_items: {
      document: {
        id, data: {
          heading, description, icon,
        },
      },
    },
  }) => (
    <GridItem
      id="list"
      key={id}
      spanMd="5"
      offsetMd="1"
      spanLg="3"
      className={styles.listItem}
    >
      {icon.url && (
        <img
          className={styles.icon}
          src={icon.url}
          alt={icon.alt}
        />
      )}
      <h4 className={styles.title}>{heading.text}</h4>
      <p>{description.text}</p>
    </GridItem>
  ));

  return (
    <div className={styles.list}>
      {list_tag && (<div className={styles.tag}>{list_tag.text}</div>)}
      <h2>{list_heading.text || ''}</h2>
      <Grid className={styles.listItems}>
        {ListItems}
      </Grid>
    </div>
  );
};

ListSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.array,
  }).isRequired,
};

export default ListSlice;
