// extracted by mini-css-extract-plugin
export var BlackText = "HeaderNav-module--BlackText--O11gL";
export var HiddenLg = "HeaderNav-module--HiddenLg--3Tqzj";
export var HiddenMd = "HeaderNav-module--HiddenMd--1Ul+9";
export var HiddenXl = "HeaderNav-module--HiddenXl--j217b";
export var HiddenXs = "HeaderNav-module--HiddenXs--3rbwC";
export var TextAlignRightXs = "HeaderNav-module--TextAlignRightXs--pv1ZZ";
export var WhiteText = "HeaderNav-module--WhiteText--OMSOD";
export var bcorp = "HeaderNav-module--bcorp--MhVlE";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "HeaderNav-module--container--PfYyh";
export var desktopWidth = "960px";
export var fadeAndSlideInFromTop = "HeaderNav-module--fadeAndSlideInFromTop--SqtRL";
export var footer = "HeaderNav-module--footer--ojLRK";
export var footerMenuGroup = "HeaderNav-module--footerMenuGroup--Xqm0l";
export var hubMenu = "HeaderNav-module--hubMenu--K2DAo";
export var hubMenusContainer = "HeaderNav-module--hubMenusContainer--WFUKx";
export var impactMenu = "HeaderNav-module--impactMenu--FpfFB";
export var impactMenuContainer = "HeaderNav-module--impactMenuContainer--OF-lf";
export var innerContainer = "HeaderNav-module--innerContainer--u9n99";
export var lgBreakpoint = "60rem";
export var logoLinksGroup = "HeaderNav-module--logoLinksGroup--bofr9";
export var mainMenu = "HeaderNav-module--mainMenu--mSswy";
export var mainMenuContainer = "HeaderNav-module--mainMenuContainer--FRaSH";
export var mainMenuWrapper = "HeaderNav-module--mainMenuWrapper--XsxE2";
export var mdBreakpoint = "40rem";
export var menuBar = "HeaderNav-module--menuBar--q1P1E";
export var menuBarGroup = "HeaderNav-module--menuBarGroup--ByZBx";
export var menuButton = "HeaderNav-module--menuButton--zYRKN";
export var menuButtonWhite = "HeaderNav-module--menuButton--white--DbIIt";
export var nav = "HeaderNav-module--nav--AL1X0";
export var navActive = "HeaderNav-module--nav--active--TwgHt";
export var navBarSpacerHomePage = "HeaderNav-module--navBarSpacer--home-page--VnRdw";
export var navDivider = "HeaderNav-module--navDivider--Tzrhz";
export var navGroup = "HeaderNav-module--navGroup--74XZI";
export var navHidden = "HeaderNav-module--nav--hidden--Rmpo2";
export var navShowBackground = "HeaderNav-module--nav--show-background--7wU2w";
export var overlay = "HeaderNav-module--overlay--bYq9c";
export var portableLogo = "HeaderNav-module--portableLogo--enbFY";
export var rightSection = "HeaderNav-module--rightSection--ILOH9";
export var scrollDivider = "HeaderNav-module--scrollDivider--0191S";
export var socialLinks = "HeaderNav-module--socialLinks--PIkSm";
export var socialLinksGroup = "HeaderNav-module--socialLinksGroup--dLRog";
export var srOnly = "HeaderNav-module--sr-only--5vq0b";
export var subheading = "HeaderNav-module--subheading--hf9qE";
export var visuallyHidden = "HeaderNav-module--visually-hidden--PNzaD";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";