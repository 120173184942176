// extracted by mini-css-extract-plugin
export var BlackText = "Typography-module--BlackText--bEl+L";
export var HiddenLg = "Typography-module--HiddenLg--RNp9m";
export var HiddenMd = "Typography-module--HiddenMd--U8-ZF";
export var HiddenXl = "Typography-module--HiddenXl--hNivU";
export var HiddenXs = "Typography-module--HiddenXs--keMlp";
export var TextAlignRightXs = "Typography-module--TextAlignRightXs--umtEG";
export var WhiteText = "Typography-module--WhiteText--I3nLU";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var caps = "Typography-module--caps--94xlS";
export var center = "Typography-module--center--yh8x7";
export var desktopWidth = "960px";
export var ellipsisOverflow = "Typography-module--ellipsisOverflow--rlQ5K";
export var heading = "Typography-module--heading--Bww9-";
export var hidden = "Typography-module--hidden--iXFNZ";
export var italic = "Typography-module--italic--wJV8i";
export var justify = "Typography-module--justify--5pxKE";
export var left = "Typography-module--left--GTfiC";
export var lgBreakpoint = "60rem";
export var link = "Typography-module--link--dhYT-";
export var mdBreakpoint = "40rem";
export var right = "Typography-module--right--54Qqn";
export var smallCapsHeading = "Typography-module--smallCapsHeading--yisiv";
export var spacing = "Typography-module--spacing--cBNHH";
export var srOnly = "Typography-module--sr-only--kAvJr";
export var strong = "Typography-module--strong--sRaQt";
export var subHeading = "Typography-module--subHeading--H5jwk";
export var textContainer = "Typography-module--textContainer--6JUFE";
export var typographyBase = "Typography-module--typographyBase--SbWTN";
export var visuallyHidden = "Typography-module--visually-hidden--ltuB-";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";