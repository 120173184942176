// extracted by mini-css-extract-plugin
export var BlackText = "SiteLink-module--BlackText--O6dXx";
export var HiddenLg = "SiteLink-module--HiddenLg--NC+ef";
export var HiddenMd = "SiteLink-module--HiddenMd--VCiAP";
export var HiddenXl = "SiteLink-module--HiddenXl--zgXD9";
export var HiddenXs = "SiteLink-module--HiddenXs--pW0AX";
export var TextAlignRightXs = "SiteLink-module--TextAlignRightXs--6GSP2";
export var WhiteText = "SiteLink-module--WhiteText--9t0tA";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var bold = "SiteLink-module--bold--RgmOs";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var linkButton = "SiteLink-module--linkButton--+3e-v";
export var linkButtonBlack = "SiteLink-module--linkButton--black--qbnF+";
export var linkButtonMarginTop = "SiteLink-module--linkButtonMarginTop---VV0h";
export var linkButtonWhite = "SiteLink-module--linkButton--white--SDep+";
export var marginLeft = "SiteLink-module--marginLeft--iWyTS";
export var mdBreakpoint = "40rem";
export var noMarginLeft = "SiteLink-module--noMarginLeft--bO0X7";
export var noTextDecoration = "SiteLink-module--noTextDecoration--2Xh2X";
export var srOnly = "SiteLink-module--sr-only--4Aer3";
export var visuallyHidden = "SiteLink-module--visually-hidden--ptYHx";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";