/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Container from '../components/Layouts/Container';
import Image from '../components/ui/Image/Image';
import SymmetricGrid from '../components/Layouts/Grids/SymmetricGrid/SymmetricGrid';
import SymmetricGridItem from '../components/Layouts/Grids/SymmetricGrid/SymmetricGridItem';
import { linkResolver } from '../helpers/linkResolver';

const ReportsSlice = (props) => {
  const {
    input: {
      primary,
    },
  } = props;

  return (
    <StaticQuery
      query={graphql`
      {
        allPrismicReport(sort: {fields: data___publication_date, order: DESC}) {
          edges {
            node {
              id
              uid
              type
              data {
                introduction
                title
                seo_title
                seo_description
                page_short_description
                page_colour
                thumbnail_image {
                  localFile {
                    publicURL
                    extension
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED,
                        quality: 100,
                        width: 640,
                        breakpoints: [640, 960, 1400]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      `}
      render={(data) => {
        const allReports = data.allPrismicReport.edges.map((report) => report.node);

        const reportItems = allReports.map((report) => (
          <SymmetricGridItem
            key={report.id}
            gridLink={linkResolver({ type: report.type, uid: report.uid })}
            linkText={report.data.title}
            subtext={report.data.introduction}
            roundedBorder
          >
            <Image
              source={report.data.thumbnail_image || {}}
              alt={report.data.thumbnail_image?.alt || ''}
            />
          </SymmetricGridItem>
        ));

        return (
          <>
            <Container>
              <SymmetricGrid
                gridTitle={primary.reports_heading.text}
                gridSubTitle={primary.reports_subheading.text}
              >
                {reportItems}
              </SymmetricGrid>
            </Container>
          </>
        );
      }}
    />
  );
};

ReportsSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.object,
  }).isRequired,
};

export default ReportsSlice;
