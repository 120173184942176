/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import Code from '../components/ui/Code';
import Container from '../components/Layouts/Container';

const CodeHighlighterSlice = ({
  input: {
    primary: {
      code: {
        text,
      },
      language,
    },
  },
}) => (
  <Container>
    <Code code={text} language={language.toLowerCase()} />
  </Container>
);

CodeHighlighterSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      code: PropTypes.shape({
        html: PropTypes.string,
        raw: PropTypes.string,
        text: PropTypes.string,
      }),
      language: PropTypes.oneOf(['Javascript', 'javascript', 'Typescript', 'typescript', 'PHP', 'php']),
    }),
  }).isRequired,
};

export default CodeHighlighterSlice;
