/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import Container from '../components/Layouts/Container';
import LinkButton from '../components/Link/LinkButton';
import * as bodyStyle from '../components/ui/Body/Body.module.scss';

import getPageContextFromData from '../lib/dto/getPageContextFromData';

const JobPost = ({ data, pageContext }) => {
  const job = data.greenhouseJob;
  const bannerImage = job.metadata.find((image) => (image.name === 'Banner image'));
  const thumbnailImage = job.metadata.find((image) => (image.name === 'Thumbnail image'));
  const landscapeImage = bannerImage ? { url: bannerImage.value } : {};
  const tImage = thumbnailImage ? { url: thumbnailImage.value } : {};

  // Jobs mostly come from Greenhouse rather than Prismic
  // so the way they handle data is pretty different
  const dataContext = {
    ...getPageContextFromData(null, null, data),
    theme: 'White',
    titleText: job.title,
    body: unescape(job.content),
    pageTitle: job.title,
    pageDescription: 'Apply for this job.',
    url: pageContext.pathname,
    thumbnailImage: tImage || '',
    landscapeImage,
  };

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <Container>
          <section className={bodyStyle.singleColumn}>
            <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(job.content) }} />
          </section>
          <LinkButton
            to={`${job.absolute_url}/#application`}
            isExternal
          >
            Apply Now
          </LinkButton>
        </Container>
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
  query JobPostQuery($id: String!) {
    greenhouseJob(id: { eq: $id }) {
      id
      internal_job_id
      title
      absolute_url
      metadata {
        name
        value
      }
      content
      location {
        name
      }
    }
  }
`;

JobPost.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default JobPost;
