import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Container from './Container';
import EmptyLayout from './EmptyLayout';
import Footer from '../Footer/Footer';
import HeaderContainer from '../Header/HeaderContainer';
import NewsletterSignupForm from '../Form/NewsletterSignup/NewsletterSignupForm';
import PageData from '../../helpers/pageData';
import SEO from '../../helpers/SEO';
import Wisepops from '../../helpers/wisepops';

import '../../helpers/global.scss';

const Layout = ({ isHomePage = false, children }) => {
  const {
    pageTitle, pageDescription, url, thumbnailImage, landscapeImage,
  } = useContext(PageData);

  return (
    <EmptyLayout>
      <SEO
        title={pageTitle}
        description={pageDescription}
        pathname={url}
        thumbnailImage={thumbnailImage}
        landscapeImage={landscapeImage}
      />
      <HeaderContainer isHomePage={isHomePage} />
      <main>
        {children}
        {!['/', '/work'].includes(url) && (
          <Container>
            <NewsletterSignupForm />
          </Container>
        )}
      </main>
      <Wisepops />
      <Footer />
    </EmptyLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHomePage: PropTypes.bool,
};

Layout.defaultProps = {
  isHomePage: false,
};

export default Layout;
