import React from 'react';
import PropTypes from 'prop-types';
import * as styles from '../components/ui/Body/Body.module.scss';
import ExtLinksSection from '../components/Layouts/ExtLinksSection/ExtLinksSection';

const ExtLinksSectionSlice = ({
  input: {
    primary: {
      heading,
    },
    items,
  },
}) => (
  <div className={styles.content}>
    <ExtLinksSection
      heading={heading}
      links={items}
    />
  </div>
);

ExtLinksSectionSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.array,
  }).isRequired,
};

export default ExtLinksSectionSlice;
