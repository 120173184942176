/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './ServicesSection.module.scss';
import CaptionedImage from '../../ui/Image/CaptionedImage';
import LinkButton from '../../Link/LinkButton';
import ServicesTextListItem from './ServicesTextListItem';

const ServicesSection = ({
  heading,
  image,
  image_caption_heading,
  image_caption_description,
  services,
}) => {
  const servicesList = services.map((s) => (
    <ServicesTextListItem
      headingText={s.service.document.data.title}
      description={s.service.document.data.introduction}
      serviceHref={`/services/${s.service.document.uid}`}
      key={s.service.document.data.title}
    />
  ));
  return (
    <>
      <h2 className={styles.sectionHeading}>{heading}</h2>
      <section className={styles.blockWrapper}>
        <div className={styles.blockImage}>
          <CaptionedImage
            source={image}
            alt=""
            image_caption_heading={image_caption_heading}
            image_caption_description={image_caption_description}
          />
        </div>
        <div className={styles.blockServices}>
          <ul className={styles.servicesList}>
            {servicesList}
          </ul>
          <div>
            <LinkButton to="/services">View all services</LinkButton>
          </div>
        </div>
      </section>
    </>
  );
};

ServicesSection.propTypes = {
  heading: PropTypes.string,
  image: PropTypes.object.isRequired,
  image_caption_heading: PropTypes.string,
  image_caption_description: PropTypes.shape(
    {
      text: PropTypes.string,
    },
  ),
  services: PropTypes.array.isRequired,
};

ServicesSection.defaultProps = {
  heading: null,
  image_caption_heading: null,
  image_caption_description: null,
};

export default ServicesSection;
