// extracted by mini-css-extract-plugin
export var BlackText = "CaseStudiesSlice-module--BlackText--+qEqB";
export var HiddenLg = "CaseStudiesSlice-module--HiddenLg--SxB0L";
export var HiddenMd = "CaseStudiesSlice-module--HiddenMd--bNWh4";
export var HiddenXl = "CaseStudiesSlice-module--HiddenXl--ndKbw";
export var HiddenXs = "CaseStudiesSlice-module--HiddenXs--Aj5Fq";
export var TextAlignRightXs = "CaseStudiesSlice-module--TextAlignRightXs--Do9sI";
export var WhiteText = "CaseStudiesSlice-module--WhiteText--H-H2V";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "CaseStudiesSlice-module--sr-only--XKaFF";
export var title = "CaseStudiesSlice-module--title--Nk2Xr";
export var visuallyHidden = "CaseStudiesSlice-module--visually-hidden--5VmWu";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";