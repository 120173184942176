// extracted by mini-css-extract-plugin
export var BlackText = "QuoteCarouselSlice-module--BlackText--f98+u";
export var Figcaption = "QuoteCarouselSlice-module--Figcaption--A79Y9";
export var HiddenLg = "QuoteCarouselSlice-module--HiddenLg--MBw0K";
export var HiddenMd = "QuoteCarouselSlice-module--HiddenMd--ckepG";
export var HiddenXl = "QuoteCarouselSlice-module--HiddenXl--dgo7N";
export var HiddenXs = "QuoteCarouselSlice-module--HiddenXs--5St9j";
export var QuoteCard = "QuoteCarouselSlice-module--QuoteCard--7-Qj+";
export var QuoteCardBackgroundEveryday = "QuoteCarouselSlice-module--QuoteCardBackgroundEveryday--Ac9A6";
export var QuoteCardBackgroundParadise = "QuoteCarouselSlice-module--QuoteCardBackgroundParadise--OGQGq";
export var QuoteCardBackgroundRose = "QuoteCarouselSlice-module--QuoteCardBackgroundRose--eBF3N";
export var QuoteCardBackgroundSuzie = "QuoteCarouselSlice-module--QuoteCardBackgroundSuzie--thBEs";
export var Quotee = "QuoteCarouselSlice-module--Quotee--IDHuo";
export var TextAlignRightXs = "QuoteCarouselSlice-module--TextAlignRightXs--QyIlR";
export var WhiteText = "QuoteCarouselSlice-module--WhiteText--28rXs";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "QuoteCarouselSlice-module--sr-only--nCFgC";
export var visuallyHidden = "QuoteCarouselSlice-module--visually-hidden--xUxgU";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";