import React from 'react';
import PropTypes from 'prop-types';
import Quote from '../components/ui/Body/Quote';
import Grid from '../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../components/Layouts/Grids/GeneralGrid/GeneralGridItem';
import * as styles from '../components/ui/Body/Body.module.scss';

const QuoteSlice = ({
  input: {
    primary: {
      quote,
    },
  },
}) => (
  <div className={styles.content}>
    <Grid>
      <GridItem spanMd="7">
        <Quote quoteText={quote.text} />
      </GridItem>
    </Grid>
  </div>
);

QuoteSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      quote: PropTypes.shape({
        text: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default QuoteSlice;
