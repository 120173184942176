import React, { useEffect } from 'react';


const Wisepops = () => {
  useEffect(() => {
    const loadScript = () => {
      const wisepopLoader = document.getElementById('wisepop-loader');
      if (wisepopLoader){
        return;
      }      
    
      const w = window;
      w.wisepops = w.wisepops || function () { (w.wisepops.q = w.wisepops.q || []).push(arguments); };
      w.wisepops.l = Date.now();
    
      const s = document.createElement('script');
      const e = document.getElementsByTagName('script')[0];
      s.defer = true;
      s.id = 'wisepop-loader';
      s.src = `https://wisepops.net/loader.js?v=2&h=${process.env.GATSBY_WISEPOPS_ID}`;
      e.parentNode.insertBefore(s, e);
    };

    loadScript();
  }, []);

  return null;
};


export default Wisepops;