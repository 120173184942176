import { linkResolver } from '../../helpers/linkResolver';

const getServiceSuggestionProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;
  const title = primary?.title1?.text?.trim();

  // Filter out any malformed links before formatting the props into
  // what the slice is expecting.
  // If it doesn't have a title and a link we don't want to know.
  const services = sliceData.items.filter((item) => (
    item?.service_title?.text?.trim() && item?.service_link?.document?.uid
  )).map((item) => {
    const serviceName = item?.service_title?.text?.trim();
    const serviceLink = linkResolver({
      type: item.service_link.document.type,
      uid: item.service_link.document.uid,
    });
    return {
      title,
      serviceName,
      serviceLink,
    };
  });

  return {
    title,
    services,
  };
};

export default getServiceSuggestionProps;
