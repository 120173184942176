// extracted by mini-css-extract-plugin
export var BlackText = "RelatedSubjectMatter-module--BlackText--AIDS9";
export var HiddenLg = "RelatedSubjectMatter-module--HiddenLg--bthRO";
export var HiddenMd = "RelatedSubjectMatter-module--HiddenMd--Y+Ge5";
export var HiddenXl = "RelatedSubjectMatter-module--HiddenXl--lQo5y";
export var HiddenXs = "RelatedSubjectMatter-module--HiddenXs--CJP6B";
export var TextAlignRightXs = "RelatedSubjectMatter-module--TextAlignRightXs--h9WOe";
export var WhiteText = "RelatedSubjectMatter-module--WhiteText--b0ti9";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var blockWrapper = "RelatedSubjectMatter-module--blockWrapper--w7D8q";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var linksWrapper = "RelatedSubjectMatter-module--linksWrapper--XoIiu";
export var mdBreakpoint = "40rem";
export var sectionHeading = "RelatedSubjectMatter-module--sectionHeading--W22zE";
export var sectionLinks = "RelatedSubjectMatter-module--sectionLinks--IGhYd";
export var srOnly = "RelatedSubjectMatter-module--sr-only--NF5kh";
export var visuallyHidden = "RelatedSubjectMatter-module--visually-hidden--XrtGK";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";