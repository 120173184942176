// extracted by mini-css-extract-plugin
export var BlackText = "HeroLink-module--BlackText--mgL0T";
export var HiddenLg = "HeroLink-module--HiddenLg--laxjw";
export var HiddenMd = "HeroLink-module--HiddenMd--WwVkC";
export var HiddenXl = "HeroLink-module--HiddenXl--Ny2al";
export var HiddenXs = "HeroLink-module--HiddenXs--ZZUfU";
export var TextAlignRightXs = "HeroLink-module--TextAlignRightXs--j+Gcv";
export var WhiteText = "HeroLink-module--WhiteText--cxnBp";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var contentWrapper = "HeroLink-module--contentWrapper--fDHv7";
export var desktopWidth = "960px";
export var image = "HeroLink-module--image--vj6jN";
export var lgBreakpoint = "60rem";
export var linkWrapper = "HeroLink-module--linkWrapper--RT2gc";
export var mdBreakpoint = "40rem";
export var srOnly = "HeroLink-module--sr-only--tJDF9";
export var subtext = "HeroLink-module--subtext--AaKvk";
export var title = "HeroLink-module--title--4b5Ui";
export var visuallyHidden = "HeroLink-module--visually-hidden--t7GZG";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";