import React from 'react';
import PropTypes from 'prop-types';
import Prismic from 'prismic-reactjs';
import { PrismicRichText } from '@prismicio/react';

import * as styles from './ExtLinksSection.module.scss';
import { linkResolver } from '../../../helpers/linkResolver';

const LinkBlock = ({
  heading, description, url, date,
}) => (
  <li className={styles.extLinkBlock}>
    <h4 className="h5">
      <a className={styles.linkHeading} href={url} target="_blank" rel="noopener noreferrer">
        {heading}
        <span>
          <svg className={styles.linkArrow} viewBox="0 0 22 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9 11.4L11.6 15.8 8.4 19H12.7C14.7 16.9 19 12.3 21.4 9.9 19 7.5 14.8 2.9 12.7 0.8H8.4C9.5 1.9 10.6 3 11.6 4 13.2 5.7 15.1 7.6 15.9 8.4H0.1V11.4H15.9Z" />
          </svg>
        </span>
        {/* This span is here so that the svg arrow can have an underline. */}
        <span className={styles.hiddenText} aria-hidden="true">oo</span>
      </a>
    </h4>

    <div className={styles.linkText}>
      <PrismicRichText field={description.richText} linkResolver={linkResolver} />
    </div>

    <p className={styles.dateText}>{date ? Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(Prismic.Date(date)) : null}
    </p>
  </li>
);
LinkBlock.propTypes = {
  heading: PropTypes.string.isRequired,
  // prismic type
  // eslint-disable-next-line react/forbid-prop-types
  description: PropTypes.array.isRequired,
  url: PropTypes.string,
  date: PropTypes.string,
};

LinkBlock.defaultProps = {
  url: null,
  date: null,
};

export default LinkBlock;
