// extracted by mini-css-extract-plugin
export var BlackText = "ColumnsList-module--BlackText--MReHo";
export var HiddenLg = "ColumnsList-module--HiddenLg--3jUyf";
export var HiddenMd = "ColumnsList-module--HiddenMd--uePOM";
export var HiddenXl = "ColumnsList-module--HiddenXl--ZQHcq";
export var HiddenXs = "ColumnsList-module--HiddenXs--A+JKK";
export var TextAlignRightXs = "ColumnsList-module--TextAlignRightXs--syqQg";
export var WhiteText = "ColumnsList-module--WhiteText--Gm5py";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var icon = "ColumnsList-module--icon--2mfM7";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var pointsListItem = "ColumnsList-module--pointsListItem--3WQLq";
export var srOnly = "ColumnsList-module--sr-only--6O5vD";
export var threeColumns = "ColumnsList-module--threeColumns--KfxBg";
export var visuallyHidden = "ColumnsList-module--visually-hidden--QK5B0";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";