import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import * as styles from './SiteLink.module.scss';

function getHostName(url) {
  if (url === null) {
    return '';
  }
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
    return match[2];
  }
  return null;
}

const SiteLink = ({
  to, target, margin, bold, ariaLabel, noTextDecoration, children,
}) => {
  const className = classNames({
    [styles.marginLeft]: margin === '50px',
    [styles.bold]: bold,
    [styles.noTextDecoration]: noTextDecoration,
  });

  // no link
  if (!to) {
    return (
      <span
        className={className}
        aria-label={ariaLabel}
      >
        {children}
      </span>
    );
  }

  // anchor link
  if (to.startsWith('#') || (getHostName(to) === process.env.SITE_URL && to.includes('#'))) {
    return (
      <AnchorLink
        to={to}
        target={target}
        className={className}
        aria-label={ariaLabel}
      >
        {children}
      </AnchorLink>
    );
  }

  // no hostname and not mailto: or tel:
  if (!getHostName(to) && !/^mailto:|^tel:/i.test(to)) {
    return (
      <Link
        to={to}
        className={className}
        aria-label={ariaLabel}
      >
        {children}
      </Link>
    );
  }

  // hostname is site url
  if (getHostName(to) === process.env.SITE_URL) {
    return (
      <Link
        to={to}
        className={className}
        aria-label={ariaLabel}
      >
        {children}
      </Link>
    );
  }

  // external links
  return (
    <a
      href={to}
      target={target}
      className={className}
      aria-label={ariaLabel}
    >
      {children}
    </a>
  );
};
SiteLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  target: PropTypes.oneOf(['_blank', '_self', '_parent']),
  margin: PropTypes.string,
  bold: PropTypes.bool,
  ariaLabel: PropTypes.string,
  noTextDecoration: PropTypes.bool,
};
SiteLink.defaultProps = {
  target: null,
  margin: '',
  bold: false,
  ariaLabel: undefined,
  noTextDecoration: false,
};

export default SiteLink;
