import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useMediaSize from '../../helpers/useMediaSize';
import Image from '../ui/Image/Image';
import * as styles from './HomeHeroBanner.module.scss';

const BannerTextLink = ({ to, hub, children }) => {
  const [active, setActive] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isDesktop = useMediaSize('desktop');

  useEffect(() => {
    if (!hub) { return () => {}; }

    // change active hub image every 4 seconds
    const intervalId = active ? setInterval(() => {
      setCurrentImageIndex((imageIndex) => (imageIndex < (hub.nodes.length - 1)
        ? imageIndex + 1
        : 0));
    }, 2000) : null;

    return () => {
      clearInterval(intervalId);
    };
  }, [active]);

  const handleMouseOver = () => {
    setActive(true);
  };

  const handleMouseOut = () => {
    setActive(false);
    if (hub) { setCurrentImageIndex(0); }
  };

  return (
    <>
      {isDesktop && hub && (
        <div className={cx(styles.images, { [styles.imagesActive]: active })} aria-hidden>
          {hub.nodes.map((node, index) => (
            <div
              key={node.id}
              className={cx(styles.image, { [styles.imageActive]: currentImageIndex === index })}
            >
              <Image
                alt=""
                source={node}
                style={{
                  width: '100vw',
                  height: '100vh',
                }}
                isFixedSize
              />
            </div>
          ))}
        </div>
      )}

      <span>
        <Link
          className={cx(styles.link, { [styles.linkActive]: active })}
          to={to}
          onMouseEnter={isDesktop ? handleMouseOver : null}
          onMouseLeave={isDesktop ? handleMouseOut : null}
        >
          {children}
        </Link>
      </span>
    </>
  );
};

BannerTextLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  hub: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape({
      childImageShape: PropTypes.shape({
        fixed: PropTypes.shape({
          src: PropTypes.string,
        }),
      }),
    })),
  }),
};

BannerTextLink.defaultProps = {
  hub: null,
};

export default BannerTextLink;
