/* eslint-disable max-len */

const getAlternatingContentMediaSliceProps = (sliceData) => {
  if (sliceData === null) return null;

  const { primary, items = [] } = sliceData;
  if (!primary) return null;

  const heading = primary.heading?.text?.trim();
  const headingType = primary.heading?.richText?.[0]?.type || null;
  const content = primary.content?.text?.trim();
  const tag = primary.tag1?.text?.trim();
  const { counter, image } = primary;

  // filter out any content items that don't have any content in them
  // then format the data for each content item
  const listItems = items.length === 0
    ? null
    : items.map((item) => {
      const listHeading = item.list_heading?.text?.trim() || null;
      const listDescription = item.list_description?.text?.trim() || null;

      return {
        listHeading,
        listDescription,
      };
    });

  return {
    counter,
    tag,
    heading,
    headingType,
    content,
    image,
    listItems,
  };
};

export default getAlternatingContentMediaSliceProps;
