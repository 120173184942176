// extracted by mini-css-extract-plugin
export var BlackText = "TextArea-module--BlackText--Xu8ir";
export var HiddenLg = "TextArea-module--HiddenLg--td0AT";
export var HiddenMd = "TextArea-module--HiddenMd--mQaXt";
export var HiddenXl = "TextArea-module--HiddenXl--g-5FJ";
export var HiddenXs = "TextArea-module--HiddenXs--s3vMu";
export var TextAlignRightXs = "TextArea-module--TextAlignRightXs--h2Z5F";
export var WhiteText = "TextArea-module--WhiteText--xC+nf";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var errorMessage = "TextArea-module--errorMessage--xGgQN";
export var input = "TextArea-module--input--G9ciu";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "TextArea-module--sr-only--NLt6g";
export var successMessage = "TextArea-module--successMessage--3A3rd";
export var visuallyHidden = "TextArea-module--visually-hidden--7oZi4";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";