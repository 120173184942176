import React from 'react';

import * as styles from './Footer.module.scss';

import bcorpSVG from '../../images/bcorp-logo-white.svg';

const BCorpIconLink = () => (
  <div>
    <a href="https://www.bcorporation.net/en-us/find-a-b-corp/company/portable" target="_blank" rel="noreferrer">
      <img
        className={styles.bcorp}
        src={bcorpSVG}
        alt="Portable is a Certified B Corporation"
      />
    </a>
  </div>
);

export default BCorpIconLink;
