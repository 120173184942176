import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import cx from 'classnames';

import * as styles from './Video.module.scss';

const Video = ({
  className, source,
}) => (
  <div className={cx(styles.video, className)}>
    <ReactPlayer
      className={styles.reactPlayer}
      url={source}
      width="100%"
      height="100%"
    />
  </div>
);

Video.propTypes = {
  source: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Video.defaultProps = {
  className: undefined,
};

export default Video;
