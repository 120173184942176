import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from './SymmetricGrid.module.scss';

const SymmetricGrid = ({
  className, gridTitle, gridSubTitle, children,
}) => (
  <section className={styles.gridContainer}>
    {gridTitle && <h2 className="h3">{gridTitle}</h2>}

    {gridSubTitle && <h3>{gridSubTitle}</h3>}

    <div className={cx(styles.grid, className)}>
      {children}
    </div>
  </section>
);

SymmetricGrid.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  gridTitle: PropTypes.string,
  gridSubTitle: PropTypes.string,
};

SymmetricGrid.defaultProps = {
  className: null,
  children: null,
  gridTitle: '',
  gridSubTitle: '',
};

export default SymmetricGrid;
