import React from 'react';
import t from 'prop-types';

import ArrowDownIcon from '../../images/arrow-down.svg';
import * as styles from './HomeHeroBanner.module.scss';

const ScrollLink = ({ onClick }) => (
  <button
    className={styles.scrollLink}
    onClick={onClick}
    type="button"
    aria-hidden
  >
    Scroll
    <img src={ArrowDownIcon} alt="" aria-hidden />
  </button>
);

ScrollLink.propTypes = {
  onClick: t.func.isRequired,
};

export default ScrollLink;
