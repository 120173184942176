import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import getPageContextFromData from '../lib/dto/getPageContextFromData';

import NotFoundPage from '../components/404';
import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import Body from '../components/ui/Body/Body';

const BlogPost = ({
  data: { prismicBlog: { type, uid, data } },
}) => {
  if (!data) return <NotFoundPage />;

  const dataContext = getPageContextFromData(type, uid, data);

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <Body />
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
query BlogPostBySlug($uid: StringQueryOperatorInput = {}) {
  prismicBlog(uid: $uid) {
    _previewable
    uid
    type
    data {
      introduction
      page_colour
      page_short_description
      prismic_page_name
      publish_date
      seo_description
      seo_title
      title
      thumbnail_image {
        localFile {
          publicURL
          extension
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED,
              quality: 100,
              width: 640,
              breakpoints: [640, 960, 1400]
            )
          }
        }
      }
      landscape_image {
        localFile {
          publicURL
          extension
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED,
              quality: 100,
              width: 1400,
              breakpoints: [640, 960, 1400]
            )
          }
        }
      }
      body {
        ... on PrismicBlogDataBodyContent {
          id
          slice_type
          primary {
            content_heading {
              html
              text
            }
            content_subtitle {
              richText
              html
              text
            }
            content_text {
              richText
              text
            }
            layout
          }
        }
        ... on PrismicBlogDataBodyImage {
          id
          slice_type
          primary {
            alignment
            image {
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    quality: 100,
                    width: 1400,
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
          }
        }
        ... on PrismicBlogDataBodyVideo {
          id
          slice_type
          primary {
            alignment
            video_embeded {
              embed_url
            }
          }
        }
        ... on PrismicBlogDataBodyQuote {
          id
          slice_type
          primary {
            quote {
              html
              text
            }
          }
        }
        ... on PrismicBlogDataBodyBanner {
          id
          slice_type
          primary {
            background_colour
            text_colour
            big_text {
              richText
              text
            }
            text_link {
              richText
              text
            }
          }
        }
        ... on PrismicBlogDataBodyRelatedContent {
          id
          slice_type
          primary {
            items_to_show
            related_content_title {
              text
            }
          }
        }
        ... on PrismicBlogDataBodyDownloadFormHs {
          id
          slice_type
          primary {
            form_title
            hubspot_form_id
            form_image {
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    quality: 100,
                    width: 1400,
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
          }
        }
        ... on PrismicBlogDataBodyCodeHighlighter {
          id
          slice_type
          primary {
            code {
              text
            }
            language
          }
        }
        ... on PrismicBlogDataBodyHsEmbeddedForm {
          id
          slice_type
          primary {
            form_title
            hubspot_form_id
          }
        }
        ... on PrismicBlogDataBodyCodeAndContent {
          id
          slice_type
          primary {
            layout
            content_heading {
              html
              text
            }
            content_subtitle {
              richText
              html
              text
            }
          }
          items {
            content_format
            content_or_code_section {
              html
              text
            }
          }
        }
        ... on PrismicBlogDataBodyActionButton {
          id
          slice_type
          primary {
            layout
            button_text {
              text
            }
            button_url {
              id
              link_type
              isBroken
              type
              uid
              url
              target
            }
          }
        }
      }
      process_tags {
        processes {
          slug
          type
        }
      }
      purpose_tags {
        purposes {
          type
          slug
        }
      }
      service_tags {
        services {
          type
          slug
        }
      }
    }
  }
}
`;

BlogPost.propTypes = {
  data: PropTypes.shape({
    prismicBlog: PropTypes.object.isRequired,
  }).isRequired,
};

export default withPrismicPreview(BlogPost);
