import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NotFoundPage from './404';
import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import Body from '../components/ui/Body/Body';
import Container from '../components/Layouts/Container';
import NewsletterSignupForm from '../components/Form/NewsletterSignup/NewsletterSignupForm';
import getPageContextFromData from '../lib/dto/getPageContextFromData';

const WorkPage = ({
  location, // location is required for the work page filter to access search query params
  data: {
    prismicWorkPage: { type, uid, data },
  },
}) => {
  if (!data) return <NotFoundPage />;

  const dataContext = getPageContextFromData(type, uid, data);

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <Body />
        <Container>
          <NewsletterSignupForm />
        </Container>
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
  {
    prismicWorkPage {
      _previewable
      uid
      type
      id
      data {
        page_colour
        page_short_description
        prismic_page_name
        seo_description
        seo_title
        title
        long_banner_heading {
          text
        }
        body {
          ... on PrismicWorkPageDataBodyCaseStudies {
            id
            slice_type
            __typename
            primary {
              items_to_show
              case_studies_title {
                text
              }
            }
            items {
              featured_case_study {
                document {
                  ... on PrismicCaseStudy {
                    id
                    uid
                    type
                    data {
                      title
                      thumbnail_image {
                        localFile {
                          publicURL
                          extension
                          childImageSharp {
                            gatsbyImageData(
                              layout: CONSTRAINED,
                              quality: 100,
                              width: 640,
                              breakpoints: [640, 960, 1400]
                            )
                          }
                        }
                      }
                      landscape_image {
                        localFile {
                          publicURL
                          extension
                          childImageSharp {
                            gatsbyImageData(
                              layout: CONSTRAINED,
                              quality: 100,
                              width: 1400,
                              breakpoints: [640, 960, 1400]
                            )
                          }
                        }
                      }
                      purpose_tags {
                        purposes {
                          type
                          document {
                            ... on PrismicPurposeCategory {
                              id
                              data {
                                purpose
                              }
                            }
                          }
                        }
                      }
                      service_tags {
                        services {
                          type
                          document {
                            ... on PrismicServiceCategory {
                              id
                              data {
                                service
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

WorkPage.propTypes = {
  data: PropTypes.shape({
    prismicWorkPage: PropTypes.shape({}).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

export default withPrismicPreview(WorkPage);
