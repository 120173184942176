import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NotFoundPage from './404';
import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import ContactInfo from '../components/ContactInfo/ContactInfo';
import ContactForm from '../components/Form/ContactForm/ContactForm';
import getPageContextFromData from '../lib/dto/getPageContextFromData';

const ContactPage = ({
  data: {
    prismicContactPage: { type, uid, data },
  },
}) => {
  if (!data) return <NotFoundPage />;

  const dataContext = getPageContextFromData(type, uid, data);

  const contactInfo = {
    contactItems: data.body,
    phone: data.phone,
    email: data.email,
  };

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <ContactForm />
        <ContactInfo contactInfo={contactInfo} />
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
  {
    prismicContactPage {
      _previewable
      uid
      type
      id
      data {
        introduction
        page_colour
        page_short_description
        prismic_page_name
        seo_description
        seo_title
        title
        phone
        email
        body {
          ... on PrismicContactPageDataBodyList {
            id
            slice_type
            primary {
              list_heading {
                text
              }
            }
            items {
              list_items {
                document {
                  ... on PrismicListItem {
                    id
                    data {
                      heading {
                        text
                      }
                      description {
                        text
                      }
                      icon {
                        alt
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicContactPageDataBodyContactInfo {
            id
            items {
              address_column {
                text
                richText
              }
            }
          }
        }
      }
    }
  }
`;

ContactPage.propTypes = {
  data: PropTypes.shape({
    prismicContactPage: PropTypes.object.isRequired,
  }).isRequired,
};

export default withPrismicPreview(ContactPage);
