const getHubspotEmbeddedFormProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;
  const heading = primary.form_title?.trim() || null;
  const description = primary.description || null;
  const hubspotFormId = primary.hubspot_form_id?.trim() || null;

  return {
    heading,
    description,
    hubspotFormId,
  };
};
export default getHubspotEmbeddedFormProps;
