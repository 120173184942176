// extracted by mini-css-extract-plugin
export var BlackText = "ServiceHubHeroSlice-module--BlackText--KEgbg";
export var HiddenLg = "ServiceHubHeroSlice-module--HiddenLg--+j9hQ";
export var HiddenMd = "ServiceHubHeroSlice-module--HiddenMd--tpAz3";
export var HiddenXl = "ServiceHubHeroSlice-module--HiddenXl--QGSb7";
export var HiddenXs = "ServiceHubHeroSlice-module--HiddenXs--Qg-tD";
export var TextAlignRightXs = "ServiceHubHeroSlice-module--TextAlignRightXs--rIMZU";
export var WhiteText = "ServiceHubHeroSlice-module--WhiteText--iXBOa";
export var active = "ServiceHubHeroSlice-module--active--Dw3q1";
export var bannerText = "ServiceHubHeroSlice-module--bannerText--YspZt";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "ServiceHubHeroSlice-module--container--l+3ne";
export var desktopWidth = "960px";
export var icon = "ServiceHubHeroSlice-module--icon--BqBPS";
export var lgBreakpoint = "60rem";
export var link = "ServiceHubHeroSlice-module--link--eOSmj";
export var mdBreakpoint = "40rem";
export var span = "ServiceHubHeroSlice-module--span--FJcvq";
export var srOnly = "ServiceHubHeroSlice-module--sr-only--ZVHtm";
export var visuallyHidden = "ServiceHubHeroSlice-module--visually-hidden--AqceR";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";