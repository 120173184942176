// extracted by mini-css-extract-plugin
export var BlackText = "ExtLinksSection-module--BlackText--3T0Te";
export var HiddenLg = "ExtLinksSection-module--HiddenLg--ulNZF";
export var HiddenMd = "ExtLinksSection-module--HiddenMd--t2HBJ";
export var HiddenXl = "ExtLinksSection-module--HiddenXl--ad088";
export var HiddenXs = "ExtLinksSection-module--HiddenXs--2bJWj";
export var TextAlignRightXs = "ExtLinksSection-module--TextAlignRightXs--YP5jV";
export var WhiteText = "ExtLinksSection-module--WhiteText--Vi-ci";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var blockWrapper = "ExtLinksSection-module--blockWrapper--3YLi1";
export var dateText = "ExtLinksSection-module--dateText--W-FOn";
export var desktopWidth = "960px";
export var extLinkBlock = "ExtLinksSection-module--extLinkBlock--O07S0";
export var hiddenText = "ExtLinksSection-module--hiddenText--XEq4x";
export var lgBreakpoint = "60rem";
export var linkArrow = "ExtLinksSection-module--linkArrow--t70Yt";
export var linkHeading = "ExtLinksSection-module--linkHeading--Y1B7i";
export var linkText = "ExtLinksSection-module--linkText--Yz3cN";
export var linksWrapper = "ExtLinksSection-module--linksWrapper--VTAsc";
export var mdBreakpoint = "40rem";
export var sectionHeading = "ExtLinksSection-module--sectionHeading--6tGjD";
export var sectionLinks = "ExtLinksSection-module--sectionLinks--S60+k";
export var srOnly = "ExtLinksSection-module--sr-only--huAZk";
export var visuallyHidden = "ExtLinksSection-module--visually-hidden--PF-00";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";