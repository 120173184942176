// extracted by mini-css-extract-plugin
export var BlackText = "Container-module--BlackText--Atfwy";
export var HiddenLg = "Container-module--HiddenLg--2vNZI";
export var HiddenMd = "Container-module--HiddenMd--vJfaj";
export var HiddenXl = "Container-module--HiddenXl--TONWa";
export var HiddenXs = "Container-module--HiddenXs--ezxjO";
export var TextAlignRightXs = "Container-module--TextAlignRightXs--CLrmx";
export var WhiteText = "Container-module--WhiteText--3p5bD";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "Container-module--container--1E6ev";
export var containerMarginBottom = "Container-module--containerMarginBottom--879UO";
export var containerShallow = "Container-module--container--shallow--zIT0h";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "Container-module--sr-only--54GWw";
export var visuallyHidden = "Container-module--visually-hidden--9hu4O";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";