import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from './TextField.module.scss';

const TextField = ({
  id,
  label,
  className,
  type,
  name,
  value,
  onChange,
  required,
  disabled,
}) => (
  <>
    <label htmlFor={id}>{label}</label>
    <input
      id={id}
      className={cx(styles.input, className)}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
    />
  </>
);

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.node,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextField.defaultProps = {
  required: false,
  disabled: false,
  name: null,
  onChange: null,
  className: null,
};

export default TextField;
