import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Typography.module.scss';

const Heading = ({ children }) => (
  <div className={styles.heading}>{ children }</div>
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Heading;
