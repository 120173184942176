import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './DownloadForm.module.scss';
import * as bodyStyles from '../../ui/Body/Body.module.scss';
import Grid from '../../Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../../Layouts/Grids/GeneralGrid/GeneralGridItem';
import HubspotEmbeddedForm from '../HubspotEmbeddedForm/HubspotEmbeddedForm';
import Image from '../../ui/Image/Image';

const DownloadFormHubspot = ({
  formID,
  downloadImage,
  downloadImageAlt,
  downloadTitle,
}) => (
  <div className={bodyStyles.content}>
    <h3 className={styles.downloadTitle}>
      {downloadTitle}
    </h3>
    <Grid>
      <GridItem spanMd="6" spanLg="6" spanXLg="5">
        <Image
          className={styles.reportImage}
          source={downloadImage}
          alt={downloadImageAlt}
          roundedBorder
        />
      </GridItem>
      <GridItem span="12" spanMd="6" spanLg="6" spanXLg="7">
        <HubspotEmbeddedForm hubspotFormId={formID} />
      </GridItem>
    </Grid>
  </div>
);

DownloadFormHubspot.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  downloadImage: PropTypes.object,
  downloadImageAlt: PropTypes.string,
  downloadTitle: PropTypes.string.isRequired,
  formID: PropTypes.string.isRequired,
};
DownloadFormHubspot.defaultProps = {
  downloadImage: '',
  downloadImageAlt: '',
};

export default DownloadFormHubspot;
