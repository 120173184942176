import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import Button from '../../Button/Button';
import Grid from '../../Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../../Layouts/Grids/GeneralGrid/GeneralGridItem';
import TextArea from '../TextArea';
import TextField from '../TextField';
import * as styles from './ContactForm.module.scss';
import * as bodyStyles from '../../ui/Body/Body.module.scss';
import { getHubspotCookie } from '../../../helpers/helpers';

const ContactForm = () => {
  const titleRef = useRef();
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    organisation: '',
    message: '',
  });
  const [formState, setFormState] = useState({
    hasError: false,
    canSubmit: false,
    submitting: false,
    submitted: false,
    showCaptcha: false,
    formMessage: null,
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTextareaChange = (event) => {
    if (!formState.showCaptcha) {
      setFormState(prev => ({
        ...prev,
        showCaptcha: true, // show captcha once they start entering in their message
      }));
    }

    handleInputChange(event);
  };

  const handleCaptchaSuccess = () => {
    setFormState(prev => ({
      ...prev,
      canSubmit: true,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formState.canSubmit) {
      setFormState(prev => ({
        ...prev,
        formMessage: 'Please complete the captcha.',
      }));
      return;
    }
    const hubspotutk = getHubspotCookie();

    const postData = {
      fields: [
        {
          name: 'firstname',
          value: formData.firstname,
        },
        {
          name: 'lastname',
          value: formData.lastname,
        },
        {
          name: 'email',
          value: formData.email,
        },
        {
          name: 'company',
          value: formData.organisation,
        },
        {
          name: 'message',
          value: formData.message,
        },
      ],
      context: {
        hutk: hubspotutk,
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    fetch(event.target.action, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    })
      .then(async (response) => {
        if (response.status >= 400) throw Error(await response.text());

        setFormState(prev => ({
          ...prev,
          submitting: false,
          submitted: true,
          formMessage: null,
        }));

        setFormData({
          firstname: '',
          lastname: '',
          email: '',
          organisation: '',
          message: '',
        });

        window.scrollTo({ top: titleRef.current.offsetTop });

        return response.text();
      })
      .catch((error) => {
        setFormState(prev => ({
          ...prev,
          hasError: true,
          submitting: false,
        }));
        console.error(error);
      });
  };

  return (
    <div className={bodyStyles.content}>
        <div style={{position: 'relative', top: '-2em'}}>
        We run efficient, facilitated workshops too, so if you have a challenge that would benefit from a bit of speed and support right now, <a href="https://share.hsforms.com/1IeENdRgmSNuWpCrBk4hqZQ4o6yx">register your interest</a> and we'll schedule one for your team.
        </div>
      <h2 ref={titleRef}>Get in touch</h2>

      <form
        id="contactForm"
        onSubmit={handleSubmit}
        action={`https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_ACCOUNT_ID}/${process.env.GATSBY_HUBSPOT_CONTACT_FORM_ID}`}
        className={styles.form}
      >
        {!formState.submitted && (
          <>
            <Grid>
              <GridItem spanLg="5" offsetLg="1">
                <TextField
                  id="contactFormFirstName"
                  name="firstname"
                  label="Your first name*"
                  type="text"
                  value={formData.firstname}
                  onChange={handleInputChange}
                  required
                  disabled={formState.submitting}
                />
                <TextField
                  id="contactFormLastName"
                  name="lastname"
                  label="Your last name*"
                  type="text"
                  value={formData.lastname}
                  onChange={handleInputChange}
                  required
                  disabled={formState.submitting}
                />
                <TextField
                  id="contactFormEmail"
                  name="email"
                  label="Your email*"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  disabled={formState.submitting}
                />
                <TextField
                  id="contactFormOrganisation"
                  name="organisation"
                  label="Your organisation"
                  type="text"
                  value={formData.organisation}
                  onChange={handleInputChange}
                  disabled={formState.submitting}
                />
              </GridItem>
              <GridItem spanLg="6">
                <TextArea
                  id="contactFormMessage"
                  name="message"
                  label="Write a message*"
                  value={formData.message}
                  onChange={handleTextareaChange}
                  required
                  disabled={formState.submitting}
                />
              </GridItem>
            </Grid>

            {formState.formMessage && (
              <p className={styles.errorMessage}>{formState.formMessage}</p>
            )}

            {formState.hasError && (
              <p className={styles.errorMessage}>
                There seems to be a problem. Please check that you have filled
                out the form correctly and if the problem persists email{' '}
                <a href="mailto:hello@portable.com.au">hello@portable.com.au</a>{' '}
                directly.
              </p>
            )}
            {formState.showCaptcha && (
              <div className={styles.captcha}>
                <ReCAPTCHA
                  sitekey={
                    window.Cypress
                      ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
                      : '6LdihJQaAAAAAGQIUxJJD7VmAjZyn7BecqhS8lOQ'
                  }
                  onChange={handleCaptchaSuccess}
                />
              </div>
            )}
            <Button
              type="submit"
              className={styles.submitButton}
              disabled={formState.submitting}
            >
              Submit enquiry
            </Button>
          </>
        )}

        {formState.submitted && (
          <div className={styles.successMessageContainer}>
            <div>
              <h3>Thank you.</h3>
              <p>We will get back to you as soon as possible.</p>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
