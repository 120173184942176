// extracted by mini-css-extract-plugin
export var BlackText = "Stack-module--BlackText--Ro-Xs";
export var HiddenLg = "Stack-module--HiddenLg--6C6uf";
export var HiddenMd = "Stack-module--HiddenMd--AAaOi";
export var HiddenXl = "Stack-module--HiddenXl--WRgVq";
export var HiddenXs = "Stack-module--HiddenXs--OwXHQ";
export var TextAlignRightXs = "Stack-module--TextAlignRightXs--EHpSG";
export var WhiteText = "Stack-module--WhiteText--aZ44C";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var spaceY1 = "Stack-module--spaceY1--XlbQB";
export var spaceY10 = "Stack-module--spaceY10---OUWf";
export var spaceY12 = "Stack-module--spaceY12--+9RuS";
export var spaceY14 = "Stack-module--spaceY14--mICjW";
export var spaceY16 = "Stack-module--spaceY16--Zay8v";
export var spaceY2 = "Stack-module--spaceY2--bYIRH";
export var spaceY3 = "Stack-module--spaceY3--Wm8Rv";
export var spaceY4 = "Stack-module--spaceY4--x9Blj";
export var spaceY5 = "Stack-module--spaceY5--0Ze6+";
export var spaceY6 = "Stack-module--spaceY6--QWK8n";
export var spaceY7 = "Stack-module--spaceY7--Jwajk";
export var spaceY8 = "Stack-module--spaceY8--QB4xT";
export var spaceY9 = "Stack-module--spaceY9--k0ywJ";
export var srOnly = "Stack-module--sr-only--UA5P+";
export var visuallyHidden = "Stack-module--visually-hidden--2T5Ts";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";