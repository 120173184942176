import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as styles from './Button.module.scss';

const Button = ({
  type,
  className,
  children,
  disabled,
}) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={cx(styles.Button, className)}
    disabled={disabled}
  >
    {children}
  </button>
);

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.node,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  className: null,
  disabled: false,
};

export default Button;
