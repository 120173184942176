// extracted by mini-css-extract-plugin
export var BlackText = "HomePageCaseStudiesSlice-module--BlackText--82c1E";
export var Full = "HomePageCaseStudiesSlice-module--Full--Kl9lR";
export var HiddenLg = "HomePageCaseStudiesSlice-module--HiddenLg--xUWvQ";
export var HiddenMd = "HomePageCaseStudiesSlice-module--HiddenMd--i5qqa";
export var HiddenXl = "HomePageCaseStudiesSlice-module--HiddenXl--apvAh";
export var HiddenXs = "HomePageCaseStudiesSlice-module--HiddenXs--Re3iI";
export var TextAlignRightXs = "HomePageCaseStudiesSlice-module--TextAlignRightXs--lreH7";
export var WhiteText = "HomePageCaseStudiesSlice-module--WhiteText--LrbKN";
export var aspectRatioBoxInside = "HomePageCaseStudiesSlice-module--aspectRatioBoxInside--HPWkj";
export var aspectRatioBoxOne = "HomePageCaseStudiesSlice-module--aspectRatioBoxOne--ynA0d";
export var aspectRatioBoxThree = "HomePageCaseStudiesSlice-module--aspectRatioBoxThree--q4bcu";
export var aspectRatioBoxTwo = "HomePageCaseStudiesSlice-module--aspectRatioBoxTwo--9RiIu";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var date = "HomePageCaseStudiesSlice-module--date--jf+BQ";
export var desktopWidth = "960px";
export var gridItem = "HomePageCaseStudiesSlice-module--gridItem--Ixd8u";
export var gridItemLink = "HomePageCaseStudiesSlice-module--gridItemLink--WPaGj";
export var hideAtMdBreakpoint = "HomePageCaseStudiesSlice-module--hideAtMdBreakpoint--x5YJU";
export var hideAtSmallBreakpoint = "HomePageCaseStudiesSlice-module--hideAtSmallBreakpoint--y23et";
export var icon = "HomePageCaseStudiesSlice-module--icon--kZkuX";
export var lgBreakpoint = "60rem";
export var link = "HomePageCaseStudiesSlice-module--link--ydRon";
export var mdBreakpoint = "40rem";
export var offsetVertically = "HomePageCaseStudiesSlice-module--offsetVertically--YS8Re";
export var reduceTitleWidth = "HomePageCaseStudiesSlice-module--reduceTitleWidth--CH-jQ";
export var sectionFooter = "HomePageCaseStudiesSlice-module--sectionFooter--7JZYC";
export var sectionHeading = "HomePageCaseStudiesSlice-module--sectionHeading--c5ReS";
export var sectionHeading__text = "HomePageCaseStudiesSlice-module--sectionHeading__text--iW0C3";
export var srOnly = "HomePageCaseStudiesSlice-module--sr-only--5hMZl";
export var subtext = "HomePageCaseStudiesSlice-module--subtext--QqOvd";
export var subtextLink = "HomePageCaseStudiesSlice-module--subtextLink--wNljE";
export var tags = "HomePageCaseStudiesSlice-module--tags--TUWtC";
export var title = "HomePageCaseStudiesSlice-module--title--Bi3Dh";
export var visuallyHidden = "HomePageCaseStudiesSlice-module--visually-hidden--0Wgp4";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";