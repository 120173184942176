import React from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

import Grid from '../Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../Layouts/Grids/GeneralGrid/GeneralGridItem';
import { linkResolver } from '../../helpers/linkResolver';
import * as styles from './ContactInfo.module.scss';

const ContactInfo = ({ contactInfo }) => (
  <div className={styles.contactInfo}>
    {contactInfo.contactItems.map((contactRow) => (
      <Grid>
        <GridItem spanLg="6" className={styles.block}>
          {contactRow.items.map((contact) => (
            <div className={styles.richtext}>
              <PrismicRichText
                field={contact?.address_column.richText}
                linkResolver={linkResolver}
              />
            </div>
          ))}
        </GridItem>
        <GridItem spanLg="6">
          <h6>Contact</h6>
          <div className={styles.block}>
            <h5>Phone</h5>
            <a
              href={`tel:${contactInfo.phone.replace(' ', '')}`}
              target="_blank"
              rel="noreferrer"
            >
              {contactInfo.phone}
            </a>
          </div>
          <div>
            <h4>Email</h4>
            <a
              href={`mailto:${contactInfo.email}`}
              target="_blank"
              rel="noreferrer"
            >
              {contactInfo.email}
            </a>
          </div>
        </GridItem>
      </Grid>
    ))}
  </div>
);

ContactInfo.propTypes = {
  contactInfo: PropTypes.shape({
    contactItems: PropTypes.arrayOf(
      PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.shape({
            address_column: PropTypes.shape({
              text: PropTypes.string,
              richText: PropTypes.arrayOf(PropTypes.object),
            }),
          }),
        ),
      }),
    ),
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default ContactInfo;
