/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import NukaCarousel from 'nuka-carousel';
import PropTypes from 'prop-types';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import * as styles from './Carousel.module.scss';

const measureSpacing = () => {
  const carouselMeassuringMachine = document.querySelector('.carousel-measuring-machine');
  if (carouselMeassuringMachine) {
    const compStyles = window.getComputedStyle(carouselMeassuringMachine);
    const marginLeft = compStyles.getPropertyValue('margin-left');
    const paddingLeft = compStyles.getPropertyValue('padding-left');
    return parseInt(marginLeft, 10) + parseInt(paddingLeft, 10);
  }
  return 0;
};

const Carousel = ({ children }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [spacing, setSpacing] = useState({
    width: 0,
    margin: 0,
  });

  useEffect(() => {
    setSpacing({ width: window.innerWidth, margin: measureSpacing() });

    const debouncedResize = debounce(() => {
      setSpacing({ width: window.innerWidth, margin: measureSpacing() });
    }, 10);

    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, []);

  return (
    <div className={styles.Container}>
      <div>
        <NukaCarousel
          //
          enableKeyboardControls
          //
          easing="easeCircleInOut"
          edgeEasing="easeCircleOut"
          //
          scrollMode="page"
          cellSpacing={40}
          cellAlign="left"
          framePadding={`0 0 0 ${spacing.margin}px`}
          slideWidth={
            spacing.width < 800 ? `${spacing.width / 1.25}px` : `${spacing.width / 2.125}px`
          }
          //
          heightMode="max"
          autoGenerateStyleTag={false}
          frameOverflow="visible"
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
          renderBottomCenterControls={() => null}
          //
          slideIndex={slideIndex}
          afterSlide={(slide) => setSlideIndex(slide)}
        >
          {children}
        </NukaCarousel>
      </div>

      <div className={styles.CarouselNavBulletContainer}>
        {React.Children.map(children, (_element, index) => (
          <button
            className={cx(
              styles.CarouselNavBullet,
              slideIndex === index && styles['CarouselNavBullet--Active'],
            )}
            type="button"
            key={`Bullet${index + 1}`}
            onClick={() => setSlideIndex(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>

      <div className={styles.CarouselNavContainer}>
        <button
          type="button"
          className={styles.PreviousButton}
          onClick={(e) => {
            e.currentTarget.focus();
            setSlideIndex(slideIndex - 1);
          }}
          disabled={slideIndex === 0}
        >
          ← Previous
        </button>

        <button
          type="button"
          className={styles.NextButton}
          onClick={(e) => {
            e.currentTarget.focus();
            setSlideIndex(slideIndex + 1);
          }}
          disabled={slideIndex === children.length - 1}
        >
          Next →
        </button>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Carousel;
