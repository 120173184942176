/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { linkResolver } from '../helpers/linkResolver';
import Container from '../components/Layouts/Container';
import Image from '../components/ui/Image/Image';
import SymmetricGrid from '../components/Layouts/Grids/SymmetricGrid/SymmetricGrid';
import SymmetricGridItem from '../components/Layouts/Grids/SymmetricGrid/SymmetricGridItem';

const BlogsSlice = ({ input: { primary } }) => (
  <StaticQuery
    query={graphql`{
      allPrismicBlog(sort: {fields: data___publish_date, order: DESC}) {
        edges {
          node {
            id
            uid
            type
            data {
              title
              introduction
              page_colour
              page_short_description
              publish_date(formatString: "DD MMMM YYYY")
              seo_description
              seo_title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              process_tags {
                processes {
                  slug
                  type
                }
              }
              purpose_tags {
                purposes {
                  type
                  slug
                }
              }
              service_tags {
                services {
                  type
                  slug
                }
              }
            }
          }
        }
      }
    }
    `}
    render={(response) => {
      const allArticles = response.allPrismicBlog.edges.map(({
        node: {
          id, uid, type, data,
        },
      }) => (
        <SymmetricGridItem
          key={id}
          gridLink={linkResolver({ type, uid })}
          linkText={data.title || ''}
          date={data.publish_date}
          roundedBorder
        >
          <Image
            source={data.thumbnail_image || {}}
            alt={data.thumbnail_image?.alt || ''}
          />
        </SymmetricGridItem>
      ));

      return (
        <Container>
          <SymmetricGrid gridTitle={primary.heading.text}>
            {allArticles}
          </SymmetricGrid>
        </Container>
      );
    }}
  />
);

BlogsSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.object,
  }).isRequired,
};

export default BlogsSlice;
