// extracted by mini-css-extract-plugin
export var BlackText = "AlternatingContentMediaBlock-module--BlackText--dXOm8";
export var HiddenLg = "AlternatingContentMediaBlock-module--HiddenLg--hvwgV";
export var HiddenMd = "AlternatingContentMediaBlock-module--HiddenMd--JBZBe";
export var HiddenXl = "AlternatingContentMediaBlock-module--HiddenXl--rhozj";
export var HiddenXs = "AlternatingContentMediaBlock-module--HiddenXs--yY1bM";
export var TextAlignRightXs = "AlternatingContentMediaBlock-module--TextAlignRightXs--yiOQi";
export var WhiteText = "AlternatingContentMediaBlock-module--WhiteText--k0lR9";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "AlternatingContentMediaBlock-module--container--7osC1";
export var counter = "AlternatingContentMediaBlock-module--counter--Ypf0L";
export var desktopWidth = "960px";
export var group = "AlternatingContentMediaBlock-module--group--NsjTJ";
export var image = "AlternatingContentMediaBlock-module--image--4tfCt";
export var lgBreakpoint = "60rem";
export var listHeading = "AlternatingContentMediaBlock-module--listHeading--6K47-";
export var listItem = "AlternatingContentMediaBlock-module--listItem--zzLrO";
export var listText = "AlternatingContentMediaBlock-module--listText--4Gb9R";
export var lowerContent = "AlternatingContentMediaBlock-module--lowerContent--6SrQS";
export var mdBreakpoint = "40rem";
export var serviceContent = "AlternatingContentMediaBlock-module--serviceContent--U-v5g";
export var serviceHeading = "AlternatingContentMediaBlock-module--serviceHeading--2l8q0";
export var serviceHeadingH3 = "AlternatingContentMediaBlock-module--serviceHeadingH3--N72h2";
export var srOnly = "AlternatingContentMediaBlock-module--sr-only--UfFYR";
export var tag = "AlternatingContentMediaBlock-module--tag--LydP5";
export var upperContent = "AlternatingContentMediaBlock-module--upperContent--3ZA6z";
export var visuallyHidden = "AlternatingContentMediaBlock-module--visually-hidden--ULSAO";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";