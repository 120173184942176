// extracted by mini-css-extract-plugin
export var BlackText = "ImageAndContentSlice-module--BlackText--Sg51m";
export var HiddenLg = "ImageAndContentSlice-module--HiddenLg--FnWVy";
export var HiddenMd = "ImageAndContentSlice-module--HiddenMd--yihdQ";
export var HiddenXl = "ImageAndContentSlice-module--HiddenXl--NydYB";
export var HiddenXs = "ImageAndContentSlice-module--HiddenXs--PkwGr";
export var TextAlignRightXs = "ImageAndContentSlice-module--TextAlignRightXs---bdNn";
export var WhiteText = "ImageAndContentSlice-module--WhiteText--7z6dB";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "ImageAndContentSlice-module--container--r9muq";
export var contentContainer = "ImageAndContentSlice-module--contentContainer--JW38b";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "ImageAndContentSlice-module--sr-only--qhI3q";
export var visuallyHidden = "ImageAndContentSlice-module--visually-hidden--T0QDp";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";