// extracted by mini-css-extract-plugin
export var BlackText = "TextCardsGridSlice-module--BlackText--LmRaT";
export var HiddenLg = "TextCardsGridSlice-module--HiddenLg--0tR0C";
export var HiddenMd = "TextCardsGridSlice-module--HiddenMd--jh9UA";
export var HiddenXl = "TextCardsGridSlice-module--HiddenXl--venWl";
export var HiddenXs = "TextCardsGridSlice-module--HiddenXs--mvH+N";
export var TextAlignRightXs = "TextCardsGridSlice-module--TextAlignRightXs--YIHIe";
export var WhiteText = "TextCardsGridSlice-module--WhiteText--3gYYn";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var callToAction = "TextCardsGridSlice-module--callToAction--ajF7t";
export var cardTitle = "TextCardsGridSlice-module--cardTitle--AB7Yi";
export var cardTitleH1 = "TextCardsGridSlice-module--cardTitleH1--gjK1K";
export var cardTitleH2 = "TextCardsGridSlice-module--cardTitleH2--sIqTZ";
export var cardTitleH3 = "TextCardsGridSlice-module--cardTitleH3--1h1qk";
export var cardTitleH4 = "TextCardsGridSlice-module--cardTitleH4--h0zOo";
export var cardTitleH5 = "TextCardsGridSlice-module--cardTitleH5--w8s3I";
export var desktopWidth = "960px";
export var grid = "TextCardsGridSlice-module--grid--HpBgM";
export var gridItemLink = "TextCardsGridSlice-module--gridItemLink--xQW1J";
export var hidden = "TextCardsGridSlice-module--hidden--aWp5P";
export var icon = "TextCardsGridSlice-module--icon--nyMr2";
export var imageWrapper = "TextCardsGridSlice-module--imageWrapper--TiRn+";
export var introduction = "TextCardsGridSlice-module--introduction--mXRFa";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var showMore = "TextCardsGridSlice-module--showMore--yx3vE";
export var showMoreWrapper = "TextCardsGridSlice-module--showMoreWrapper--3Y2Y4";
export var srOnly = "TextCardsGridSlice-module--sr-only--ke0y0";
export var title = "TextCardsGridSlice-module--title--grYoF";
export var visible = "TextCardsGridSlice-module--visible--C3tpT";
export var visuallyHidden = "TextCardsGridSlice-module--visually-hidden--xKKLl";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";