/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        url: url
        twitterUsername
      }
    }
    defaultImage: file(relativePath: { eq: "default.png" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1440, height: 900, quality: 100)
      }
    }
  }
`;

const SEO = ({
  title, description, pathname, article, thumbnailImage, landscapeImage,
}) => {
  const {
    site: {
      siteMetadata: {
        defaultTitle, titleTemplate, defaultDescription, url, twitterUsername,
      },
    },
    defaultImage,
  } = useStaticQuery(query);

  let imageSrc;

  // determine how to reference the image url
  if (thumbnailImage && pathname.includes('/careers/job-openings/job')) {
    // special case for job posts as image data structure is different
    imageSrc = landscapeImage ? landscapeImage.url : thumbnailImage.url;
  } else {
    // prefer landscape, then thumbnail, then default P/
    // in that order.
    const preferredImage = [
      landscapeImage?.localFile?.publicURL,
      thumbnailImage?.localFile?.publicURL,
      getSrc(defaultImage),
    ].find((value) => Boolean(value));

    // Only set `imageSrc` if `preferredImage` is truthy
    // this way the meta tag won't render with a broken URL
    // "Facebook [and other platforms] will automatically show
    // the first image that is at least 600px wide on your page"
    // – https://indieweb.org/The-Open-Graph-protocol#How_to_set_image
    if (preferredImage) {
      imageSrc = new URL(preferredImage, url);
    }
  }

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: imageSrc,
    url: new URL(pathname || '/', url),
  };

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <html lang="en" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {article && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.image && <meta property="og:image:alt" content="" />}

      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  thumbnailImage: PropTypes.object,
  landscapeImage: PropTypes.object,
};

SEO.defaultProps = {
  title: null,
  description: null,
  pathname: null,
  article: false,
  thumbnailImage: null,
  landscapeImage: null,
};

export default SEO;
