/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';
import * as styles from '../../components/ui/Body/Body.module.scss';
import { linkResolver } from '../../helpers/linkResolver';

import Code from '../../components/ui/Code';
import Grid from '../../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../../components/Layouts/Grids/GeneralGrid/GeneralGridItem';

const CodeAndContentSlice = ({
  layout, heading, subtitle, contentItems,
}) => (
  <div data-cy="content-slice" className={styles.content}>
    {heading && <h2 className={styles.rowHeading}>{heading}</h2>}

    <Grid>
      {subtitle && (
        <GridItem
          spanMd={layout === 'One column' ? '7' : '4'}
          offsetMd={layout === 'One column' ? '5' : '1'}
        >
          <h3 className={styles.rowDescription}>{subtitle}</h3>
        </GridItem>
      )}

      <GridItem
        spanMd={layout === 'One column' ? '12' : '7'}
        spanLg="7"
      >
        {contentItems.map(({ contentFormat, content }, index) => {
          if (contentFormat === 'content') {
            return (
              <div
                className={styles.groupedContentItem}
                // eslint-disable-next-line react/no-array-index-key
                key={`${content.text.substring(0, 10).replaceAll(' ', '')}${index}`}
              >
                <PrismicRichText field={content.richText} linkResolver={linkResolver} />
              </div>
            );
          }
          return (
            <div
              className={styles.groupedContentItem}
              // eslint-disable-next-line react/no-array-index-key
              key={`${content.text.substring(0, 10).replaceAll(' ', '')}${index}`}
            >
              <Code code={content.text} language={contentFormat.toLowerCase()} />
            </div>
          );
        })}
      </GridItem>
    </Grid>
  </div>
);

CodeAndContentSlice.propTypes = {
  layout: PropTypes.string,
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  contentItems: PropTypes.arrayOf(PropTypes.shape({
    contentFormat: PropTypes.oneOf(['content', 'javascript', 'typescript', 'php']),
    content: PropTypes.shape({
      html: PropTypes.string,
      richText: PropTypes.array,
      text: PropTypes.string,
    }),
  })),
};

CodeAndContentSlice.defaultProps = {
  layout: 'One column',
  heading: undefined,
  subtitle: undefined,
  contentItems: [],
};

export default CodeAndContentSlice;
