const ImageAndContentSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;
  const description = primary.description?.text?.trim() || null;
  const { image } = primary;
  const buttonLinkText = primary?.link_text?.text?.trim() || null;
  const buttonLinkTarget = primary.link.target || '_self';
  const buttonLink = primary.link.url || '';

  return {
    description,
    image,
    buttonLink,
    buttonLinkTarget,
    buttonLinkText,
  };
};
export default ImageAndContentSliceProps;
