// extracted by mini-css-extract-plugin
export var BlackText = "Collection-module--BlackText--UeLg0";
export var Collection = "Collection-module--Collection--fZOl6";
export var CollectionItem = "Collection-module--CollectionItem--rMWoT";
export var CollectionItemEmpty = "Collection-module--CollectionItem--empty--dN5it";
export var CollectionItemEmpty2 = "Collection-module--CollectionItem--empty-2--Ua3cq";
export var CollectionItemEmpty3 = "Collection-module--CollectionItem--empty-3--eEGpD";
export var CollectionItemEmpty4 = "Collection-module--CollectionItem--empty-4--jETE7";
export var CollectionItem__inner = "Collection-module--CollectionItem__inner--1bJFa";
export var CollectionItem__title = "Collection-module--CollectionItem__title--IjUXQ";
export var HiddenLg = "Collection-module--HiddenLg--wRi-Z";
export var HiddenMd = "Collection-module--HiddenMd--pQDKm";
export var HiddenXl = "Collection-module--HiddenXl--+lhpV";
export var HiddenXs = "Collection-module--HiddenXs--ngz3k";
export var TextAlignRightXs = "Collection-module--TextAlignRightXs--J5cJo";
export var WhiteText = "Collection-module--WhiteText--v-UQo";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "Collection-module--sr-only--EO27Z";
export var visuallyHidden = "Collection-module--visually-hidden--W9HW-";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";