// extracted by mini-css-extract-plugin
export var BlackText = "Accordion-module--BlackText--gUkhW";
export var HiddenLg = "Accordion-module--HiddenLg--wr7ch";
export var HiddenMd = "Accordion-module--HiddenMd--+nZNP";
export var HiddenXl = "Accordion-module--HiddenXl--7r89P";
export var HiddenXs = "Accordion-module--HiddenXs--1X+nl";
export var TextAlignRightXs = "Accordion-module--TextAlignRightXs--b0pKK";
export var WhiteText = "Accordion-module--WhiteText--YCWPy";
export var accordion = "Accordion-module--accordion--4w42c";
export var accordion__button = "Accordion-module--accordion__button---uywf";
export var accordion__heading = "Accordion-module--accordion__heading--CgnfW";
export var accordion__item = "Accordion-module--accordion__item--iAnK-";
export var accordion__panel = "Accordion-module--accordion__panel--t8ySj";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var fadein = "Accordion-module--fadein--SFsNe";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "Accordion-module--sr-only--ubdcd";
export var visuallyHidden = "Accordion-module--visually-hidden--w12MF";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";