/* eslint-disable camelcase */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Container from '../components/Layouts/Container';
import Grid from '../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../components/Layouts/Grids/GeneralGrid/GeneralGridItem';
import Image from '../components/ui/Image/Image';
import SiteLink from '../components/Link/SiteLink';
import { UpRightArrow, DownArrow } from '../components/ui/Icons/Icons';
import { mapPrismicPurposeTags, mapPurposeTagToHubLink, sortCaseStudiesByPublicationDate } from '../helpers/helpers';
import { linkResolver } from '../helpers/linkResolver';
import * as styles from './HomePageCaseStudiesSlice.module.scss';

const CaseStudy = ({
  id,
  gridItemStyles,
  title,
  imgSrc,
  type,
  uid,
  size,
  subtext,
  subtextLink,
  className,
}) => {
  const reduceTitleWidthClass = gridItemStyles.inner.aspectRatioBox === 'aspectRatioBoxTwo' ? 'reduceTitleWidth' : '';
  return (
    <GridItem
      key={id}
      {...gridItemStyles.outer}
      className={className}
    >
      <div className={styles.gridItem}>
        <div className={cx(
          styles.gridItemLink,
          styles[gridItemStyles.inner.verticalOffsetClass],
        )}
        >
          <SiteLink
            to={linkResolver({ type, uid })}
          >
            <div className={styles[gridItemStyles.inner.aspectRatioBox]}>
              <div className={styles.aspectRatioBoxInside}>
                <Image source={imgSrc} />
              </div>
            </div>
            <div>
              <p className={cx(
                styles.title,
                styles[size],
                styles[reduceTitleWidthClass],
              )}
              >{title}
              </p>
            </div>
            {subtext && subtextLink === undefined && (
              <p className={cx(styles.subtext)}>
                {subtext.toUpperCase()}
              </p>
            )}
          </SiteLink>
        </div>
        {subtextLink !== undefined && (
          <p className={cx(styles.subtextLink)}>
            <SiteLink
              to={subtextLink}
            >
              {subtext.toUpperCase()}
            </SiteLink>
          </p>
        )}
      </div>
    </GridItem>
  );
};

CaseStudy.propTypes = {
  id: PropTypes.string.isRequired,
  gridItemStyles: PropTypes.shape({
    outer: PropTypes.shape({
      span: PropTypes.string,
      spanMd: PropTypes.string,
      spanLg: PropTypes.string,
      offsetMd: PropTypes.string,
      offsetLg: PropTypes.string,
    }),
    inner: PropTypes.shape({
      aspectRatioBox: PropTypes.string,
      verticalOffsetClass: PropTypes.string,
    }),
  }).isRequired,
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.shape({
    url: PropTypes.string,
    localFile: PropTypes.shape({
      publicURL: PropTypes.string,
      extension: PropTypes.string,
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
      }),
    }),
    childImageSharp: PropTypes.shape({
      fixed: PropTypes.object,
    }),
  }).isRequired,
  type: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
  subtextLink: PropTypes.string,
  className: PropTypes.string,
};

CaseStudy.defaultProps = {
  className: undefined,
  subtextLink: undefined,
};

// type: '5col' | '6col' | '12col'
const getGridItemStyles = (type = '12col') => {
  switch (type) {
  case '4col': {
    return {
      outer: {
        span: '12',
        spanMd: '4',
        spanLg: '4',
      },
      inner: {
        aspectRatioBox: 'aspectRatioBoxOne',
        verticalOffsetClass: 'offsetVertically',
      },
    };
  }
  case '5col': {
    return {
      outer: {
        span: '12',
        spanMd: '5',
        spanLg: '5',
        offsetMd: '2',
        offsetLg: '2',
      },
      inner: {
        aspectRatioBox: 'aspectRatioBoxTwo',
      },
    };
  }
  case '12col': {
    return {
      outer: {
        spanMd: '12',
        spanLg: '12',
      },
      inner: {
        aspectRatioBox: 'aspectRatioBoxThree',
      },
    };
  }
  default:
    return {
      outer: {},
      inner: {},
    };
  }
};

const HomePageCaseStudiesSlice = () => (
  <StaticQuery
    query={graphql`{
        allPrismicCaseStudy(sort: {fields: first_publication_date, order: DESC}, limit: 5) {
          edges {
            node {
              id
              uid
              type
              first_publication_date
              data {
                publication_date
                title
                thumbnail_image {
                  localFile {
                    publicURL
                    extension
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED,
                        quality: 100,
                        width: 640,
                        breakpoints: [640, 960, 1400]
                      )
                    }
                  }
                }
                landscape_image {
                  localFile {
                    publicURL
                    extension
                    childImageSharp {
                      gatsbyImageData(
                        layout: CONSTRAINED,
                        quality: 100,
                        width: 1400,
                        breakpoints: [640, 960, 1400]
                      )
                    }
                  }
                }
                purpose_tags {
                  purposes {
                    type
                    document {
                      ... on PrismicPurposeCategory {
                        id
                        data {
                          purpose
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`}
    render={({
      allPrismicCaseStudy: { edges },
    }) => {
      // clean up each case study object
      const caseStudies = sortCaseStudiesByPublicationDate(edges.map((n) => n.node));

      const cases = caseStudies.map((c, index) => {
        const size = ((index + 1) % 3) === 0 ? 'Full' : 'Half';
        const purposeTags = mapPrismicPurposeTags(c.data.purpose_tags);
        const subtext = purposeTags?.[0]?.title;
        const subtextLink = mapPurposeTagToHubLink(subtext);
        let gridItemStyles = {};
        if (index === 0 || index === 3) {
          gridItemStyles = getGridItemStyles('5col');
        }
        if (index === 1 || index === 4) {
          gridItemStyles = getGridItemStyles('4col');
        }
        if (index === 2) {
          gridItemStyles = getGridItemStyles('12col');
        }

        if (size === 'Full') {
          return (
            <React.Fragment key={c.id}>
              <CaseStudy
                id={c.id}
                gridItemStyles={gridItemStyles}
                title={c.data.title}
                purposeTag={purposeTags[0] && purposeTags[0].title}
                imgSrc={c.data.thumbnail_image}
                type={c.type}
                uid={c.uid}
                size={size}
                subtext={subtext}
                subtextLink={subtextLink}
                className={styles.hideAtMdBreakpoint}
              />
              <CaseStudy
                id={c.id}
                gridItemStyles={gridItemStyles}
                title={c.data.title}
                purposeTag={purposeTags[0] && purposeTags[0].title}
                imgSrc={c.data.landscape_image}
                type={c.type}
                uid={c.uid}
                size={size}
                subtext={subtext}
                subtextLink={subtextLink}
                className={styles.hideAtSmallBreakpoint}
              />
            </React.Fragment>
          );
        }

        return (
          <CaseStudy
            key={c.id}
            id={c.id}
            gridItemStyles={gridItemStyles}
            title={c.data.title}
            purposeTag={purposeTags[0] && purposeTags[0].title}
            imgSrc={c.data.thumbnail_image}
            type={c.type}
            uid={c.uid}
            size={size}
            subtext={subtext}
            subtextLink={subtextLink}
          />
        );
      });

      return (
        <>
          <Container>
            <div
              className={styles.sectionHeading}
            >
              <h3 className={styles.sectionHeading__text}>Recent work</h3>
              <DownArrow className={styles.icon} />
            </div>
            <div>
              <Grid>
                {cases}
              </Grid>
            </div>
            <div className={styles.sectionFooter}>
              <span className={styles.link}>
                <SiteLink to="/work">
                  See all of our work <UpRightArrow />
                </SiteLink>
              </span>
            </div>
          </Container>
        </>
      );
    }}
  />
);

export default HomePageCaseStudiesSlice;
