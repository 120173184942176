import React, { useContext } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import Prismic from 'prismic-reactjs';

import AnchorNavigationSlice from '../../../slices/AnchorNavigationSlice/AnchorNavigationSlice';

import PageData from '../../../helpers/pageData';

import * as styles from './HeaderBanner.module.scss';

const createEventAddress = (eventText) => {
  const linesOfText = eventText.split(';');
  const linesNodes = [];

  linesOfText.forEach((line) => {
    linesNodes.push(<span key={line}>{line}<br /></span>);
  });

  return (
    <address className={styles.eventDetailsBlock}>
      {linesNodes}
    </address>
  );
};

const createEventDateTime = (eventDateStart, eventDateEnd, isWhite) => {
  const eventStart = Prismic.Date(eventDateStart);
  const eventEnd = Prismic.Date(eventDateEnd);

  const startsOnDay = Intl.DateTimeFormat('en-AU', {
    weekday: 'long',
  }).format(eventStart);

  const startsOnDate = Intl.DateTimeFormat('en-AU', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }).format(eventStart);

  const startsOnTime = Intl.DateTimeFormat('en-AU', {
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
  }).format(eventStart);

  const endsOnTime = Intl.DateTimeFormat('en-AU', {
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
  }).format(eventEnd);

  return (
    <div className={styles.eventDetailsBlock}>
      <div className={classNames(styles[isWhite])}>
        {startsOnDay}<br />
        {startsOnDate}<br />
        {startsOnTime} - {endsOnTime}
      </div>
    </div>
  );
};

const createBannerWithEvent = (
  titleText, subText, eventAddress,
  startDateTime, endDateTime, isWhite,
) => (
  <>
    <h1 className={classNames(styles.bannerHeading, styles[isWhite])}>{titleText}</h1>
    <h2 className={classNames(styles.bannerSubText, styles[isWhite])}>{subText}</h2>
    <div className={styles.eventDetails}>
      {createEventAddress(eventAddress, isWhite)}
      {createEventDateTime(startDateTime, endDateTime, isWhite)}
    </div>
  </>
);

const createBannerWithSubText = (titleText, subText, isWhite) => (
  <>
    <h1 className={classNames(styles.bannerHeading, styles[isWhite])}>
      {titleText}
    </h1>
    <p className={classNames(styles.bannerSubText, styles[isWhite])}>
      {subText}
    </p>
  </>
);

const createBannerWithTag = (titleText, tag, isWhite) => (
  <>
    <div className={classNames(styles.bannerTag, styles[isWhite])}>
      {tag}
    </div>
    <h1 className={classNames(styles.bannerHeading, styles[isWhite])}>
      {titleText}
    </h1>
  </>
);

const createBannerWithTextLink = (titleText, textLinkText, textLinkUrl, isWhite) => (
  <>
    <h1 className={classNames(styles.bannerHeading, styles[isWhite])}>{titleText}</h1>
    <h2 className={classNames(styles.bannerSubText, styles[isWhite])}>
      <Link to={textLinkUrl} isWhite={isWhite === 'white'}>
        {textLinkText}
      </Link>
    </h2>
  </>
);

const createBannerText = (context) => {
  const {
    theme, titleText, subText, tag,
    address, startDateTime, endDateTime,
    textLinkText, textLinkUrl,
  } = context;
  const isWhite = theme === 'Black' ? 'white' : '';

  if (startDateTime !== '' && startDateTime !== undefined && address !== null) {
    return createBannerWithEvent(
      titleText, subText, address.text,
      startDateTime, endDateTime, isWhite,
    );
  }
  if (!subText !== '' && subText !== undefined && subText !== null) {
    return createBannerWithSubText(titleText, subText, isWhite);
  }
  if (tag !== '' && tag !== undefined && tag !== null) {
    return createBannerWithTag(titleText, tag, isWhite);
  }
  if (textLinkText !== '' && textLinkText !== undefined && textLinkText !== null) {
    return createBannerWithTextLink(titleText, textLinkText, textLinkUrl, isWhite);
  }
  return (
    <h1 className={classNames('h1', styles.bannerHeading)}>
      {titleText}
    </h1>
  );
};

const HeaderBanner = () => {
  const context = useContext(PageData);

  if (!context.titleText) return null;

  if (context.longBannerText) {
    return (
      <section className={styles.bannerContainer}>
        <h1 className={classNames('h2', styles.longBannerText)}>
          {context.longBannerText}
        </h1>
        { context.subNav && <AnchorNavigationSlice {...context.subNav} /> }
      </section>
    );
  }

  return (
    <section className={styles.bannerContainer}>
      {createBannerText(context)}
      { context.subNav && <AnchorNavigationSlice {...context.subNav} /> }
    </section>
  );
};

export default HeaderBanner;
