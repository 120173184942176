import { linkResolver } from '../../helpers/linkResolver';
import useContactBlocks from '../../helpers/useContactBlocks';
import { slicePropsFormatter } from './dto';

export default (
  type,
  uid,
  data,
) => {
  // If there is a subnavigation element in the page body / slices
  // then we want to pull it out here and add it to page context so it can be
  // displayed inside the header
  let subNav = null;

  const body = (data.body || []).map((slice) => {
    if (slice.slice_type === 'anchor_navigation') {
      subNav = slicePropsFormatter(slice);
      return null;
    }
    return slice;
  }).filter((slice) => !!slice);

  const contactBlocks = useContactBlocks();

  let dataContext = {
    theme: data.page_colour,
    titleText: data.title,
    tag: data.tag?.text,
    subText: data.introduction,
    subNav,
    body,
    uid,
    contactBlocks,
    relatedContent: data.related_content,
    longBannerText: data.long_banner_heading?.text,
    pageTitle: data.seo_title,
    pageDescription: data.seo_description,
    thumbnailImage: data.thumbnail_image,
    landscapeImage: data.landscape_image,
    purposeTags: (data.purpose_tags || []).map((p) => p.purposes.slug),
    processTags: (data.process_tags || []).map((p) => p.processes.slug),
    serviceTags: (data.service_tags || []).map((p) => p.services.slug),
  };

  if (type === 'event') {
    dataContext = {
      ...dataContext,
      startDateTime: data.start_date_time,
      endDateTime: data.end_date_time,
      address: data.address,
      buttonText: 'Book tickets now',
    };
  }

  // This only makes sense for prismic pages
  if (type && uid) {
    dataContext = {
      ...dataContext,
      url: linkResolver({ type, uid }),
    }
  }
  return dataContext;
};
