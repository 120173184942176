import { linkResolver } from '../../helpers/linkResolver';

const getAccordionSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;
  const heading = primary.heading?.text?.trim() || null;
  const items = sliceData.items.map((item) => {
    const sectionHeading = item.heading?.text?.trim() || null;
    const { text } = item;
    const linkText = item.link_text?.text?.trim() || null;
    let link = null;
    // Check if there is button text and url and assign them to readable variables
    if (item.link_text && item.link) {
      const isExternal = item.link.raw.link_type !== 'Document';
      link = isExternal
        ? item.link.raw.url
        : linkResolver({
          type: item.link.raw.type,
          uid: item.link.raw.uid,
        });
    }

    return {
      heading: sectionHeading,
      text,
      linkText,
      link,
    };
  });

  return {
    heading,
    items,
  };
};

export default getAccordionSliceProps;
