// extracted by mini-css-extract-plugin
export var BlackText = "Quote-module--BlackText--Fl6JD";
export var HiddenLg = "Quote-module--HiddenLg--bCfGt";
export var HiddenMd = "Quote-module--HiddenMd--OxoJz";
export var HiddenXl = "Quote-module--HiddenXl--UUSIR";
export var HiddenXs = "Quote-module--HiddenXs--xNj9T";
export var TextAlignRightXs = "Quote-module--TextAlignRightXs--IDnX-";
export var WhiteText = "Quote-module--WhiteText--Qp8sO";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var quote = "Quote-module--quote--kxBrS";
export var srOnly = "Quote-module--sr-only--9DPvl";
export var visuallyHidden = "Quote-module--visually-hidden--05QpR";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";