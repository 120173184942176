/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import SiteLink from '../../components/Link/SiteLink';
import { UpRightArrow } from '../../components/ui/Icons/Icons';
import * as styles from './ServiceSuggestionSlice.module.scss';
import Container from '../../components/Layouts/Container';

const ServiceSuggestionSliceProps = {
  title: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.shape({
    serviceName: PropTypes.string,
    serviceLink: PropTypes.string,
  })),
};

const ServiceSuggestionSlice = ({
  title, services,
}) => (
  <Container>
    <div className={styles.container}>
      <div>
        <h3>{title}</h3>
      </div>
      <ul className={styles.services}>
        {services && services.map((service, i) => (
          <li className={styles.serviceItem} key={`serviceList-${i}`}>
            <h3 className={styles.serviceName}>
              <span aria-hidden="true">→</span>&nbsp;
              <SiteLink className={styles.serviceLink} to={service.serviceLink}>
                {service.serviceName}
              </SiteLink>
            </h3>
          </li>
        ))}
        <div className={styles.linkText}>
          <div>
            <SiteLink to="/services" bold>
              <UpRightArrow className={styles.icon} /> Back to all services
            </SiteLink>
          </div>
        </div>
      </ul>
    </div>
  </Container>
);

ServiceSuggestionSlice.propTypes = ServiceSuggestionSliceProps;

ServiceSuggestionSlice.defaultProps = ServiceSuggestionSliceProps;

export default ServiceSuggestionSlice;
