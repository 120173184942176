/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './ServiceSection.module.scss';
import SiteLink from '../../components/Link/SiteLink';
import Container from '../../components/Layouts/Container';
import Image from '../../components/ui/Image/Image';

const ServiceSectionProps = {
  tag: PropTypes.string,
  serviceDescription: PropTypes.string,
  heading: PropTypes.string,
  visual: PropTypes.image,
  items: PropTypes.arrayOf(PropTypes.shape({
    serviceItemTitle: PropTypes.string,
    serviceItemDescription: PropTypes.string,
    serviceItemLink: PropTypes.string,
  })),
};

const WithTagAndDescription = ({
  heading, items, tag, serviceDescription,
}) => (
  <div id="services_list" className={styles.tagContainer}>
    <div className={styles.tag}>{tag}</div>
    <h2 id={heading} className={styles.heading}>{heading}</h2>
    <div className={styles.twoColumnContent}>
      <p className={styles.description}>{serviceDescription}</p>
      <div className={styles.services}>
        <div className={styles.serviceListColumn}>
          {items && items.map((service, i) => (
            <div className={styles.serviceList} key={`serviceList-${i}`}>
              {service.serviceItemLink ? (
                <div className={styles.serviceItemTitle}>
                  <SiteLink to={service.serviceItemLink}>
                    <h3 className="h4">
                      <span aria-hidden="true">→</span>&nbsp;
                      {service.serviceItemTitle}
                    </h3>
                  </SiteLink>
                </div>
              ) : (
                <h3 className="h4">{service.serviceItemTitle}</h3>
              )}
              <p className={styles.serviceItemDescription}>{service.serviceItemDescription}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const WithVisual = ({ heading, items, visual }) => (
  <div id="services_list" className={styles.serviceContainer}>
    <div>
      <div className={styles.headingIconColumn}>
        <h3 id={heading} className={styles.heading}>{heading}</h3>
        <Image className={styles.svg} source={visual} alt="" />
      </div>
    </div>
    <div className={styles.services}>
      <div className={styles.serviceListColumn}>
        {items && items.map((service, i) => (
          <div className={styles.serviceList} key={`serviceList-${i}`}>
            {service.serviceItemLink ? (
              <div className={styles.serviceItemTitle}>
                <SiteLink to={service.serviceItemLink}>
                  <h4>
                    <span aria-hidden="true">→</span>&nbsp;
                    {service.serviceItemTitle}
                  </h4>
                </SiteLink>
              </div>
            ) : (
              <h4>{service.serviceItemTitle}</h4>
            )}
            <p className={styles.serviceItemDescription}>{service.serviceItemDescription}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const ServiceSection = ({
  heading, items, visual, tag, serviceDescription,
}) => (
  <Container>
    {tag && serviceDescription ? (
      <WithTagAndDescription
        heading={heading}
        items={items}
        tag={tag}
        serviceDescription={serviceDescription}
      />
    ) : (
      <WithVisual heading={heading} items={items} visual={visual} />
    )}
  </Container>
);

ServiceSection.propTypes = ServiceSectionProps;
ServiceSection.defaultProps = ServiceSectionProps;

export default ServiceSection;
