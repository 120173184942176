// extracted by mini-css-extract-plugin
export var BlackText = "AccordionSlice-module--BlackText--JwkTz";
export var HiddenLg = "AccordionSlice-module--HiddenLg--D7BG8";
export var HiddenMd = "AccordionSlice-module--HiddenMd--FH0MR";
export var HiddenXl = "AccordionSlice-module--HiddenXl--ZSfDO";
export var HiddenXs = "AccordionSlice-module--HiddenXs--cJCoT";
export var TextAlignRightXs = "AccordionSlice-module--TextAlignRightXs--eoW+G";
export var WhiteText = "AccordionSlice-module--WhiteText--NgrIa";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var heading = "AccordionSlice-module--heading--Cub8F";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "AccordionSlice-module--sr-only--ljkDr";
export var visuallyHidden = "AccordionSlice-module--visually-hidden--qOrpZ";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";