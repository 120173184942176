// extracted by mini-css-extract-plugin
export var BlackText = "HeaderBanner-module--BlackText--h1cy6";
export var HiddenLg = "HeaderBanner-module--HiddenLg--0I7PD";
export var HiddenMd = "HeaderBanner-module--HiddenMd--F6XxA";
export var HiddenXl = "HeaderBanner-module--HiddenXl--Xv0Hp";
export var HiddenXs = "HeaderBanner-module--HiddenXs--bwTth";
export var TextAlignRightXs = "HeaderBanner-module--TextAlignRightXs--19QZx";
export var WhiteText = "HeaderBanner-module--WhiteText--pnug-";
export var bannerContainer = "HeaderBanner-module--bannerContainer--RvAcB";
export var bannerHeading = "HeaderBanner-module--bannerHeading--W0-EX";
export var bannerSubText = "HeaderBanner-module--bannerSubText--mMyG6";
export var bannerTag = "HeaderBanner-module--bannerTag--v0Pvu";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var eventDetails = "HeaderBanner-module--eventDetails--5b2oH";
export var eventDetailsBlock = "HeaderBanner-module--eventDetailsBlock--lgMLc";
export var lgBreakpoint = "60rem";
export var longBannerText = "HeaderBanner-module--longBannerText--HGKAx";
export var mdBreakpoint = "40rem";
export var srOnly = "HeaderBanner-module--sr-only--w6gVF";
export var visuallyHidden = "HeaderBanner-module--visually-hidden--NhMOj";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";