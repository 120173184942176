import { useMediaQuery } from 'react-responsive';

import * as variables from './_variables.module.scss';

const useMediaSize = (size) => {
  let query = '';
  switch (size) {
  case 'mobile':
    query = `(max-width: ${variables.mdBreakpoint})`;
    break;
  case 'tablet':
    query = `(min-width: ${variables.mdBreakpoint}) and (max-width: ${variables.lgBreakpoint})`;
    break;
  case 'desktop':
    query = `(min-width: ${variables.lgBreakpoint})`;
    break;
  default:
    break;
  }
  return useMediaQuery({ query });
};

export default useMediaSize;
