/* eslint-disable indent */
/* eslint-disable camelcase */
import getAccordionSliceProps from './getAccordionSliceProps';
import getActionButtonSliceProps from './getActionButtonSliceProps';
import getAlternatingContentMediaSliceProps from './getAlternatingContentMediaSliceProps';
import getAnchorNavigationSliceProps from './getAnchorNavigationSliceProps';
import getCodeAndContentSliceProps from './getCodeAndContentSliceProps';
import getContentSliceProps from './getContentSliceProps';
import getContentAndCardsSliceProps from './getContentAndCardsSliceProps';
import getHubspotEmbeddedFormProps from './getHubspotEmbeddedFormProps';
import getImageAndContentSliceProps from './getImageAndContentSliceProps';
import getImageAndContentCarouselSliceProps from './getImageAndContentCarouselSliceProps';
import getOurWorkProps from './getOurWorkProps';
import getProductCaseStudiesSliceProps from './getProductCaseStudiesSliceProps';
import getQuoteCarouselSliceProps from './getQuoteCarouselSliceProps';
import getServiceHubHeroSliceProps from './getServiceHubHeroSliceProps';
import getServiceSectionProps from './getServiceSectionProps';
import getServiceDesignHeroProps from './getServiceDesignHeroProps';
import getServiceSuggestionProps from './getServiceSuggestionProps';
import getTagAndContentSliceProps from './getTagAndContentSliceProps';
import getUpcomingSessionProps from './getUpcomingSessionProps';

export {
 getAccordionSliceProps,
 getActionButtonSliceProps,
 getAlternatingContentMediaSliceProps,
 getAnchorNavigationSliceProps,
 getCodeAndContentSliceProps,
 getContentSliceProps,
  getContentAndCardsSliceProps,
 getHubspotEmbeddedFormProps,
 getImageAndContentSliceProps,
  getImageAndContentCarouselSliceProps,
 getOurWorkProps,
  getProductCaseStudiesSliceProps,
 getQuoteCarouselSliceProps,
 getServiceHubHeroSliceProps,
 getServiceSectionProps,
 getServiceDesignHeroProps,
 getServiceSuggestionProps,
 getTagAndContentSliceProps,
 getUpcomingSessionProps,
};

// eslint-disable-next-line import/prefer-default-export
export const slicePropsFormatter = (sliceData) => {
  switch (sliceData.slice_type) {
    case 'accordion':
      return getAccordionSliceProps(sliceData);
    case 'action_button':
      return getActionButtonSliceProps(sliceData);
    case 'alternating_media_text':
      return getAlternatingContentMediaSliceProps(sliceData);
    case 'anchor_navigation':
      return getAnchorNavigationSliceProps(sliceData);
    case 'code_and_content':
      return getCodeAndContentSliceProps(sliceData);
    case 'content':
      return getContentSliceProps(sliceData);
    case 'content_and_cards':
      return getContentAndCardsSliceProps(sliceData);
    case 'get_tickets_card':
      return getUpcomingSessionProps(sliceData);
    case 'hs_embedded_form':
      return getHubspotEmbeddedFormProps(sliceData);
    case 'image_and_content':
      return getImageAndContentSliceProps(sliceData);
    case 'image_and_content_carousel':
      return getImageAndContentCarouselSliceProps(sliceData);
    case 'our_work':
      return getOurWorkProps(sliceData);
    case 'product_case_studies':
      return getProductCaseStudiesSliceProps(sliceData);
    case 'quote_carousel':
      return getQuoteCarouselSliceProps(sliceData);
    case 'services_hero':
      return getServiceHubHeroSliceProps(sliceData);
    case 'services_list':
      return getServiceSectionProps(sliceData);
    case 'service_post_hero':
      return getServiceDesignHeroProps(sliceData);
    case 'service_suggestion':
      return getServiceSuggestionProps(sliceData);
    case 'tag_and_content':
      return getTagAndContentSliceProps(sliceData);
    default:
      return sliceData;
  }
};
