import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState({
    scrollTop: undefined,
  });

  useEffect(() => {
    // Handler to call on scroll
    function handleScroll() {
      // Set element scroll position
      setScrollPosition({
        scrollTop: document.getElementById('navOverlay').scrollTop,
      });
    }

    const debouncedScroll = debounce(handleScroll, 10);
    // Add event listener
    document.getElementById('navOverlay').addEventListener('scroll', debouncedScroll);

    // Call handler right away so state gets updated with initial scrol position
    handleScroll();

    // Remove event listener on cleanup
    return () => document.getElementById('navOverlay').removeEventListener('scroll', handleScroll);
  }, []);

  return scrollPosition.scrollTop > 1;
};

export default useScroll;
