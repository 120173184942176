/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { PrismicRichText } from '@prismicio/react';
import PageData from '../../../helpers/pageData';

import * as styles from './Banner.module.scss';
import { linkResolver } from '../../../helpers/linkResolver';

const Banner = ({
  bigText, textLink, textColour, backgroundColour,
}) => (
  <PageData.Consumer>
    {(context) => (
      <div
        className={cx(
          styles.bannerWrapper,
          styles[backgroundColour],
          { [styles.noPadding]: context.theme && context.theme.toLowerCase() === backgroundColour },
        )}
      >
        <div className={cx(styles.bannerInner, styles[textColour])}>
          <div className={cx(styles.bannerHeading)}>
            <PrismicRichText field={bigText.richText} linkResolver={linkResolver} />
          </div>
          <PrismicRichText field={textLink.richText} linkResolver={linkResolver} />
        </div>
      </div>
    )}
  </PageData.Consumer>
);

Banner.propTypes = {
  bigText: PropTypes.array,
  textLink: PropTypes.array,
  textColour: PropTypes.oneOf(['black', 'white']).isRequired,
  backgroundColour: PropTypes.oneOf(['black', 'white']).isRequired,
};

Banner.defaultProps = {
  bigText: [],
  textLink: [],
};

export default Banner;
