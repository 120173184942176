/* eslint-disable camelcase */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';
import classNames from 'classnames';
import slugify from 'slugify';
import { linkResolver } from '../helpers/linkResolver';

import * as styles from './TextCardsGridSlice.module.scss';
import Container from '../components/Layouts/Container';

import Grid from '../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../components/Layouts/Grids/GeneralGrid/GeneralGridItem';
import SiteLink from '../components/Link/SiteLink';
import Image from '../components/ui/Image/Image';
import { Minus, Plus } from '../components/ui/Icons/Icons';

const TextCardsGridSlice = (props) => {
  const { primary, items = [] } = props;
  const { section_introduction, section_title, open_by_default } = primary;
  const [isOpen, setIsOpen] = useState(open_by_default);
  const focusOnOpenRef = useRef(null);

  const cards = items.map((item) => (
    <GridItem
      key={item.id}
      span="12"
      spanMd="5"
      spanLg="3"
    >
      <div className={styles.gridItemLink}>
        <Image source={item.image} alt={item.image?.alt || ''} imageWrapperClassName={styles.imageWrapper} />
        <p
          className={classNames({
            [styles.cardTitle]: true,
            [styles.cardTitleH1]: item.title.richText[0].type === 'heading1',
            [styles.cardTitleH2]: item.title.richText[0].type === 'heading2',
            [styles.cardTitleH3]: item.title.richText[0].type === 'heading3',
            [styles.cardTitleH4]: item.title.richText[0].type === 'heading4',
            [styles.cardTitleH5]: item.title.richText[0].type === 'heading5',
          })}
        >
          <SiteLink to={item.card_link.url} noTextDecoration>
            {item.title.text}
          </SiteLink>
        </p>
        <PrismicRichText field={item.description.richText} linkResolver={linkResolver} />
        {item.call_to_action?.text
        && (
          <p className={styles.callToAction}>
            {item.call_to_action.text} →
          </p>
        )}
      </div>
    </GridItem>
  ));

  const firstEightCards = cards.slice(0, 8);
  const lastCards = cards.slice(8);

  return (
    <Container>
      {section_title
      && (
        <h2 className={styles.title}>
          {section_title.text}
        </h2>
      )}

      <div className={styles.introduction}>
        {section_introduction
      && <PrismicRichText field={section_introduction.richText} linkResolver={linkResolver} />}
      </div>

      {cards && cards.length
      && (
        <Grid className={styles.grid}>
          {firstEightCards}
        </Grid>
      )}

      <div
        tabIndex="-1"
        ref={focusOnOpenRef}
      />

      {cards && cards.length > 8 && lastCards
        && (
          <Grid
            className={isOpen ? styles.visible : styles.hidden}
            inert={isOpen ? undefined : ''}
            id={`${slugify(section_title.text)}-last-cards`}
          >
            {lastCards}
          </Grid>
        )}

      {cards && cards.length > 8 && (
        <div className={styles.showMoreWrapper}>
          <button
            type="button"
            className={styles.showMore}
            onClick={() => {
              setIsOpen(!isOpen);
              if (!isOpen) focusOnOpenRef.current.focus();
            }}
            aria-expanded={isOpen}
            aria-controls={`${slugify(section_title.text)}-last-cards`}
          >
            {isOpen ? (
              <>
                <h3>Show less</h3>
                <Minus className={styles.icon} />
              </>
            ) : (
              <>
                <h3>Show more</h3>
                <Plus className={styles.icon} />
              </>
            )}
          </button>
        </div>
      )}
    </Container>
  );
};

TextCardsGridSlice.propTypes = {
  primary: PropTypes.shape({
    section_introduction: PropTypes.shape({
      text: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      richText: PropTypes.array,
    }),
    section_title: PropTypes.shape({
      text: PropTypes.string,
    }),
    open_by_default: PropTypes.bool,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.shape({
      text: PropTypes.string,
    }),
    description: PropTypes.shape({
      text: PropTypes.string,
    }),
    call_to_action: PropTypes.shape({
      text: PropTypes.string,
    }),
    card_link: PropTypes.shape({
      target: PropTypes.string,
      url: PropTypes.string,
    }),
  })).isRequired,
};

export default TextCardsGridSlice;
