const getServiceDesignHeroProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;
  const tag = primary.tag?.text?.trim() || null;
  const heading = primary.heading?.text?.trim() || null;
  const content = primary.content?.text?.trim() || null;
  return {
    tag,
    heading,
    content,
  };
};

export default getServiceDesignHeroProps;
