// extracted by mini-css-extract-plugin
export var BlackText = "ImpactAreasSlice-module--BlackText--NWD4F";
export var HiddenLg = "ImpactAreasSlice-module--HiddenLg--zIt2l";
export var HiddenMd = "ImpactAreasSlice-module--HiddenMd--vJqIx";
export var HiddenXl = "ImpactAreasSlice-module--HiddenXl--ED9aw";
export var HiddenXs = "ImpactAreasSlice-module--HiddenXs--1TQ7a";
export var TextAlignRightXs = "ImpactAreasSlice-module--TextAlignRightXs--LBbLl";
export var WhiteText = "ImpactAreasSlice-module--WhiteText--Zx+cd";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "ImpactAreasSlice-module--container--W45YX";
export var desktopWidth = "960px";
export var hub = "ImpactAreasSlice-module--hub--BTbuB";
export var hubShapes = "ImpactAreasSlice-module--hubShapes--b53+b";
export var hubs = "ImpactAreasSlice-module--hubs--Zs92I";
export var icon = "ImpactAreasSlice-module--icon--CMCfV";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var sectionHeading = "ImpactAreasSlice-module--sectionHeading--fzIVb";
export var sectionHeading__text = "ImpactAreasSlice-module--sectionHeading__text--XbbIj";
export var showImage = "ImpactAreasSlice-module--showImage--tQ8XC";
export var srOnly = "ImpactAreasSlice-module--sr-only--jSu-6";
export var visuallyHidden = "ImpactAreasSlice-module--visually-hidden--CwgVu";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";