/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { shuffleArray } from '../helpers/helpers';

import Container from '../components/Layouts/Container';
import PageData from '../helpers/pageData';
import Image from '../components/ui/Image/Image';
import SymmetricGrid from '../components/Layouts/Grids/SymmetricGrid/SymmetricGrid';
import SymmetricGridItem from '../components/Layouts/Grids/SymmetricGrid/SymmetricGridItem';
import { linkResolver } from '../helpers/linkResolver';

const RelatedContentSlice = ({ input: { primary } }) => (
  <StaticQuery
    query={graphql`{
      blogs: allPrismicBlog(sort: {fields: data___publish_date, order: DESC}) {
        edges {
          node {
            id
            uid
            type
            data {
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              process_tags {
                processes {
                  slug
                  type
                }
              }
              purpose_tags {
                purposes {
                  type
                  slug
                }
              }
              service_tags {
                services {
                  type
                  slug
                }
              }
            }
          }
        }
      }
      caseStudies: allPrismicCaseStudy(sort: {fields: first_publication_date, order: DESC})  {
        edges {
          node {
            id
            uid
            type
            data {
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              process_tags {
                processes {
                  slug
                  type
                }
              }
              purpose_tags {
                purposes {
                  type
                  slug
                }
              }
              service_tags {
                services {
                  type
                  slug
                }
              }
            }
          }
        }
      }
      reports: allPrismicReport(sort: {fields: first_publication_date, order: DESC})  {
        edges {
          node {
            id
            uid
            type
            data {
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              process_tags {
                processes {
                  slug
                  type
                }
              }
              purpose_tags {
                purposes {
                  type
                  slug
                }
              }
              service_tags {
                services {
                  type
                  slug
                }
              }
            }
          }
        }
      }
      events: allPrismicEvent(sort: {fields: first_publication_date, order: DESC})  {
        edges {
          node {
            id
            uid
            type
            data {
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              process_tags {
                processes {
                  slug
                  type
                }
              }
              purpose_tags {
                purposes {
                  type
                  slug
                }
              }
              service_tags {
                services {
                  type
                  slug
                }
              }
            }
          }
        }
      }
    }`}
    render={({
      blogs, caseStudies, reports, events,
    }) => {
      const {
        purposeTags, processTags, serviceTags,
      } = useContext(PageData);

      const [randomRelatedContent, setRandomRelatedContent] = useState([]);

      const isRelatedProcess = (tag) => processTags.includes(tag.processes.slug);
      const isRelatedPurpose = (tag) => purposeTags.includes(tag.purposes.slug);
      const isRelatedService = (tag) => serviceTags.includes(tag.services.slug);

      const filterArrayByTags = (array) => array.filter(({
        node: { data: { process_tags, purpose_tags, service_tags } },
      }) => {
        const matchedProcess = process_tags.find((tag) => isRelatedProcess(tag));
        const matchedPurpose = purpose_tags.find((tag) => isRelatedPurpose(tag));
        const matchedService = service_tags.find((tag) => isRelatedService(tag));
        return (matchedProcess !== undefined
          || matchedPurpose !== undefined
          || matchedService !== undefined);
      });

      const filteredBlogs = filterArrayByTags(blogs.edges);
      const filteredCaseStudies = filterArrayByTags(caseStudies.edges);
      const filteredReports = filterArrayByTags(reports.edges);
      const filteredEvents = filterArrayByTags(events.edges);

      const allRelatedContent = [
        ...filteredBlogs,
        ...filteredCaseStudies,
        ...filteredReports,
        ...filteredEvents,
      ];
      const numberOfItemsToShow = primary.items_to_show || 3;

      useEffect(() => {
        if (!allRelatedContent) return null;
        const shuffledContent = shuffleArray(allRelatedContent).slice(0, numberOfItemsToShow);
        return setRandomRelatedContent(shuffledContent);
      }, [blogs, caseStudies, reports, events]);

      const relatedContentItems = randomRelatedContent.map(({
        node: {
          id, uid, type, data,
        },
      }) => (
        <SymmetricGridItem
          key={id}
          gridLink={linkResolver({ type, uid })}
          linkText={data.title || ''}
          date={data.publish_date}
          roundedBorder
        >
          <Image
            source={data.thumbnail_image || {}}
            alt={data.thumbnail_image ? data.thumbnail_image.alt : ''}
          />
        </SymmetricGridItem>
      ));

      return (
        <Container>
          <SymmetricGrid gridTitle={primary.related_content_title.text || ''}>
            {relatedContentItems}
          </SymmetricGrid>
        </Container>
      );
    }}
  />
);

RelatedContentSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      items_to_show: PropTypes.number,
      related_content_title: PropTypes.object,
    }).isRequired,
  }).isRequired,
};

export default RelatedContentSlice;
