import React from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

import { linkResolver } from '../helpers/linkResolver';
import * as bodyStyles from '../components/ui/Body/Body.module.scss';
import Grid from '../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../components/Layouts/Grids/GeneralGrid/GeneralGridItem';
import EmbeddedHubspotForm from '../components/Form/HubspotEmbeddedForm/HubspotEmbeddedForm';

const DownloadFormSlice = ({
  heading, description, hubspotFormId,
}) => (
  <div className={bodyStyles.content}>
    <Grid>
      <GridItem spanMd="7" spanLg="7" spanXLg="7">
        <h3>
          {/* eslint-disable-next-line camelcase */}
          {heading}
        </h3>
        <PrismicRichText field={description.richText} linkResolver={linkResolver} />
        {/* eslint-disable-next-line camelcase */}
        <EmbeddedHubspotForm hubspotFormId={hubspotFormId} />
      </GridItem>
    </Grid>
  </div>
);

DownloadFormSlice.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    richText: PropTypes.array,
  }),
  hubspotFormId: PropTypes.string,
};

DownloadFormSlice.defaultProps = {
  heading: null,
  description: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    richText: null,
  }),
  hubspotFormId: null,
};

export default DownloadFormSlice;
