/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Container from '../components/Layouts/Container';
import Grid from '../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../components/Layouts/Grids/GeneralGrid/GeneralGridItem';
import SiteLink from '../components/Link/SiteLink';
import { DownArrow } from '../components/ui/Icons/Icons';
import { linkResolver } from '../helpers/linkResolver';

import * as styles from './ImpactAreasSlice.module.scss';

const ImpactAreasSlice = ({
  input: { primary: { description, heading }, items },
}) => {
  const renderHubs = (hubs) => hubs.map((hub, index) => {
    const { document: { data, uid, type } } = hub;
    const impactTitle = data.impact_areas_name.text;
    let imgSrc = '';
    if (data.hub_shapes.localFile !== null
      && data.hub_shapes.localFile.publicURL !== null) {
      imgSrc = data.hub_shapes.localFile.publicURL;
    } else if (data.hub_shapes.url) {
      imgSrc = data.hub_shapes.url;
    }

    // From the top of the ImpactAreaSlice component -
    // it is needed to offset the image from the top as it is
    // absolutely positioned
    const topOffset = 350;

    const [imageVisible, setImageVisible] = useState(false);
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(topOffset);

    return (
      <li key={uid} className={styles.hub}>
        <div
          onMouseMove={(e) => {
            // Caters for the increased Y position of the mouse
            // as it moves down the list of items on the page
            const hubTextPositionOffset = (e.clientY / 100) * (index * 20);
            setMouseX((e.clientX / 10) - 62);
            setMouseY((topOffset + (e.clientY - hubTextPositionOffset) / 5) - 64);
          }}
          onMouseOver={() => {
            setImageVisible(true);
          }}
          onMouseLeave={() => { setImageVisible(false); }}
          onFocus={() => { setImageVisible(true); }}
          onBlur={() => { setImageVisible(false); }}
        >
          <SiteLink to={linkResolver({ type, uid })}>
            {impactTitle}
          </SiteLink>
        </div>
        <img
          className={imageVisible ? cx(styles.hubShapes, styles.showImage) : styles.hubShapes}
          src={imgSrc}
          alt=""
          style={{
            left: `${mouseX}px`,
            top: `${mouseY}px`,
          }}
        />
      </li>
    );
  });

  const hubs = items.map((item) => item.hub);

  return (
    <Container>
      <div id="impact-areas" className={styles.container}>
        <Grid>
          <GridItem
            span="12"
            className={styles.sectionHeading}
          >
            <h3 className={styles.sectionHeading__text}>{heading.text}</h3>
            <DownArrow className={styles.icon} />
          </GridItem>
          <GridItem
            key="description"
            span="12"
            spanMd="8"
            spanLg="3"
            offsetLg="2"
          >

            <p>{description.text}</p>
          </GridItem>
          <GridItem
            span="12"
            spanMd="12"
            spanLg="7"
          >
            <ul className={styles.hubs}>
              {renderHubs(hubs)}
            </ul>
          </GridItem>
        </Grid>
      </div>
    </Container>
  );
};

ImpactAreasSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      heading: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }).isRequired,
      description: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        hub: PropTypes.shape({
          uid: PropTypes.string,
          type: PropTypes.string,
          document: PropTypes.shape({
            id: PropTypes.string,
            data: PropTypes.shape({
              impact_areas_name: PropTypes.shape({
                text: PropTypes.string,
              }),
              hub_shapes: PropTypes.shape({
                localFile: PropTypes.shape({
                  publicURL: PropTypes.string,
                }),
              }),
            }).isRequired,
          }).isRequired,
        }),
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default ImpactAreasSlice;
