import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Quote.module.scss';

const Quote = ({ quoteText }) => (
  <blockquote className={styles.quote}>{quoteText}</blockquote>
);

Quote.propTypes = {
  quoteText: PropTypes.string.isRequired,
};

export default Quote;
