// extracted by mini-css-extract-plugin
export var BlackText = "Footer-module--BlackText--ItPDx";
export var HiddenLg = "Footer-module--HiddenLg--qMrCQ";
export var HiddenMd = "Footer-module--HiddenMd--4ysRT";
export var HiddenXl = "Footer-module--HiddenXl--n0SJf";
export var HiddenXs = "Footer-module--HiddenXs--5wgRK";
export var TextAlignRightXs = "Footer-module--TextAlignRightXs--7P3m7";
export var WhiteText = "Footer-module--WhiteText--9lVz9";
export var bcorp = "Footer-module--bcorp--cqBFM";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var desktopWidth = "960px";
export var footer = "Footer-module--footer--LZE0a";
export var footerContainer = "Footer-module--footerContainer--aqR0t";
export var footerGrid = "Footer-module--footerGrid--eiU5k";
export var footerGridItem = "Footer-module--footerGridItem--0Sehw";
export var indigenousAcknowledgement = "Footer-module--indigenousAcknowledgement--yCJnE";
export var indigenousFlags = "Footer-module--indigenousFlags--iHuyF";
export var lgBreakpoint = "60rem";
export var lgbtqiAcknowledgement = "Footer-module--lgbtqiAcknowledgement--Hj9LM";
export var lgbtqiFlag = "Footer-module--lgbtqiFlag--He5mi";
export var logo = "Footer-module--logo--Lu+fA";
export var mdBreakpoint = "40rem";
export var srOnly = "Footer-module--sr-only--6F7+K";
export var textBlock = "Footer-module--textBlock--8Jhsa";
export var visuallyHidden = "Footer-module--visually-hidden--uS-ix";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";