/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { isBefore } from 'date-fns';

import Container from '../components/Layouts/Container';
import Grid from '../components/Layouts/Grids/GeneralGrid/GeneralGrid';
import GridItem from '../components/Layouts/Grids/GeneralGrid/GeneralGridItem';
import Image from '../components/ui/Image/Image';
import SiteLink from '../components/Link/SiteLink';
import {
  Arch, Circle, Minus, Plus, RightAngle, SpeechBubble, DownArrow,
} from '../components/ui/Icons/Icons';

import { linkResolver } from '../helpers/linkResolver';
import * as styles from './WhatsHappeningSlice.module.scss';

const getItemInfo = (node) => {
  const itemInfo = {};
  if (node.id.includes('Greenhouse__Job')) {
    const jobImageInfo = {
      url: node.metadata[0].name === 'Thumbnail image' ? node.metadata[0].value : 'undefined',
    };
    itemInfo.id = node.id;
    itemInfo.title = node.title;
    itemInfo.imageSrc = jobImageInfo;
    itemInfo.date = node.updated_at.toUpperCase();
    itemInfo.url = `/careers/job-openings/job/${node.internal_job_id}`;
    itemInfo.subtext = 'Apply ->';
    itemInfo.itemType = 'CAREERS';
    itemInfo.typeLink = '/careers/job-openings';
    itemInfo.icon = <Circle />;
  } else { // its coming from prismic
    itemInfo.id = node.id;
    itemInfo.title = node.data.title;
    itemInfo.imageSrc = node.data.thumbnail_image || {};
    itemInfo.date = node.first_publication_date.toUpperCase();
    itemInfo.url = linkResolver({ type: node.type, uid: node.uid });

    switch (node.type) {
    case 'blog':
      itemInfo.itemType = 'ARTICLES';
      itemInfo.typeLink = '/articles';
      itemInfo.subtext = 'Read article ->';
      itemInfo.icon = <SpeechBubble />;
      break;
    case 'event':
      itemInfo.itemType = 'EVENTS';
      itemInfo.typeLink = '/events';
      itemInfo.subtext = 'Find out more ->';
      itemInfo.icon = <Arch />;
      break;
    case 'report':
      itemInfo.itemType = 'REPORTS';
      itemInfo.typeLink = '/reports';
      itemInfo.subtext = 'Get report ->';
      itemInfo.icon = <RightAngle />;
      break;
    default:
      itemInfo.itemType = '';
      itemInfo.typeLink = '/404';
    }
  }

  return itemInfo;
};

const WhatsHappeningSlice = ({ input: { primary } }) => (
  <StaticQuery
    query={graphql`{
      blogs: allPrismicBlog(sort: {fields: data___publish_date, order: DESC}, limit: 8) {
        edges {
          node {
            id
            uid
            type
            first_publication_date(formatString: "DD MMM YYYY")
            data {
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
            }
          }
        }
      }
      reports: allPrismicReport(sort: {fields: first_publication_date, order: DESC}, limit: 8)  {
        edges {
          node {
            id
            uid
            type
            first_publication_date(formatString: "DD MMM YYYY")
            data {
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
            }
          }
        }
      }
      events: allPrismicEvent(sort: {fields: first_publication_date, order: DESC}, limit: 8)  {
        edges {
          node {
            id
            uid
            type
            first_publication_date(formatString: "DD MMM YYYY")
            data {
              title
              start_date_time(formatString: "DD MMMM YYYY")
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
            }
          }
        }
      }
      jobs: allGreenhouseJob(sort: {fields: updated_at, order: DESC}, limit: 8) {
        edges {
          node {
            id
            internal_job_id
            title
            absolute_url
            updated_at(formatString: "DD MMM YYYY")
            metadata {
              name
              value
            }
          }
        }
      }
    }`}
    render={({
      blogs, reports, events, jobs,
    }) => {
      const allNewContent = [
        ...blogs.edges,
        ...reports.edges,
        ...events.edges,
        ...jobs.edges,
      ];

      const [isMoreVisible, setIsMoreVisible] = useState(false);

      const formattedData = allNewContent.map((item) => getItemInfo(item.node));
      formattedData.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        if (isBefore(aDate, bDate)) {
          return 1;
        }
        if (isBefore(bDate, aDate)) {
          return -1;
        }
        return 0;
      });

      const createItems = (contentItems) => contentItems.map((itemData) => (
        <GridItem
          key={itemData.id}
          span="12"
          spanMd="5"
          spanLg="3"
        >
          <p className={styles.date}>{itemData.date}</p>
          <div>
            <div className={styles.gridItemLink}>
              <SiteLink to={itemData.url} ariaLabel={itemData.title}>
                <div className={styles.aspectRatioBox}>
                  <div className={styles.aspectRatioBoxInside}>
                    <Image source={itemData.imageSrc} alt="" />
                  </div>
                </div>
              </SiteLink>
              <SiteLink to={itemData.typeLink}>
                <div className={styles.itemType}>
                  {itemData.icon}
                  <span>{itemData.itemType}</span>
                </div>
              </SiteLink>
              <div>
                <h4 className={styles.title}>{itemData.title}</h4>
              </div>
              <SiteLink to={itemData.url}>
                <p className={styles.subtext}>
                  {itemData.subtext}
                </p>
              </SiteLink>
            </div>
          </div>
        </GridItem>
      ));

      const firstFourContentItems = createItems(formattedData.slice(0, 4));
      const secondFourContentItems = createItems(formattedData.slice(4, 8));

      return (
        <Container>
          <div
            className={styles.sectionHeading}
          >
            <h3 className={styles.sectionHeading__text}>{primary.heading.text}</h3>
            <DownArrow className={styles.icon} />
          </div>
          <Grid>
            {firstFourContentItems}
          </Grid>
          <Grid className={isMoreVisible ? styles.visible : styles.hidden}>
            {secondFourContentItems}
          </Grid>

          <div className={styles.showMoreWrapper}>
            <button type="button" className={styles.showMore} onClick={() => setIsMoreVisible(!isMoreVisible)}>
              {isMoreVisible ? (
                <>
                  <h5>Show less</h5>
                  <Minus className={styles.icon} />
                </>
              ) : (
                <>
                  <h5>Show more</h5>
                  <Plus className={styles.icon} />
                </>
              )}
            </button>
          </div>
        </Container>
      );
    }}
  />
);

WhatsHappeningSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      heading: PropTypes.object,
    }).isRequired,
  }).isRequired,
};

export default WhatsHappeningSlice;
