import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import * as styles from './SymmetricGridItem.module.scss';
import SiteLink from '../../../Link/SiteLink';

const SymmetricGridItem = ({
  children,
  gridLink,
  linkText,
  date,
  tags,
  subtext,
  subtextLink,
  size,
  roundedBorder,
  smallDataSubtext,
  order,
  itemKey,
}) => {
  const LinkWrapper = gridLink ? SiteLink : React.Fragment;
  const Title = () => (typeof linkText === 'object'
    ? <linkText.type className={styles.title}>{linkText.props.children}</linkText.type>
    : <h2 className={styles.title}>{linkText}</h2>);

  return (
    <div data-cy="symmetric-grid-item" className={cx(styles.gridItem, styles[`gridItem${size}`], order && styles[`${order}`])} key={itemKey}>
      <div className={styles.gridItemLink}>
        <LinkWrapper to={gridLink}>
          <div className={cx(styles.image, { [styles.roundedBorder]: roundedBorder })}>
            {children}
          </div>
          <Title />

          {subtext && subtextLink === undefined && (
            <p className={cx(styles.subtext, { [styles.smallDataSubtext]: smallDataSubtext })}>
              {subtext}
            </p>
          )}

          {date && (
            <div>
              <p className={styles.date}>{date}</p>
              <p className={styles.tags}>{tags}</p>
            </div>
          )}
        </LinkWrapper>
      </div>

      {subtextLink !== undefined && (
        <p className={cx(styles.subtextLink, { [styles.smallDataSubtext]: smallDataSubtext })}>
          <SiteLink
            to={subtextLink}
          >
            {subtext}
          </SiteLink>
        </p>
      )}
    </div>
  );
};

SymmetricGridItem.propTypes = {
  children: PropTypes.node,
  gridLink: PropTypes.string,
  linkText: PropTypes.oneOfType([
    PropTypes.string, PropTypes.object,
  ]),
  date: PropTypes.string,
  tags: PropTypes.string,
  subtext: PropTypes.string,
  subtextLink: PropTypes.string,
  size: PropTypes.oneOf(['Third', 'Half', 'Twothirds', 'Full']),
  roundedBorder: PropTypes.bool,
  smallDataSubtext: PropTypes.bool,
  order: PropTypes.string,
  itemKey: PropTypes.string,
};

SymmetricGridItem.defaultProps = {
  children: null,
  gridLink: '',
  linkText: '',
  date: '',
  tags: '',
  subtext: '',
  size: 'Third',
  subtextLink: undefined,
  roundedBorder: false,
  smallDataSubtext: false,
  order: null,
  itemKey: Math.random().toString(36),
};

export default SymmetricGridItem;
