// extracted by mini-css-extract-plugin
export var BlackText = "Body-module--BlackText--XuzF5";
export var HiddenLg = "Body-module--HiddenLg--nBtao";
export var HiddenMd = "Body-module--HiddenMd--qhydV";
export var HiddenXl = "Body-module--HiddenXl--TyN8T";
export var HiddenXs = "Body-module--HiddenXs--wCwWa";
export var TextAlignRightXs = "Body-module--TextAlignRightXs--gM39M";
export var WhiteText = "Body-module--WhiteText--L68Dd";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var content = "Body-module--content--Fc0U8";
export var desktopWidth = "960px";
export var gridItem1 = "Body-module--gridItem1--DI+XX";
export var gridItem2 = "Body-module--gridItem2--SdbEp";
export var gridLayout = "Body-module--gridLayout--fxZnQ";
export var groupedContentItem = "Body-module--groupedContentItem--gTYdr";
export var heading = "Body-module--heading--eTaaO";
export var headingOnly = "Body-module--headingOnly--LPuIT";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var rowDescription = "Body-module--rowDescription--OQySE";
export var rowDescriptionH3 = "Body-module--rowDescriptionH3--pwZXm";
export var rowHeading = "Body-module--rowHeading--a9gBA";
export var singleColumn = "Body-module--singleColumn--o4QdB";
export var srOnly = "Body-module--sr-only--zzAlC";
export var visuallyHidden = "Body-module--visually-hidden--dvGey";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";