import React, { useContext } from 'react';

import PageData from '../../helpers/pageData';

import Stack from './Stack';
import ContactStrip, { HTMLBlock } from './ContactStrip';
import BCorpIconLink from './BCorpIconLink';
import AOC from './AOC';
import LGBTQ from './LGBTQ';

import useMediaSize from '../../helpers/useMediaSize';

import {
  Footer,
  FooterContainer,
  FooterGrid,
  FooterGridItem,
  Logo,
} from './FooterComponents';

const FooterSection = () => {
  const { contactBlocks } = useContext(PageData);
  const isDesktop = useMediaSize('desktop');
  const isTablet = useMediaSize('tablet');
  const isMobile = useMediaSize('mobile');

  const [
    melbourneStudioHTML,
    sydneyStudioHTML,
    canberraStudioHTML,
    contactInfoHTML,
  ] = contactBlocks;

  return (
    <Footer>
      <FooterContainer>
        {isDesktop && (
          <FooterGrid>
            <FooterGridItem>
              <Stack spaced={16}>
                <div>
                  <Logo />
                  <HTMLBlock data={melbourneStudioHTML} />
                  <HTMLBlock data={canberraStudioHTML} />
                </div>
                <BCorpIconLink />
              </Stack>
            </FooterGridItem>
            <FooterGridItem>
              <HTMLBlock data={sydneyStudioHTML} />
              <HTMLBlock data={contactInfoHTML} />
            </FooterGridItem>
            <FooterGridItem />
            <FooterGridItem>
              <Stack spaced={16}>
                <AOC />
                <LGBTQ />
              </Stack>
            </FooterGridItem>
          </FooterGrid>
        )}
        {((isTablet || isMobile) && (
          <Stack spaced={14}>
            <div>
              <Stack spaced={9}>
                <AOC />
                <LGBTQ />
              </Stack>
            </div>
            <div>
              <Stack spaced={8}>
                <Logo />
                <ContactStrip contactBlocks={contactBlocks} />
                <BCorpIconLink />
              </Stack>
            </div>
          </Stack>
        ))}
      </FooterContainer>
    </Footer>
  );
};

export default FooterSection;
