import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NotFoundPage from '../components/404';
import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import Body from '../components/ui/Body/Body';
import getPageContextFromData from '../lib/dto/getPageContextFromData';

const GenericPage = ({
  data: { prismicGenericPage: { type, uid, data } },
}) => {
  if (!data) return <NotFoundPage />;

  const dataContext = getPageContextFromData(type, uid, data);

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <Body />
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
query GenericPageBySlug($uid: StringQueryOperatorInput = {}) {
  prismicGenericPage(uid: $uid) {
    _previewable
    uid
    type
    data {
      introduction
      page_colour
      page_short_description
      prismic_page_name
      seo_description
      seo_title
      title
      tag {
        html
        text
      }
      thumbnail_image {
        localFile {
          publicURL
          extension
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED,
              quality: 100,
              width: 640,
              breakpoints: [640, 960, 1400]
            )
          }
        }
      }
      landscape_image {
        localFile {
          publicURL
          extension
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED,
              quality: 100,
              width: 1400,
              breakpoints: [640, 960, 1400]
            )
          }
        }
      }
      process_tags {
        processes {
          slug
          type
        }
      }
      purpose_tags {
        purposes {
          type
          slug
        }
      }
      service_tags {
        services {
          type
          slug
        }
      }
      body {
        ... on PrismicGenericPageDataBodyAccordion {
          id
          slice_type
          items {
            heading {
              html
              richText
              text
            }
            link {
              id
              link_type
              raw
              url
              uid
            }
            link_text {
              html
              richText
              text
            }
            text {
              richText
              text
              html
            }
          }
          primary {
            heading {
              html
              richText
              text
            }
          }
          slice_type
        }
        ... on PrismicGenericPageDataBodyActionButton {
          id
          slice_type
          primary {
            layout
            button_text {
              text
            }
            button_url {
              id
              link_type
              isBroken
              type
              uid
              url
              target
            }
          }
        }
        ... on PrismicGenericPageDataBodyAlternatingMediaText {
          id
          slice_type
          items {
            list_description {
              html
              richText
            }
            list_heading {
              html
              richText
              text
            }
          }
          primary {
              counter
              tag1 {
                text
              }
            content {
              text
            }
            heading {
              text
              richText
            }
            image {
              alt
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    quality: 100,
                    width: 1400,
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
          }
          slice_type
        }
        ... on PrismicGenericPageDataBodyAnchorNavigation {
          id
          slice_type
          slice_label
          items {
            slice_id {
              text
            }
            navigation_label {
              text
            }
          }
        }
        ... on PrismicGenericPageDataBodyBanner {
          id
          slice_type
          primary {
            background_colour
            text_colour
            big_text {
              richText
              text
            }
            text_link {
              richText
              text
            }
          }
        }
        ... on PrismicGenericPageDataBodyCaseStudies {
          id
          slice_type
          slice_label
          items {
            featured_case_study {
              id
              uid
              type
              url
              document {
                ... on PrismicCaseStudy {
                  id
                  uid
                  type
                  data {
                    title
                    thumbnail_image {
                      alt
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          primary {
            items_to_show
            case_studies_title {
              html
              text
              richText
            }
          }
        }
        ... on PrismicGenericPageDataBodyCardsGrid {
          id
          slice_type
          primary {
            section_title {
              text
            }
            section_introduction {
              richText
              text
            }
            open_by_default
          }
          items {
            title {
              richText
              text
            }
            description {
              richText
              text
            }
            card_link {
              url
              uid
              target
            }
            call_to_action {
              richText
              text
            }
            image {
              url
              alt
            }
          }
        }
        ... on PrismicGenericPageDataBodyContent {
          id
          slice_type
          primary {
            content_heading {
              richText
              text
            }
            content_subtitle {
              richText
              text
            }
            content_text {
              richText
              text
            }
            content_button_text {
              text
            }
            content_button_url {
              raw
            }
            layout
          }
        }
        ... on PrismicGenericPageDataBodyDivider {
          id
          slice_type
        }
        ... on PrismicGenericPageDataBodyGetTicketsCard {
          id
          slice_type
          slice_label
          primary {
            title1 {
              text
            }
            tag {
              text
            }
            description {
              richText
            }
          }
          items {
            session_tag {
              text
            }
            session_title {
              text
            }
            session_description {
              richText
            }
            amount {
              text
            }
            date_and_time {
              richText
            }
            button_link {
              url
              uid
              target
            }
            button_text {
              text
            }
            session_information_list {
              richText
            }
          }
        }
        ... on PrismicGenericPageDataBodyHsEmbeddedForm {
          id
          slice_type
          primary {
            form_title
            description {
              richText
            }
            hubspot_form_id
          }
        }
        ... on PrismicGenericPageDataBodyImage {
          id
          slice_type
          primary {
            alignment
            image {
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    quality: 100,
                    width: 1400,
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
          }
        }
        ... on PrismicGenericPageDataBodyImageAndContent {
          id
          slice_type
          slice_label
          primary {
            description {
              html
              richText
              text
            }
            image {
              alt
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    quality: 100,
                    width: 1400,
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
            link_text{
              text
            }
            link {
              url
              uid
              target
            }
          }
        }
        ... on PrismicGenericPageDataBodyList {
          id
          slice_type
          primary {
            list_heading {
              text
            }
            list_tag {
              html
              text
            }
            subtext {
              html
              text
            }
          }
          items {
            list_items {
              document {
                ... on PrismicListItem {
                  id
                  data {
                    heading {
                      text
                    }
                    description {
                      text
                      richText
                    }
                    icon {
                      alt
                      url
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicGenericPageDataBodyQuote {
          id
          slice_type
          primary {
            quote {
              richText
              text
            }
          }
        }
        ... on PrismicGenericPageDataBodyRelatedContent {
          id
          slice_type
          primary {
            items_to_show
            related_content_title {
              text
            }
          }
        }
        ... on PrismicGenericPageDataBodyRelatedLinksCards {
          id
          slice_type
          primary {
            heading {
              text
            }
            CTA_text {
              text
            }
            CTA_url {
              url
              uid
              target
            }
          }
          items {
            related_content {
              document {
                ... on PrismicBlog {
                  id
                  uid
                  type
                  data {
                    title
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                    introduction
                  }
                }
                ... on PrismicCaseStudy {
                  id
                  uid
                  type
                  data {
                    title
                    introduction
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                  }
                }
                ... on PrismicReport {
                  id
                  uid
                  type
                  data {
                    title
                    introduction
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicGenericPageDataBodyServicesList {
          id
          slice_type
          slice_label
          primary {
            heading {
              html
              text
            }
            service_tag {
              text
            }
            service_desc {
              text
            }
          }
          items {
            service_description {
              html
              text
            }
            service_link {
              url
              uid
              link_type
              id
              isBroken
            }
            service_title {
              html
              text
              richText
            }
          }
        }
        ... on PrismicGenericPageDataBodyServicePostHero {
          id
          slice_type
          slice_label
          primary {
            tag {
              html
              text
            }
            heading {
              html
              text
            }
            content {
              html
              text
            }
          }
        }
        ... on PrismicGenericPageDataBodyTagAndContent {
          id
          slice_type
          slice_label
          primary {
            tag1 {
              text
            }
            heading {
              text
            }
            description {
              richText
            }
          }
        }
        ... on PrismicGenericPageDataBodyTeamMembers {
          id
          slice_type
          primary {
            heading {
              text
            }
            sort
          }
          items {
            team_member {
              document {
                ... on PrismicTeamMember {
                  id
                  data {
                    name
                    title
                  }
                }
              }
            }
          }
        }
        ... on PrismicGenericPageDataBodyValues {
          id
          slice_type
          primary {
            heading {
              text
            }
          }
          items {
            value {
              document {
                ... on PrismicValue {
                  id
                  data {
                    name {
                      text
                    }
                    description {
                      richText
                      text
                    }
                    image {
                      alt
                      localFile {
                        publicURL
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicGenericPageDataBodyVideo {
          id
          slice_type
          primary {
            alignment
            video_embeded {
              embed_url
            }
          }
        }
        ... on PrismicGenericPageDataBodyContentAndCards {
          id
          slice_type
          primary {
            title1 {
              text
            }
            description {
              richText
            }
            button_link {
              url
              uid
            }
            button_label {
              text
            }
          }
          items {
            tag1 {
              text
            }
            image {
              alt
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    quality: 100
                    width: 142
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
            description {
              text
            }
          }
        }
        ... on PrismicGenericPageDataBodyProductCaseStudies {
          id
          slice_type
          primary {
            background_colour
            text_colour
            title {
              text
            }
            product_image {
              alt
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    quality: 100
                    width: 560
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
            partner_image {
              alt
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    quality: 100
                    width: 160
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
            name {
              text
            }
            introduction {
              richText
            }
            link {
              url
            }
          }
        }
        ... on PrismicGenericPageDataBodyImageAndContentCarousel {
          id
          slice_type
          items {
            title1 {
              text
            }
            image {
              alt
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    quality: 100
                    width: 318
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
            description {
              text
            }
            author {
              text
            }
          }
        }
      }
    }
  }
}`;

GenericPage.propTypes = {
  data: PropTypes.shape({
    prismicGenericPage: PropTypes.object.isRequired,
  }).isRequired,
};

export default withPrismicPreview(GenericPage);
