/* eslint-disable camelcase */
const getActionButtonSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary: { layout, button_text, button_url } } = sliceData;
  const text = button_text?.text?.trim() === '' ? undefined : button_text?.text?.trim();
  let url;

  // if the url exists and it's not broken, create the formatted url
  const isWorkingUrl = button_url.url !== null && !button_url.isBroken;
  if (isWorkingUrl) {
    url = {
      isExternal: button_url.link_type !== 'Document',
      ...button_url,
    };
  }

  return {
    layout,
    text,
    url,
  };
};

export default getActionButtonSliceProps;
