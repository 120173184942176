import * as variables from './helpers/_variables.module.scss';

export const BLACK_THEME = {
  '--white': variables.white,
  '--black': variables.black,
  '--backgroundColour': variables.black,
  '--backgroundColourHSL': variables.blackHSL,
  '--textColour': variables.white,
  '--textColourHSL': variables.whiteHSL,
  '--buttonTextColour': variables.black,
  '--buttonBackground': variables.white,
  '--mobileMenuBackground': variables.white,
  '--mobileMenuTextColour': variables.black,
  '--lightTextColour': variables.white,
};

export const WHITE_THEME = {
  '--white': variables.white,
  '--black': variables.black,
  '--backgroundColour': variables.white,
  '--backgroundColourHSL': variables.whiteHSL,
  '--textColour': variables.black,
  '--textColourHSL': variables.blackHSL,
  '--buttonTextColour': variables.white,
  '--buttonBackground': variables.black,
  '--mobileMenuBackground': variables.black,
  '--mobileMenuTextColour': variables.white,
  '--lightTextColour': variables.black,
};

export const INDUSTRIES = [
  'Academic',
  'Aged care ',
  'Agriculture',
  'Airline and airport operatations',
  'Animal care',
  'Arts and culture',
  'Carer',
  'Community service',
  'Construction',
  'Consultancy',
  'Design',
  'Education',
  'Ethical business',
  'Fashion',
  'Federal government',
  'Finance',
  'Food',
  'Health care',
  'Hospitality',
  'Indigenous organisations and services',
  'International development',
  'IT and technology',
  'Legal Services',
  'Local government',
  'Manufacturing',
  'Media',
  'Non applicable',
  'Not for profit',
  'Other',
  'Police and prison services',
  'Primary industries',
  'Private industry',
  'Public transport',
  'Publishing',
  'Retail',
  'Self-employed',
  'Sports',
  'State government',
  'Student',
  'Telecommunications',
  'Tourism',
];
