/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NotFoundPage from '../components/404';
import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import Body from '../components/ui/Body/Body';
import getPageContextFromData from '../lib/dto/getPageContextFromData';

const Hub = ({
  data: { prismicHub: { type, uid, data } },
}) => {
  if (!data) return <NotFoundPage />;

  const dataContext = getPageContextFromData(type, uid, data);

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <Body />
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
query HubBySlug($uid: StringQueryOperatorInput = {}) {
  prismicHub(uid: $uid) {
    _previewable
    uid
    type
    data {
      introduction
      page_colour
      page_short_description
      prismic_page_name
      seo_description
      seo_title
      title
      thumbnail_image {
        localFile {
          publicURL
          extension
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED,
              quality: 100,
              width: 640,
              breakpoints: [640, 960, 1400]
            )
          }
        }
      }
      landscape_image {
        localFile {
          publicURL
          extension
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED,
              quality: 100,
              width: 1400,
              breakpoints: [640, 960, 1400]
            )
          }
        }
      }
      body {
        ... on PrismicHubDataBodyContent {
          id
          slice_type
          primary {
            content_heading {
              text
            }
            content_subtitle {
              text
              richText
            }
            content_text {
              richText
            }
            content_button_text {
              text
            }
            content_button_url {
              raw
            }
            layout
          }
        }
        ... on PrismicHubDataBodyImage {
          id
          slice_type
          primary {
            alignment
            image {
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    quality: 100,
                    width: 1400,
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
          }
        }
        ... on PrismicHubDataBodyDivider {
          id
          slice_type
        }
        ... on PrismicHubDataBodyServicesSection {
          id
          slice_type
          primary {
            heading {
              html
              text
            }
            image_caption_heading {
              html
              text
            }
            image_caption_description {
              richText
              text
            }
            image {
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    quality: 100,
                    width: 1400,
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
          }
          items {
            service {
              document {
                ... on PrismicServicePost {
                  uid
                  data {
                    introduction
                    title
                  }
                }
              }
            }
          }
        }
        ... on PrismicHubDataBodyHeroLink {
          id
          slice_type
          primary {
            ...HubBodyHeroLink
          }
        }
        ... on PrismicHubDataBodyExternalLinks {
          id
          slice_type
          primary {
            heading {
              html
              text
            }
          }
          items {
            link_date
            link_description {
              richText
              text
            }
            link_heading {
              text
            }
            link_url {
              url
            }
          }
        }
        ... on PrismicHubDataBodyRelatedSubjectMatter {
          id
          slice_type
          primary {
            heading {
              text
            }
          }
          items {
            related_content {
              document {
                ... on PrismicBlog {
                  id
                  uid
                  type
                  data {
                    title
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                    introduction
                  }
                }
                ... on PrismicCaseStudy {
                  id
                  uid
                  type
                  data {
                    title
                    introduction
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                  }
                }
                ... on PrismicReport {
                  id
                  uid
                  type
                  data {
                    title
                    introduction
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicHubDataBodyHubCollection {
          id
          slice_type
          __typename
          primary {
            items_to_show
          }
          items {
            featured_content {
              id
              uid
              type
              document {
                ... on PrismicBlog {
                  id
                  uid
                  type
                  data {
                    title
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                  }
                }
                ... on PrismicCaseStudy {
                  id
                  uid
                  type
                  data {
                    title
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                  }
                }
                ... on PrismicEvent {
                  id
                  uid
                  type
                  data {
                    title
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                  }
                }
                ... on PrismicReport {
                  id
                  uid
                  type
                  data {
                    title
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                  }
                }
                ... on PrismicServicePost {
                  id
                  uid
                  type
                  data {
                    title
                    thumbnail_image {
                      localFile {
                        publicURL
                        extension
                        childImageSharp {
                          gatsbyImageData(
                            layout: CONSTRAINED,
                            quality: 100,
                            width: 640,
                            breakpoints: [640, 960, 1400]
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicHubDataBodyDownloadFormHs {
          id
          slice_type
          primary {
            form_title
            hubspot_form_id
            form_image {
              localFile {
                publicURL
                extension
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED,
                    quality: 100,
                    width: 1400,
                    breakpoints: [640, 960, 1400]
                  )
                }
              }
            }
          }
        }
        ... on PrismicHubDataBodyActionButton {
          id
          slice_type
          primary {
            layout
            button_text {
              text
            }
            button_url {
              id
              link_type
              isBroken
              type
              uid
              url
              target
            }
          }
        }
        ... on PrismicHubDataBodyQuoteCarousel {
          id
          slice_type
          items {
            quote_date {
              text
            }
            quote_text {
              text
            }
            quotee {
              text
            }
          }
        }
        ... on PrismicHubDataBodyAccordion {
          id
          slice_type
          primary {
            heading {
              text
            }
          }
          items {
            heading {
              text
            }
            link {
              raw
            }
            link_text {
              text
            }
            text {
              richText
            }
          }
        }
      }
    }
  }
}`;

Hub.propTypes = {
  data: PropTypes.shape({
    prismicHub: PropTypes.object.isRequired,
  }).isRequired,
};

export default withPrismicPreview(Hub);
