const getCodeAndContentSliceProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary, items } = sliceData;
  const { layout } = primary;
  const heading = primary.content_heading?.text?.trim() === '' ? undefined : primary.content_heading?.text?.trim();
  const subtitle = primary.content_subtitle?.text?.trim() === '' ? undefined : primary.content_subtitle?.text?.trim();

  // filter out any content items that don't have any content in them
  // then format the data for each content item
  const contentItems = items.filter((item) => {
    if (item.content_or_code_section?.text?.trim() === '') {
      return false;
    }
    return true;
  }).map((item) => ({
    // code component prism formatter needs language in lowercase
    contentFormat: item.content_format.toLowerCase(),
    content: { ...item.content_or_code_section },
  }));

  return {
    layout,
    heading,
    subtitle,
    contentItems,
  };
};

export default getCodeAndContentSliceProps;
