/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import Banner from '../components/ui/Banner/Banner';

const BannerSlice = (props) => {
  const {
    input: {
      primary: {
        big_text,
        text_link,
        text_colour,
        background_colour,
      },
    },
  } = props;

  return !big_text ? null : (
    <Banner
      bigText={big_text}
      textLink={text_link}
      textColour={text_colour}
      backgroundColour={background_colour}
    />
  );
};

BannerSlice.propTypes = {
  input: PropTypes.shape({
    primary: PropTypes.shape({
      big_text: PropTypes.object,
      text_link: PropTypes.object,
      text_colour: PropTypes.string,
      background_colour: PropTypes.string,
    }),
  }).isRequired,
};

export default BannerSlice;
