import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './HubspotEmbeddedForm.module.scss';

import { waitForThen } from '../../../helpers/helpers';

const HubspotEmbeddedForm = ({ hubspotFormId }) => {
  const embedHubspotForm = () => {
    // the hbspt form should be available on the window object due to
    // the script tag that is run on load of the page in the useEffect
    window.hbspt.forms.create({
      region: process.env.GATSBY_HUBSPOT_REGION,
      portalId: process.env.GATSBY_HUBSPOT_ACCOUNT_ID,
      formId: hubspotFormId,
      target: '#hubspotEmbeddedForm',
    });
  };

  React.useEffect(() => {
    if (!window) return;
    const existingScript = document.getElementById('hubspot');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/v2.js';
      script.id = 'hubspot';
      document.body.appendChild(script);
    }
    waitForThen(() => (window.hbspt !== undefined), embedHubspotForm);
  }, [hubspotFormId]);

  return (
    <div
      id="hubspotEmbeddedForm"
      className={styles.hubspotEmbeddedForm}
    />
  );
};

HubspotEmbeddedForm.propTypes = {
  hubspotFormId: PropTypes.string.isRequired,
};

export default HubspotEmbeddedForm;
