import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './ColumnsList.module.scss';
import Container from '../Container';
import Heading from '../../ui/Typography/Heading';

const ColumnsList = ({ listItems, listHeading }) => {
  const createColumns = () => {
    const formattedList = listItems.map((item, index) => {
      if (!item) return null;
      return (
        <div className={styles.pointsListItem} key={index}>
          {item}
        </div>
      );
    });

    return formattedList;
  };

  return (
    <Container>
      <Heading>
        <h2>{listHeading}</h2>
      </Heading>
      <div className={styles.threeColumns}>
        {createColumns()}
      </div>
    </Container>
  );
};

ColumnsList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  listItems: PropTypes.array.isRequired,
  listHeading: PropTypes.string,
};

ColumnsList.defaultProps = {
  listHeading: '',
};

export default ColumnsList;
