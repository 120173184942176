/* eslint-disable react/prop-types */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FilterableCardListSlice from './FilterableCardListSlice';
import {
  sortCaseStudiesByPublicationDate,
  pluckTagsFromCaseStudy,
  pluckTagsFromBlog,
} from '../../helpers/helpers';
import SymmetricGrid from '../../components/Layouts/Grids/SymmetricGrid/SymmetricGrid';
import SymmetricGridItem from '../../components/Layouts/Grids/SymmetricGrid/SymmetricGridItem';
import { linkResolver } from '../../helpers/linkResolver';
import Image from '../../components/ui/Image/Image';

const getGridItems = (gridItem) => {
  if (gridItem === undefined || !gridItem.data) return null;

  const linkText = gridItem.data.title;
  const subtext = gridItem.type === 'blog' ? 'Article' : 'Case Study';
  const subtextLink = gridItem.type === 'blog' ? '/articles' : '/work';

  return (
    <SymmetricGridItem
      key={gridItem.id}
      gridLink={linkResolver({ type: gridItem.type, uid: gridItem.uid })}
      linkText={linkText}
      subtext={subtext.toUpperCase()}
      subtextLink={subtextLink}
      roundedBorder
      smallDataSubtext
    >
      <Image
        source={gridItem.data.thumbnail_image || {}}
        alt={gridItem.data.thumbnail_image.alt || ''}
      />
    </SymmetricGridItem>
  );
};

const JusticeHubGridItems = ({ items, filters }) => {
  if (items === undefined) return null;

  // Trim the visible items to 9 unless there are filters selected.
  // This is done so that the user doesn't have to scroll forever to see the rest of the page.
  const itemsVisible = Object.keys(filters).length === 0 ? items.slice(0, 9) : items;

  return (
    <>
      {itemsVisible.length === 0 && (
        <>
          <h3>No items match your filter selection.</h3>
          <h4>Please try another filter.</h4>
        </>
      )}

      {itemsVisible.length > 0 && <SymmetricGrid>{itemsVisible.map(getGridItems)}</SymmetricGrid>}
    </>
  );
};

const JusticeHubCaseStudiesQuery = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicCaseStudy(
        sort: { fields: first_publication_date, order: DESC }
        filter: {
          data: { purpose_tags: { elemMatch: { purposes: { slug: { eq: "access-to-justice" } } } } }
        }
      ) {
        edges {
          node {
            id
            uid
            type
            first_publication_date
            data {
              publication_date
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              landscape_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 1400,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              service_tags {
                services {
                  type
                  document {
                    ... on PrismicServiceCategory {
                      id
                      data {
                        service
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allPrismicServiceCategory {
        nodes {
          data {
            service
          }
        }
      }
      allPrismicBlog(
        sort: { fields: first_publication_date, order: DESC }
        filter: {
          data: {
            purpose_tags: { elemMatch: { purposes: { id: {}, slug: { eq: "access-to-justice" } } } }
          }
        }
      ) {
        edges {
          node {
            id
            uid
            type
            first_publication_date
            data {
              publish_date
              title
              thumbnail_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 640,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              landscape_image {
                localFile {
                  publicURL
                  extension
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      quality: 100,
                      width: 1400,
                      breakpoints: [640, 960, 1400]
                    )
                  }
                }
              }
              service_tags {
                services {
                  type
                  document {
                    ... on PrismicServiceCategory {
                      id
                      data {
                        service
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const caseStudyEdges = data.allPrismicCaseStudy.edges;
  const blogEdges = data.allPrismicBlog.edges;
  const services = data.allPrismicServiceCategory.nodes;

  // extract initial arrays of all case studies and hubs from prismic query
  const allCaseStudies = sortCaseStudiesByPublicationDate(caseStudyEdges.map((n) => n.node)).map(
    pluckTagsFromCaseStudy,
  );
  const allBlogPosts = blogEdges.map((n) => n.node).map(pluckTagsFromBlog);

  const allItems = allCaseStudies.concat(allBlogPosts);

  // We want services, but only those that actually apply to
  // Justice related case studies. As we have this additional initial filter
  // if we don't do this then it can become a bit empty and get
  // "No matching" very easily.
  const allUsedServiceTags = allItems.reduce((acc, curr) => {
    const serviceTags = curr?.tags?.services || [];
    return [...acc, ...serviceTags];
  }, []);

  const allServices = services
    .map((s) => s.data.service)
    .filter((s) => allUsedServiceTags.includes(s));

  const selectedFilters = ['services'];

  const slug = 'justice';

  return (
    <FilterableCardListSlice
      allItems={allItems}
      getGridItems={getGridItems}
      allServices={allServices}
      selectedFilters={selectedFilters}
      slug={slug}
    >
      {/* https://codedaily.io/tutorials/Using-Functions-as-Children-and-Render-Props-in-React-Components */}
      {({ items, filters }) => <JusticeHubGridItems items={items} filters={filters} />}
    </FilterableCardListSlice>
  );
};

export default JusticeHubCaseStudiesQuery;
