/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NotFoundPage from '../components/404';
import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import SliceZone from '../components/Layouts/SliceZone';
import Container from '../components/Layouts/Container';
import SymmetricGrid from '../components/Layouts/Grids/SymmetricGrid/SymmetricGrid';
import SymmetricGridItem from '../components/Layouts/Grids/SymmetricGrid/SymmetricGridItem';
import Image from '../components/ui/Image/Image';
import getPageContextFromData from '../lib/dto/getPageContextFromData';

const JobOpenings = ({
  data: { prismicJobOpenings: { type, uid, data }, allGreenhouseJob: { edges } },
}) => {
  if (!data) return <NotFoundPage />;

  const dataContext = getPageContextFromData(type, uid, data);

  const jobs = edges.map(({ node: { title, internal_job_id, metadata } }) => {
    const jobImageInfo = {
      url: metadata[0].name === 'Thumbnail image' ? metadata[0].value : 'undefined',
    };

    return (
      <SymmetricGridItem
        key={title}
        gridLink={`/careers/job-openings/job/${internal_job_id}`}
        target="_blank"
        linkText={title}
        roundedBorder
      >
        <Image
          source={jobImageInfo}
          alt=""
        />
      </SymmetricGridItem>
    );
  });

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <SliceZone allSlices={data.body} />
        <Container>
          <SymmetricGrid>
            {jobs}
          </SymmetricGrid>
        </Container>
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
query JobOpeningsBySlug($uid: StringQueryOperatorInput = {}) {
  prismicJobOpenings(uid: $uid) {
    _previewable
    uid
    type
    data {
      introduction
      page_colour
      page_short_description
      prismic_page_name
      seo_description
      seo_title
      title
      body {
        ... on PrismicJobOpeningsDataBodyContent {
          id
          slice_type
          primary {
            content_heading {
              richText
              text
            }
            content_subtitle {
              richText
              text
            }
            content_text {
              richText
              text
            }
            layout
          }
        }
      }
    }
  }
  allGreenhouseJob(sort: {fields: updated_at, order: DESC}) {
    edges {
      node {
        id
        internal_job_id
        title
        absolute_url
        metadata {
          name
          value
        }
        content
        location {
          name
        }
      }
    }
  }
}`;

JobOpenings.propTypes = {
  data: PropTypes.shape({
    prismicJobOpenings: PropTypes.object.isRequired,
    allGreenhouseJob: PropTypes.object,
  }).isRequired,
};

export default withPrismicPreview(JobOpenings);
