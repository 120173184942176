/* eslint-disable max-len */
import { linkResolver } from '../../helpers/linkResolver';

const getServiceSectionProps = (sliceData) => {
  if (sliceData === null) return null;
  const { primary } = sliceData;
  const heading = primary.heading?.text?.trim() || null;

  let tag = null;
  tag = primary.service_tag !== null ? primary.service_tag?.text?.trim() || null : null;

  let serviceDescription = null;
  serviceDescription = primary.service_desc !== null ? primary.service_desc?.text?.trim() || null : null;

  const visual = primary?.visual;

  const items = sliceData.items.map((item) => {
    const serviceItemTitle = item.service_title?.text?.trim() || null;
    const serviceItemDescription = item.service_description?.text || null;
    let serviceItemLink = null;
    // Check if there is link text and url and assign them to readable variables
    if (item.service_title && item.service_link) {
      const isExternal = item.service_link.link_type !== 'Document';
      serviceItemLink = isExternal
        ? item.service_link.url
        : linkResolver({
          type: item.service_link.type,
          uid: item.service_link.uid,
        });
    }
    return {
      heading,
      visual,
      tag,
      serviceDescription,
      serviceItemTitle,
      serviceItemLink,
      serviceItemDescription,
    };
  });

  return {
    heading,
    visual,
    items,
    tag,
    serviceDescription,
  };
};

export default getServiceSectionProps;
