// extracted by mini-css-extract-plugin
export var BlackText = "RelatedLinksCards-module--BlackText--3PHs-";
export var HiddenLg = "RelatedLinksCards-module--HiddenLg--Ws8ka";
export var HiddenMd = "RelatedLinksCards-module--HiddenMd--cMHrm";
export var HiddenXl = "RelatedLinksCards-module--HiddenXl--CrSIG";
export var HiddenXs = "RelatedLinksCards-module--HiddenXs--NDYWi";
export var TextAlignRightXs = "RelatedLinksCards-module--TextAlignRightXs--F5R2t";
export var WhiteText = "RelatedLinksCards-module--WhiteText--BZqxa";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var cta = "RelatedLinksCards-module--cta--v353F";
export var desktopWidth = "960px";
export var header = "RelatedLinksCards-module--header--pb1B6";
export var heading = "RelatedLinksCards-module--heading--M3p0Y";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "RelatedLinksCards-module--sr-only--Nh3yB";
export var title = "RelatedLinksCards-module--title----KBC";
export var visuallyHidden = "RelatedLinksCards-module--visually-hidden--j8WNr";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";