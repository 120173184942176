import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as styles from './Container.module.scss';

const Container = ({
  nonCssModuleClass,
  className,
  shallow,
  backgroundImageSrc,
  children,
}) => {
  const classes = cx(styles.container, {
    [styles.containerShallow]: shallow,
    [styles[className]]: className,
    [nonCssModuleClass]: nonCssModuleClass,
  });
  return (
    <div
      className={classes}
      style={{
        background: backgroundImageSrc
          ? ` url( + ${backgroundImageSrc} + )`
          : '',
      }}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  nonCssModuleClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  backgroundImageSrc: PropTypes.string,
  shallow: PropTypes.bool,
};

Container.defaultProps = {
  nonCssModuleClass: '',
  className: '',
  backgroundImageSrc: '',
  shallow: false,
  children: null,
};

export default Container;
