/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './HeroLink.module.scss';
import { linkResolver } from '../../helpers/linkResolver';
import Image from '../ui/Image/Image';
import SiteLink from './SiteLink';

const HeroLink = ({
  linkInfo, image, alt, title, tag,
}) => {
  if (!linkInfo) {
    return null;
  }

  return (
    <section className={styles.linkWrapper}>
      <SiteLink to={linkResolver(linkInfo)}>
        <div className={styles.image}>
          <Image
            source={image}
            alt={alt}
            style={{
              width: '100%',
            }}
          />
        </div>
        <div className={styles.contentWrapper}>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.subtext}>{tag}</p>
        </div>
      </SiteLink>
    </section>
  );
};

HeroLink.propTypes = {
  linkInfo: PropTypes.object.isRequired,
  image: PropTypes.object,
  alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
};

HeroLink.defaultProps = {
  alt: null,
  image: {},
};

export default HeroLink;
