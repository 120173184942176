import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import NotFoundPage from './404';
import PageData from '../helpers/pageData';
import Layout from '../components/Layouts/Layout';
import Body from '../components/ui/Body/Body';
import getPageContextFromData from '../lib/dto/getPageContextFromData';

const ServicesPage = ({
  data: { prismicServicesPage: { type, uid, data } },
}) => {
  if (!data) return <NotFoundPage />;

  const dataContext = getPageContextFromData(type, uid, data);

  return (
    <PageData.Provider value={dataContext}>
      <Layout>
        <Body />
      </Layout>
    </PageData.Provider>
  );
};

export const query = graphql`
{
  prismicServicesPage {
    _previewable
    uid
    type
    id
    data {
      introduction
      page_colour
      page_short_description
      prismic_page_name
      seo_description
      seo_title
      title
      body {
        ... on PrismicServicesPageDataBodyContent {
          id
          slice_type
          primary {
            content_heading {
              html
              text
            }
            content_subtitle {
              richText
              html
              text
            }
            content_text {
              richText
              text
            }
          }
        }
        ... on PrismicServicesPageDataBodyCollection {
          id
          slice_type
          primary {
            collection_heading {
              text
            }
            collection_subtext {
              text
            }
          }
          items {
            squares {
              document {
                ... on PrismicServiceCategory {
                  id
                  data {
                    service
                  }
                }
              }
            }
          }
        }
        ... on PrismicServicesPageDataBodyActionButton {
          id
          slice_type
          primary {
            layout
            button_text {
              text
            }
            button_url {
              id
              link_type
              isBroken
              type
              uid
              url
              target
            }
          }
        }
        ... on PrismicServicesPageDataBodyServicesHero {
          id
          slice_type
          primary {
            header {
              text
            }
          }
        }
        ... on PrismicServicesPageDataBodyServicesList {
          id
          slice_type
          primary {
            heading {
              text
            }
            service_tag {
              text
            }
            service_desc {
              text
            }
            visual {
              alt
              url
            }
          }
          items {
            service_title {
              text
            }
            service_link {
              raw
              link_type
              type
              uid
              url
            }
            service_description {
              text
            }
          }
        }
      }
    }
  }
}
`;

ServicesPage.propTypes = {
  data: PropTypes.shape({
    prismicServicesPage: PropTypes.object.isRequired,
  }).isRequired,
};

export default withPrismicPreview(ServicesPage);
