/* eslint-disable react/prop-types */
import React from 'react';

// Tip: Convert from SVG to JSX using https://transform.tools/

export const Arch = (props) => (
  <svg width={28} height={24} viewBox="0 0 28 24" fill="none" {...props}>
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5C6.268 5 0 11.268 0 19h7a7 7 0 1114 0h7c0-7.732-6.268-14-14-14z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path
          fill="currentColor"
          transform="matrix(0 -1 -1 0 28 24)"
          d="M0 0H24V28H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Circle = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx={12} cy={12} r={9} fill="currentColor" />
  </svg>
);

export const Minus = (props) => (
  <svg width={19} height={19} viewBox="0 0 19 19" fill="none" {...props}>
    <path
      transform="rotate(-90 0 10.75)"
      fill="currentColor"
      d="M0 10.75H2.75V29.5H0z"
    />
  </svg>
);

export const Plus = (props) => (
  <svg width={19} height={19} viewBox="0 0 19 19" fill="none" {...props}>
    <path
      transform="rotate(-180 10.75 18.75)"
      fill="currentColor"
      d="M10.75 18.75H13.5V37.5H10.75z"
    />
    <path
      transform="rotate(-90 0 10.75)"
      fill="currentColor"
      d="M0 10.75H2.75V29.5H0z"
    />
  </svg>
);
export const DownArrow = (props) => (
  <svg viewBox="14 13 21 21" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 28.438V13h3v15.438l6.952-6.79 2.096 2.146L24.5 34.097 13.952 23.794l2.096-2.146L23 28.438z"
      fill="currentColor"
    />
  </svg>
);

export const RightAngle = (props) => (
  <svg width={28} height={24} viewBox="0 0 28 24" fill="none" {...props}>
    <path transform="rotate(90 24 2)" fill="currentColor" d="M24 2H32V22H24z" />
    <path transform="rotate(90 12 2)" fill="currentColor" d="M12 2H32V10H12z" />
  </svg>
);

export const SpeechBubble = (props) => (
  <svg width={24} height={28} viewBox="0 0 24 28" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.92 12.799A9.002 9.002 0 003 13.999a9.002 9.002 0 007.8 8.921c.227.052.465.08.708.08h8.503a.989.989 0 00.99-.989v-8.503c0-.244-.029-.481-.08-.71z"
      fill="currentColor"
    />
  </svg>
);

export const UpRightArrow = (props) => (
  <svg viewBox="0 0 29 30" fill="none" {...props}>
    <path
      d="M22.835 9.284l.403 10.548.337 7.664 5.008-5.372c-.276-4.982-1.13-15.753-1.331-21.519-5.699.2-16.467.135-21.49.243L.754 6.22c2.716-.027 5.294-.118 7.669-.2 4.04-.04 8.653-.202 10.55-.336L.48 25.52l3.862 3.6L22.835 9.285z"
      fill="currentColor"
    />
  </svg>
);

export const BlackCircle = (props) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
    <circle cx="18" cy="18" r="18" fill="#181818" />
  </svg>
);

export const Instagram = ({ fill = 'black', ...props }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
    <path d="M12.4995 16.5H4.4388C4.42572 16.4583 4.38974 16.4706 4.36112 16.4665C3.80326 16.4022 3.26447 16.2243 2.77793 15.9439C1.28388 15.0582 0.517627 13.7448 0.502908 12.0036C0.496365 11.1915 0.502908 10.3794 0.502908 9.56815C0.502908 8.02737 0.502908 6.4866 0.502908 4.94582C0.488425 4.18875 0.679777 3.44195 1.05654 2.78514C1.93481 1.28852 3.25141 0.513231 4.99243 0.50587C7.31897 0.495239 9.6455 0.505871 11.972 0.500964C12.7172 0.485445 13.4542 0.657558 14.1154 1.00147C15.2455 1.60257 15.9856 2.52262 16.3446 3.7518C16.4133 3.98897 16.4264 4.23677 16.5 4.47067V12.563C16.4182 12.7487 16.4272 12.9531 16.3733 13.1453C15.9047 14.7965 14.8563 15.8703 13.206 16.3544C12.9722 16.4231 12.7293 16.4272 12.4995 16.5ZM15.0648 8.50662H15.0714C15.0714 7.34531 15.0714 6.18427 15.0714 5.02351C15.0774 4.7012 15.0367 4.37971 14.9503 4.06912C14.7614 3.44322 14.374 2.89577 13.8466 2.5094C13.3193 2.12302 12.6805 1.91869 12.0268 1.92724C9.67331 1.93788 7.3206 1.92724 4.9679 1.92724C4.52469 1.91529 4.08482 2.00686 3.68319 2.19467C2.53832 2.76715 1.93563 3.69456 1.93154 4.98099C1.92337 7.32896 1.93154 9.67692 1.93154 12.0249C1.9284 12.3314 1.96915 12.6369 2.05257 12.9318C2.24265 13.5602 2.63244 14.1095 3.16283 14.4963C3.69321 14.8831 4.33526 15.0864 4.99161 15.0754C7.33451 15.0606 9.67659 15.0704 12.0195 15.0754C12.5095 15.0881 12.9949 14.9773 13.4309 14.7531C14.4392 14.2117 15.0231 13.3727 15.0583 12.2244C15.0943 10.9854 15.0648 9.74562 15.0648 8.50662Z" fill={fill} />
    <path d="M8.50189 12.6256C7.68679 12.6248 6.89022 12.3824 6.21281 11.929C5.53541 11.4756 5.00756 10.8316 4.69597 10.0784C4.38438 9.32515 4.30302 8.49644 4.46218 7.69697C4.62134 6.8975 5.01387 6.16315 5.59018 5.58669C6.16649 5.01023 6.90071 4.61752 7.70009 4.45819C8.49947 4.29887 9.32813 4.38006 10.0814 4.69153C10.8346 5.00299 11.4787 5.53074 11.9322 6.2081C12.3856 6.88547 12.6282 7.68205 12.6291 8.49721C12.6281 9.59165 12.1929 10.641 11.4192 11.4149C10.6454 12.1889 9.59625 12.6243 8.50189 12.6256ZM11.1948 8.49639C11.1938 7.96355 11.0349 7.44298 10.738 7.00049C10.4412 6.558 10.0198 6.21349 9.52719 6.01051C9.03456 5.80754 8.49281 5.75522 7.97045 5.86019C7.44809 5.96515 6.96858 6.22268 6.59258 6.60019C6.21658 6.97771 5.96096 7.45826 5.85807 7.98107C5.75517 8.50387 5.80963 9.04545 6.01453 9.53731C6.21943 10.0292 6.56558 10.4492 7.00921 10.7443C7.45283 11.0394 7.974 11.1963 8.5068 11.1952C8.86075 11.1954 9.21125 11.1257 9.53816 10.99C9.86508 10.8543 10.162 10.6554 10.4118 10.4046C10.6615 10.1538 10.8593 9.8561 10.9937 9.52863C11.1281 9.20116 11.1964 8.85036 11.1948 8.49639Z" fill={fill} />
    <path d="M12.8061 5.25651C12.6693 5.25742 12.5337 5.23137 12.4069 5.17986C12.2802 5.12834 12.1648 5.05236 12.0674 4.95625C11.97 4.86015 11.8925 4.7458 11.8393 4.61974C11.7861 4.49368 11.7583 4.35838 11.7573 4.22155C11.7564 4.08473 11.7825 3.94906 11.834 3.8223C11.8855 3.69554 11.9615 3.58018 12.0576 3.48278C12.1537 3.38539 12.268 3.30787 12.3941 3.25467C12.5201 3.20146 12.6554 3.17361 12.7922 3.1727C13.0681 3.17435 13.3324 3.28367 13.5289 3.47738C13.7253 3.67109 13.8383 3.93386 13.8439 4.2097C13.8437 4.48571 13.7346 4.75049 13.5402 4.9465C13.3459 5.14252 13.0821 5.25392 12.8061 5.25651Z" fill={fill} />
  </svg>
);

export const Facebook = ({ fill = 'black', ...props }) => (
  <svg width="9" height="17" viewBox="0 0 9 17" fill={fill} {...props}>
    <path d="M2.67816 16.5C2.67498 14.2478 2.67498 11.9961 2.67816 9.74483C2.67816 9.6235 2.64344 9.59202 2.5195 9.59333C1.73711 9.59858 0.953341 9.59333 0.171623 9.59858C0.0442877 9.59858 -0.00133238 9.57366 2.95029e-05 9.44052C0.00593098 8.56607 0.00593098 7.69161 2.95029e-05 6.81715C2.95029e-05 6.69189 0.0306719 6.65254 0.166179 6.65385C0.948578 6.65975 1.73234 6.65385 2.51406 6.65975C2.65024 6.65975 2.6802 6.62303 2.67884 6.49776C2.67135 5.93702 2.67884 5.37627 2.67884 4.81618C2.66886 4.41078 2.68751 4.00519 2.73468 3.60222C2.98322 1.85899 4.2416 0.693563 6.06175 0.529602C7.01506 0.444343 7.9534 0.558459 8.89309 0.679134C8.98298 0.690939 9 0.736193 9 0.816861C9 1.56583 8.99523 2.3148 9 3.06377C9 3.1792 8.95778 3.19494 8.85224 3.19494C8.44095 3.1897 8.02944 3.1897 7.61769 3.19494C7.23501 3.19888 6.86866 3.26774 6.54998 3.4881C6.13189 3.77864 5.97867 4.20231 5.9671 4.67386C5.95143 5.28708 5.9671 5.90095 5.95961 6.51481C5.95961 6.63155 5.99638 6.6591 6.11214 6.65844C6.97012 6.65385 7.8281 6.65844 8.68541 6.65844H8.79095C8.84679 6.65844 8.87607 6.67418 8.85905 6.73649C8.84679 6.78305 8.84747 6.83224 8.83998 6.88012C8.70379 7.73861 8.56284 8.59646 8.43141 9.45561C8.41303 9.57628 8.36332 9.60055 8.2496 9.59989C7.54211 9.5953 6.83461 9.59989 6.1278 9.59399C5.98412 9.59399 5.95757 9.63727 5.95757 9.76451C5.96074 12.0066 5.96074 14.2487 5.95757 16.4908" fill={fill} />
  </svg>
);

export const LinkedIn = ({ stroke = 'black', ...props }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
    <path d="M11.1665 5.83334C12.2274 5.83334 13.2448 6.25477 13.9949 7.00492C14.7451 7.75506 15.1665 8.77248 15.1665 9.83334V14.5H12.4998V9.83334C12.4998 9.47972 12.3594 9.14058 12.1093 8.89053C11.8593 8.64049 11.5201 8.50001 11.1665 8.50001C10.8129 8.50001 10.4737 8.64049 10.2237 8.89053C9.97365 9.14058 9.83317 9.47972 9.83317 9.83334V14.5H7.1665V9.83334C7.1665 8.77248 7.58793 7.75506 8.33808 7.00492C9.08822 6.25477 10.1056 5.83334 11.1665 5.83334V5.83334Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.50016 6.5H1.8335V14.5H4.50016V6.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.16683 4.50001C3.90321 4.50001 4.50016 3.90306 4.50016 3.16668C4.50016 2.4303 3.90321 1.83334 3.16683 1.83334C2.43045 1.83334 1.8335 2.4303 1.8335 3.16668C1.8335 3.90306 2.43045 4.50001 3.16683 4.50001Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const Twitter = ({ stroke = 'black', ...props }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
    <path d="M15.8332 2.49999C15.1948 2.95031 14.4879 3.29473 13.7398 3.51999C13.3383 3.05833 12.8047 2.73112 12.2112 2.58261C11.6177 2.4341 10.9928 2.47145 10.4212 2.68963C9.84961 2.9078 9.3588 3.29626 9.01516 3.80247C8.67152 4.30868 8.49164 4.90821 8.49984 5.51999V6.18666C7.32826 6.21704 6.16735 5.9572 5.12051 5.43029C4.07367 4.90338 3.17338 4.12575 2.49984 3.16666C2.49984 3.16666 -0.166829 9.16666 5.83317 11.8333C4.46019 12.7653 2.82461 13.2326 1.1665 13.1667C7.1665 16.5 14.4998 13.1667 14.4998 5.49999C14.4992 5.31429 14.4814 5.12905 14.4465 4.94666C15.1269 4.27565 15.6071 3.42847 15.8332 2.49999V2.49999Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
