// extracted by mini-css-extract-plugin
export var BlackText = "TagAndContentSlice-module--BlackText--miaNt";
export var HiddenLg = "TagAndContentSlice-module--HiddenLg--g9tMr";
export var HiddenMd = "TagAndContentSlice-module--HiddenMd--uX0eg";
export var HiddenXl = "TagAndContentSlice-module--HiddenXl--aN68r";
export var HiddenXs = "TagAndContentSlice-module--HiddenXs--eCbkp";
export var TextAlignRightXs = "TagAndContentSlice-module--TextAlignRightXs--V+Uw4";
export var WhiteText = "TagAndContentSlice-module--WhiteText--t3fbc";
export var black = "#181818";
export var blackHSL = "0 0% 9%";
export var container = "TagAndContentSlice-module--container--hP3kG";
export var desktopWidth = "960px";
export var lgBreakpoint = "60rem";
export var mdBreakpoint = "40rem";
export var srOnly = "TagAndContentSlice-module--sr-only--OY4nQ";
export var tag = "TagAndContentSlice-module--tag--TBngE";
export var visuallyHidden = "TagAndContentSlice-module--visually-hidden--45YPv";
export var white = "#fcfcfc";
export var whiteHSL = "0 0% 99%";